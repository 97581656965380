import { client } from '../services/api-client';

const authKey = 'AUTH_TOKEN';

const getToken = () => localStorage.getItem(authKey);
const setToken = token => localStorage.setItem(authKey, token);
const removeToken = () => localStorage.removeItem(authKey);

const login = async data => {
  const result = await client('users/login', {
    data: { ...data, isAdminApp: true },
    transform: true,
  });
  if (result.role === 'user') {
    logout();
    return Promise.resolve();
  }
  return result;
};

const logout = async () =>
  await client(`users/logout`, {
    method: 'DELETE',
    transform: true,
    authNeeded: true,
  });

export { authKey, getToken, setToken, removeToken, login, logout };
