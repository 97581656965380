import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getTherapies = async (_resource, params) => {
  try {
    const filters = { userId: params?.id, isOngoing: true };
    const url = constructUrl('users/therapies', { filters });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const updateDischargeGuest = async (_resource, params) => {
  const { therapyId } = params;
  try {
    const resp = await client(`users/therapies/${therapyId}/discharge`, {
      authNeeded: true,
      method: 'PUT',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getTherapies, updateDischargeGuest };
