import { S3_URI } from './constants';
import parsePhoneNumber from 'libphonenumber-js/min';
import toast from 'react-hot-toast';

export const parseError = response => {
  const retObj = {}; // Output response object

  if (response.errors) {
    const error = response.errors[0];
    const exception = error.extensions.exception;

    // Find the statusCode
    retObj.statusCode = (exception && exception.status) || 500;

    // Build the message
    if (!(exception && exception.response)) {
      retObj.message = error.message;
    } else if (typeof exception.response.message === 'string') {
      retObj.message = exception.response.message;
    } else if (Array.isArray(exception.response.message)) {
      retObj.message = exception.response.message.join(',');
    } else {
      retObj.message = error.message;
    }
  }

  return retObj;
};

export const buildIconUrl = iconPath =>
  iconPath.includes(S3_URI) ? iconPath : new URL(iconPath, S3_URI).toString();

export const timeDiffIn = (time1, time2) => {
  if (!time1 || !time2) return 0;

  const totalMs = time1 - time2;
  const totalMins = Math.ceil(totalMs / 1000 / 60);
  return totalMins > 60
    ? `${totalMins / 60}h ${totalMins % 60}m`
    : `${totalMins}m`;
};

export const validateString = value => {
  if (!value || (value && value.trim().length < 1)) return 'Required';
};

export const phoneValidation = value => {
  if (!(parsePhoneNumber(value) && parsePhoneNumber(value).isValid())) {
    return 'Phone format not right';
  }
  return undefined;
};

export const showToast = (message, type = 'success') => {
  toast[type](message);
};
