import React, { useState } from 'react';
import {
  PasswordInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  useNotify,
} from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import authProvider from '../auth-provider';
import { customDataProvider } from '../data-provider';
import Logo from '../assets/oh-white.svg';
import { showToast } from '../utils';
import history from '../history';

export const LoginPage = props => {
  const notify = useNotify();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [inputEmail, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const query = new URLSearchParams(history.location.search);
  const token = query.get('token');
  const submit = form => {
    const { username, password } = form;
    authProvider.login({ username, password }).catch(err => {
      showToast('Invalid parameters', 'error');
    });
  };
  const handleForgotPassword = () => {
    customDataProvider
      .getOne('forgotPassword', { email: inputEmail })
      .then(() => {
        notify('reset password link sent');
        setEmailSent(true);
      })
      .catch(err => {
        notify(
          err?.message ||
            err.response.data.message ||
            'reset password link failed',
        );
      });
  };
  const handleResetPassword = form => {
    const { password, password_confirmation } = form;
    const payload = { password, password_confirmation, token };
    customDataProvider
      .update('password', {
        payload,
      })
      .then(() => {
        showToast('Password has been reset successfully! Please login.');
        setForgotPassword(false);
        setPasswordReset(true);
        history.push('/login');
      });
  };
  const goToLogin = () => {
    setForgotPassword(false);
    setEmailSent(false);
  };
  const LoginToolbar = props => (
    <Toolbar {...props}>
      <SaveButton icon={<></>} label="Login" onSave={submit} />
    </Toolbar>
  );
  const loginContent = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm toolbar={<LoginToolbar />}>
              <TextInput source="username" label="Username" />
              <PasswordInput source="password" label="Password" />
            </SimpleForm>
            <div
              className="mt-4 inline-block text-blue-600"
              onClick={() => setForgotPassword(true)}
            >
              Forgot password
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const ForgotPasswordToolbar = props => (
    <Toolbar {...props}>
      <SaveButton
        icon={<></>}
        label="Send Email"
        // onSuccess={handleForgotPassword}
      />
    </Toolbar>
  );
  const forgotPasswordContent = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm
              save={handleForgotPassword}
              toolbar={<ForgotPasswordToolbar />}
            >
              <TextInput
                source="inputEmail"
                label="Email"
                onChange={e => setEmail(e.target.value)}
              />
            </SimpleForm>
            <div
              className="mt-4 inline-block text-blue-600"
              onClick={() => {
                setForgotPassword(false);
              }}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const ResetToolbar = props => (
    <Toolbar {...props}>
      <SaveButton
        icon={<></>}
        label="Reset Password"
        onSave={handleResetPassword}
      />
    </Toolbar>
  );
  const resetPasswordContent = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm toolbar={<ResetToolbar />}>
              <TextInput source="password" label="Password" />
              <PasswordInput
                source="password_confirmation"
                label="Confirma Password"
              />
            </SimpleForm>
            <div
              className="mt-4 inline-block text-blue-600"
              onClick={() => setForgotPassword(false)}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const emailSentContent = () => (
    <div className="page-wrap">
      <div className="login-container">
        <div className="logo-wrapper">
          <img src={Logo} alt="logo" />
        </div>
        <div className="login-wrap">
          <div className="text-center">
            <h1 className="text-xl">
              Password Reset Link has been sent. Check your Inbox!
            </h1>
            <div
              className="mt-4 text-blue-600 login-btn"
              onClick={() => goToLogin()}
            >
              Login Here
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <MuiThemeProvider theme={props.theme}>
      {token && !passwordReset && resetPasswordContent()}
      {forgotPassword && !emailSent && forgotPasswordContent()}
      {(!forgotPassword || passwordReset) && loginContent()}
      {emailSent && !token && emailSentContent()}
    </MuiThemeProvider>
  );
};
