import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getGiftList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;
    let giftTableFilters = {};
    if (filter.code) {
      filter['code.lk'] = filter.code;
      giftTableFilters['userEmail.lk'] = encodeURIComponent(filter.code);
      giftTableFilters['recipientEmail.lk'] = encodeURIComponent(filter.code);

      giftTableFilters['userFirstName.lk'] = filter.code;
      giftTableFilters['recipientFirstName.lk'] = filter.code;

      giftTableFilters['recipientLastName.lk'] = filter.code;
      giftTableFilters['userLastName.lk'] = filter.code;
    }
    const fields = ['gift.*', 'giftType.*', 'user.*'];

    const url = constructUrl('gifts', {
      fields,
      filters: {
        condition: 'or',
        sortField: 'id',
        sortOrder: 'desc',
        ...filter,
      },
      giftTableFilters: { condition: 'or', ...giftTableFilters },
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.page - 1),
    });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGiftDetail = async (_resource, params) => {
  try {
    // let { pagination } = params;
    const { id } = params;
    const filters = { id };
    const fields = ['gift.*', 'giftType.*', 'user.*'];
    const url = constructUrl('gifts', {
      fields,
      filters,
      // limit: pagination.perPage,
      // offset: pagination.perPage * (pagination.page - 1),
    });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchGiftPaymentIntent = async amount => {
  try {
    const url = 'gifts/payment-intent';

    const resp = await client(url, { authNeeded: true, data: { amount } });

    return resp;
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchGiftTypes = async () => {
  const url = 'gift-types';
  const resp = await client(url);

  return {
    data: resp.items,
    total: resp.count,
  };
};

const purchaseGift = async (_resource, gift) => {
  const url = 'gifts/purchase';
  const result = await client(url, { data: gift, authNeeded: true });

  return result;
};

const fetchUnusedGiftCodes = async (_resource, params) => {
  let { pagination } = params;
  const filters = {
    status: 'generated',
    'code.lk': params?.searchText ? params?.searchText : undefined,
  };
  const fields = ['code', 'id'];
  const url = constructUrl('gifts', {
    fields,
    filters,
    limit: pagination?.perPage,
    offset: pagination?.perPage * (pagination?.page - 1),
  });
  const result = await client(url, { authNeeded: true });
  return { data: result.items, total: result.count };
};

const createGiftCodes = async (_resource, params) => {
  const url = 'gifts/generate';
  const result = await client(url, { data: params, authNeeded: true });

  return result;
};

const updateGift = async (_resource, params) => {
  const { id, status } = params;
  const url = `gifts/${id}`;
  const result = await client(url, {
    method: 'PUT',
    data: { status },
    authNeeded: true,
  });
  return result;
};

const queries = {
  getGiftList,
  getGiftDetail,
  fetchGiftPaymentIntent,
  fetchGiftTypes,
  purchaseGift,
  fetchUnusedGiftCodes,
  createGiftCodes,
  updateGift,
};
export default queries;
