import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import {
  EditContextProvider,
  NumberInput,
  required,
  SimpleForm,
  useEditController,
  useNotify,
  Loading,
  useRefresh,
  SelectInput,
  useQuery,
} from 'react-admin';
// import Button from '@material-ui/core/Button';
import { ActionButtons, FullNameField } from '../components';
import { customDataProvider } from '../data-provider';
import { useState } from 'react';

export const CreditsSlide = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const controllerProps = useEditController(props);
  const {
    id,
    isOpenCredits,
    setIsOpenCredits,
    title,
    source,
    objectKey,
  } = props;
  const validateCredits = [required()];

  const handleCredits = values => {
    const payload = {
      id,
      sessionCredits: values?.sessionCredits || undefined,
      shopCredits:
        values?.shopCredits !== null ? values?.shopCredits : undefined,
    };
    customDataProvider.update('guest', payload).then(
      () => {
        setIsOpenCredits(false);
        notify(`updated ${title}`);
        refresh();
      },
      err => {
        notify(err.response.data.message, 'error');
      },
    );
  };

  const handleClose = () => {
    setIsOpenCredits(false);
  };

  return (
    <Route
      path={`/guest/${id}`}
      render={() => (
        <Drawer
          open={isOpenCredits}
          anchor="right"
          onClose={() => {
            setIsOpenCredits(false);
          }}
        >
          <div style={{ width: 400, padding: 20 }}>
            <h2>{title}</h2>
            <div style={{ marginBottom: 20 }}>
              Enter the amount you want to add or subtract (+/-)
              <EditContextProvider value={controllerProps}>
                <SimpleForm
                  class="slide-right"
                  save={handleCredits}
                  toolbar={
                    <ActionButtons
                      handleSave={handleCredits}
                      handleCancel={handleClose}
                      saveLabel={`+/- ${title}`}
                    />
                  }
                  initialValues={{ [objectKey]: 0 }}
                >
                  <NumberInput
                    source={source}
                    min={0}
                    validate={validateCredits}
                  />
                </SimpleForm>
              </EditContextProvider>
            </div>
          </div>
        </Drawer>
      )}
    />
  );
};

export const UpdateTherapist = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { id, isOpenUpdateTherapist, setIsOpenUpdateTherapist } = props;

  const [isUpdatingTherapist, setIsUpdatingTherapist] = useState(false);

  const { data: therapistsList, loading: isTherapistsLoading } = useQuery({
    type: 'getList',
    resource: 'therapistList',
    payload: {},
  });

  const controllerProps = useEditController(props);

  const handleUpdateTherapist = values => {
    setIsUpdatingTherapist(true);
    const payload = {
      userId: id,
      therapistId: values?.therapistId || undefined,
    };
    customDataProvider.update('updateUserTherapist', payload).then(
      () => {
        setIsOpenUpdateTherapist(false);
        setIsUpdatingTherapist(false);
        notify(`updated therapist`);
        refresh();
      },
      err => {
        notify(err?.response?.data?.message || 'api failed', 'error');
        setIsOpenUpdateTherapist(false);
        setIsUpdatingTherapist(false);
      },
    );
  };
  const handleClose = () => {
    setIsOpenUpdateTherapist(false);
  };
  return (
    <Route
      path={`/guest/${id}`}
      render={() => (
        <Drawer open={isOpenUpdateTherapist} anchor="right">
          <div style={{ width: 400, padding: 20 }}>
            {(isTherapistsLoading || isUpdatingTherapist) && <Loading />}
            <h2>Update Therapist</h2>
            <EditContextProvider value={controllerProps}>
              <SimpleForm
                class="slide-right"
                save={handleUpdateTherapist}
                toolbar={
                  <ActionButtons
                    handleSave={handleUpdateTherapist}
                    handleCancel={handleClose}
                    saveLabel="update therapist"
                  />
                }
              >
                <SelectInput
                  source="therapistId"
                  choices={therapistsList || []}
                  label="Therapists"
                  optionText={<FullNameField />}
                  validate={[required()]}
                  className="text-indent"
                />
              </SimpleForm>
            </EditContextProvider>
          </div>
        </Drawer>
      )}
    />
  );
};
