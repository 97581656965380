import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput,
  FunctionField,
} from 'react-admin';
import { FullNameField } from '../components';

const UserFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="email" alwaysOn />
  </Filter>
);

export const UserList = ({ ...props }) => (
  <List
    filters={<UserFilter />}
    bulkActionButtons={false}
    actions={false}
    {...props}
    perPage={25}
    className="main-table"
    empty={false}
  >
    <Datagrid rowClick="edit">
      <FullNameField label="Name" />
      <TextField source="email" />
      <FunctionField
        label="Phone Number"
        render={record => record.phoneNumber.split('+1')[1]}
      />
      ;
    </Datagrid>
  </List>
);
