import { constructUrl } from '../../utils/api';

const { client } = require('../../services/api-client');

const updateMembership = async (_resource, params) => {
  const { id: userId } = params;
  try {
    const resp = await client('users/membership/cancel', {
      authNeeded: true,
      method: 'PUT',
      data: { userId },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getMemberships = async (_resource, params) => {
  try {
    const fields = [];
    const filters = { userId: params?.id };
    const url = constructUrl('users/membership-months', { fields, filters });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { updateMembership, getMemberships };
