import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import {
  CheckboxGroupInput,
  email,
  // FileField,
  FileInput,
  ImageField,
  Loading,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useNotify,
  useQuery,
} from 'react-admin';
import { LocationField } from '../components';
import { customDataProvider } from '../data-provider';
import { roles } from '../utils/constants';

const validateFirstName = [required()];
const validateLastName = [required()];
const validateEmail = [required(), email()];
const validateRole = [required()];
const validateSpeciality = [required()];
const validateLocationId = [required()];
const validateZenotiId = [required()];
const validateOqId = [required()];
const validateBio = [required()];
const validateVirtualMeetingLink = [required()];
const validateSupervisorNpi = [required()];
const validateSupervisorFullName = [required()];

export const TeamMemberForm = props => {
  const notify = useNotify();
  const [role, setRole] = useState(props?.record?.role);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);
  // const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isLoadImage, setIsLoadImage] = useState(false);
  const [isLoadThumbnail, setIsLoadThumbnail] = useState(false);
  // const [isLoadVideo, setIsLoadVideo] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(true);
  const handleCheckboxSelection = event => {
    setCheckboxValue(!isEmpty(event));
  };
  const { data } = useQuery({
    type: 'getList',
    resource: 'locations',
    payload: { userId: props.id },
  });

  const { data: userSpecialities } = useQuery({
    type: 'getList',
    resource: 'specialities',
    payload: { id: props.id },
  });

  const UploadFile = (file, uploadUrl, shareUrl, type, uploadType) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl);
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const payload = {
            userId: props?.record.id,
            [uploadType]: shareUrl,
          };
          customDataProvider.update(type, payload).then(() => {
            notify('upload successfull');
            setIsLoadImage(false);
            // setIsLoadVideo(false);
            setIsLoadThumbnail(false);
            props?.setDisableButton(false);
          });
        } else {
          notify('upload failed');
          setIsLoadImage(false);
          // setIsLoadVideo(false);
          setIsLoadThumbnail(false);
          props?.setDisableButton(false);
        }
      }
    };
    // xhr.upload.addEventListener('progress', function (evt) {
    // });
    xhr.send(file);
  };

  const handleImageUpload = event => {
    setIsImageLoading(true);
    setIsLoadImage(true);
    props?.setDisableButton(true);
    const myFormData = {
      userId: props?.record.id,
      fileExtension: event?.path.split('.')[1],
      contentType: 'application/octet-stream',
    };
    if (event !== null) {
      customDataProvider.getOne('imageUpload', myFormData).then(res => {
        UploadFile(
          event,
          res?.uploadUrl,
          res?.shareUrl,
          'updateImage',
          'imageUrl',
        );
      });
    }
  };

  const handleThumbnailUpload = event => {
    setIsThumbnailLoading(true);
    setIsLoadThumbnail(true);
    props?.setDisableButton(true);
    const myFormData = {
      userId: props?.record.id,
      fileExtension: event?.path.split('.')[1],
      contentType: 'application/octet-stream',
    };
    if (event !== null) {
      customDataProvider.getOne('thumbnailUpload', myFormData).then(res => {
        UploadFile(
          event,
          res?.uploadUrl,
          res?.shareUrl,
          'updateThumbnail',
          'thumbnailUrl',
        );
      });
    }
  };

  // const handleVideoUpload = event => {
  //   setIsVideoLoading(true);
  //   setIsLoadVideo(true);
  //   props?.setDisableButton(true);
  //   const myFormData = {
  //     userId: props?.record.id,
  //     fileExtension: event?.path.split('.')[1],
  //     contentType: 'application/octet-stream',
  //   };
  //   if (event !== null) {
  //     customDataProvider.getOne('videoUpload', myFormData).then(res => {
  //       UploadFile(
  //         event,
  //         res?.uploadUrl,
  //         res?.shareUrl,
  //         'updateVideo',
  //         'videoUrl',
  //       );
  //     });
  //   }
  // };

  return (
    <>
      <TextInput
        source="firstName"
        multiline
        label="First Name"
        style={{ width: '100%' }}
        validate={validateFirstName}
      />
      <TextInput
        source="lastName"
        multiline
        label="Last Name"
        style={{ width: '100%' }}
        validate={validateLastName}
      />
      <TextInput
        source="email"
        multiline
        label="Email"
        style={{ width: '100%' }}
        validate={validateEmail}
      />
      <SelectInput
        source="role"
        choices={roles}
        label="Role"
        style={{ width: '100%' }}
        validate={validateRole}
        optionValue="id"
        onChange={e => {
          setRole(e.target.value);
        }}
        disabled={props?.editTeamMemeber}
      />

      {(role === 'director' ||
        role === 'therapist' ||
        role === 'supervisor') && (
        <SelectInput
          source="locationId"
          choices={data}
          label="Location"
          style={{ width: '100%' }}
          optionText={<LocationField />}
          validate={validateLocationId}
          optionValue="id"
        />
      )}
      {(role === 'therapist' || role === 'supervisor') && (
        <>
          <SelectArrayInput
            source="specialtyIds"
            choices={userSpecialities}
            label="Specialty"
            style={{ width: '100%' }}
            validate={validateSpeciality}
            optionValue="id"
          />
          <TextInput
            source="bio"
            multiline
            label="Bio"
            style={{ width: '100%' }}
            validate={validateBio}
          />
          <TextInput
            source="zenotiId"
            multiline
            label="Zenoti Id"
            style={{ width: '100%' }}
            validate={validateZenotiId}
          />
          <TextInput
            source="oqId"
            multiline
            label="Oq Id"
            style={{ width: '100%' }}
            validate={validateOqId}
          />
          <TextInput
            source="virtualMeetingLink"
            multiline
            label="Virtual Meeting Link"
            style={{ width: '100%' }}
            validate={validateVirtualMeetingLink}
          />
          <CheckboxGroupInput
            source="notSeeingNewClients"
            label=""
            choices={[{ id: 1, name: 'Not Seeing New Clients? *' }]}
            onChange={handleCheckboxSelection}
            format={formValue => (formValue && checkboxValue ? [1] : [])}
          />
          {role === 'therapist' && (
            <>
              <TextInput
                source="supervisorNpi"
                multiline
                label="Supervisor Npi"
                style={{ width: '100%' }}
                validate={validateSupervisorNpi}
              />
              <TextInput
                source="supervisorFullName"
                multiline
                label="Supervisor Full Name"
                style={{ width: '100%' }}
                validate={validateSupervisorFullName}
              />
            </>
          )}
          {props?.editTeamMemeber && (
            <>
              <FileInput
                source="imageUrl"
                label="Upload image"
                accept="image/*"
                onChange={handleImageUpload}
              >
                {!isLoadImage && <ImageField source="src" title="imageUrl" />}
              </FileInput>
              {!isImageLoading ? (
                <ImageField source="imageUrl" title="imageUrl" />
              ) : (
                <>
                  {isLoadImage && (
                    <Loading
                      className="plain-loading"
                      loadingSecondary="upload in progress, please wait…"
                    />
                  )}
                </>
              )}
              <FileInput
                source="thumbnailUrl"
                label="Upload thumbnail"
                accept="image/*"
                onChange={handleThumbnailUpload}
              >
                {!isLoadThumbnail && (
                  <ImageField source="src" title="thumbnailUrl" />
                )}
              </FileInput>
              {!isThumbnailLoading ? (
                <ImageField source="thumbnailUrl" title="thumbnailUrl" />
              ) : (
                <>
                  {isLoadThumbnail && (
                    <Loading
                      className="plain-loading"
                      loadingSecondary="upload in progress, please wait…"
                    />
                  )}
                </>
              )}
              {/* <FileInput
                source="videoUrl"
                label="Upload video"
                accept="application/json,video/*"
                onChange={handleVideoUpload}
              >
                {!isLoadVideo && <FileField source="src" title="videoUrl" />}
              </FileInput>
              {!isVideoLoading ? (
                <FileField source="videoUrl" title="videoUrl" />
              ) : (
                <>
                  {isLoadVideo && (
                    <Loading
                      className="plain-loading"
                      loadingSecondary="upload in progress, please wait…"
                    />
                  )}{' '}
                </>
              )} */}
            </>
          )}
        </>
      )}
    </>
  );
};
