import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { CustomButton, PriceField } from '../../components';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  TextField,
  useQuery,
} from 'react-admin';
import { isEmpty, keyBy } from 'lodash';
import { SessionTransactionsRefund } from './session-transactions-refund';
import { getPaymentStatus } from '../../utils/common';

export const SessionTransactions = ({ ...props }) => {
  const [sessionTransactionId, setSessionTransactionId] = useState();
  const [
    isOpenSessionTransactionRefund,
    setIsOpenSessionTransactionRefund,
  ] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  const { data: sessionTransactionsList, loading, refetch } = useQuery({
    type: 'getList',
    resource: 'sessionTransactions',
    payload: {
      filter: {
        sessionId: props?.sessionId?.id,
      },
    },
  });

  const handleCancel = () => {
    setIsOpenSessionTransactionRefund(false);
  };

  return (
    <div className="intake-width" style={{ padding: 20 }}>
      <h2>Session Transactions</h2>

      <>
        <ListContextProvider
          value={{
            resource: 'guest',
            basePath: '',
            data: keyBy(sessionTransactionsList, 'id'),
            ids:
              sessionTransactionsList &&
              sessionTransactionsList.map(({ id }) => id),
            currentSort: { field: 'id', order: 'ASC' },
            selectedIds: [],
            total: props?.total,
            page: props?.page,
            perPage: props?.perPage,
            setPage: props?.setPage,
            setPerPage: props?.setPerPage,
            // loading,
          }}
          className="main-table"
        >
          <Datagrid
            rowClick={e => {
              const rowData = sessionTransactionsList.filter(
                res => res.id === e,
              );
              setSessionTransactionId(rowData[0]);
            }}
          >
            <DateField
              record={sessionTransactionsList && sessionTransactionsList[0]}
              source="updatedAt"
              options={{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }}
            />
            <PriceField
              label="Shop Credits Used"
              source="shopCreditsUsed"
              sourceKey="shopCreditsUsed"
            />
            <PriceField
              label=" CC Total"
              source="finalPrice"
              sourceKey="finalPrice"
            />
            <PriceField
              label="Reimbursed Amount"
              source="reimbursedAmount"
              sourceKey="reimbursedAmount"
            />
            <PriceField
              label="Refunded CC Amount"
              source="refundAmount"
              sourceKey="refundAmount"
            />
            <PriceField
              label="Refunded Shop Credits"
              source="refundShopCredit"
              sourceKey="refundShopCredit"
            />
            <TextField source="notes" />
            <FunctionField
              label="Payment Status"
              render={record => (
                <span className="bold">{getPaymentStatus(record)}</span>
              )}
            />
            <FunctionField
              label=""
              render={record =>
                record?.status === 'successful' &&
                user?.role !== 'therapist' &&
                user?.role !== 'supervisor' && (
                  <CustomButton
                    setIsOpenModal={setIsOpenSessionTransactionRefund}
                    record={record}
                  />
                )
              }
            />
          </Datagrid>
          {isEmpty(sessionTransactionsList) && !loading && (
            <div style={{ padding: 10 }}>No transactions found</div>
          )}
        </ListContextProvider>
      </>

      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenSessionTransactionRefund}
            anchor="right"
            onClose={() => {
              setIsOpenSessionTransactionRefund(false);
            }}
          >
            <div style={{ width: 300, padding: 20 }}>
              <h2>Refund Transaction</h2>
              <SessionTransactionsRefund
                props={props}
                handleCancel={handleCancel}
                transaction={sessionTransactionId}
                refetch={refetch}
              />
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
