import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput,
  DateField,
  CreateButton,
  FunctionField,
} from 'react-admin';

const PromoFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="email" alwaysOn />
  </Filter>
);

export const ProductList = ({ record }) => (
  <span>
    {record.isApplicableOnSession && <span>Applicable On Session</span>}
    {record.isApplicableOnSession && record.isApplicableOnMembership && (
      <span>, </span>
    )}
    {record.isApplicableOnMembership && <span>Applicable On Membership</span>}
    {record.isApplicableOnMembership && record.isApplicableOnGift && (
      <span>, </span>
    )}
    {record.isApplicableOnGift && <span>Applicable On Gift</span>}
  </span>
);
export const RestrictionsList = ({ record }) => (
  <span>
    {record.isForFirstTimeGuestsOnly && <span>For First Time Guests Only</span>}
    {record.isForFirstTimeGuestsOnly && record.isOneTimePerGuest && (
      <span>, </span>
    )}
    {record.isOneTimePerGuest && <span>One Time Per Guest</span>}
  </span>
);

export const CodeValue = ({ record }) => (
  <span>
    {record.isDiscountPercentage ? (
      <span>{record.discountValue}</span>
    ) : (
      <span>{record.discountValue / 100}</span>
    )}
  </span>
);

export const PromoList = ({ ...props }) => {
  const [user, setUser] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);
  return (
    <List
      filters={<PromoFilter />}
      {...props}
      bulkActionButtons={false}
      actions={
        <CreateButton
          basePath={props?.basePath}
          disabled={user?.role !== 'admin' && user?.role !== 'director'}
        />
      }
      perPage={25}
      className="main-table"
    >
      <Datagrid rowClick={'edit'}>
        <TextField source="code" />
        <DateField source="validityStartDateTime" showTime />
        <DateField source="validityEndDateTime" showTime />
        <FunctionField
          label="Is For First Time Guests Only"
          render={record => (record.isForFirstTimeGuestsOnly ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Code Type"
          render={record => (record.isDiscountPercentage ? '%' : '$')}
        />
        <CodeValue label="Code Value" record={props?.data && props?.data[0]} />
        <RestrictionsList
          record={props?.data && props?.data[0]}
          label="Restrictions"
        />
        <ProductList record={props?.data && props?.data[0]} label="Products" />
        <TextField source="maxRedemptionCount" />
      </Datagrid>
    </List>
  );
};
