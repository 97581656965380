/* eslint-disable arrow-body-style */
import { Show, SimpleShowLayout, useRecordContext } from 'react-admin';
import { get, upperFirst } from 'lodash';
import dayjs from 'dayjs';

const GiftShowField = fieldProps => {
  const { label, source, render } = fieldProps;
  const record = useRecordContext(fieldProps);

  return (
    <div
      style={{
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <span style={{ fontWeight: 500, marginRight: 10 }}>{label}:</span>{' '}
      <span>{render ? render(record) : get(record, source)}</span>
    </div>
  );
};

export const GiftDetail = props => {
  return (
    <Show title=" " {...props}>
      <SimpleShowLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h2>Purchaser</h2>
            <GiftShowField
              label="Purchaser Name"
              render={rec =>
                `${rec.gift?.userFirstName} ${rec.gift?.userLastName}`
              }
            />
            <GiftShowField label="Purchaser Email" source="gift.userEmail" />
            <GiftShowField
              label="Purchase Date"
              render={rec =>
                dayjs(rec.transaction?.createdAt).format('MM/DD/YYYY')
              }
            />
            <GiftShowField
              label="Purchaser Name"
              render={rec =>
                `${rec.gift?.userFirstName} ${rec.gift?.userLastName}`
              }
            />
          </div>
          <div>
            <h2>Recipient</h2>
            <GiftShowField
              label="Recipient Name"
              render={rec =>
                `${rec.gift?.recipientFirstName} ${rec.gift?.recipientLastName}`
              }
            />
            <GiftShowField
              label="Recipient Email"
              source="gift.recipientEmail"
            />
            <GiftShowField
              label="Email Delivery Date"
              render={rec =>
                dayjs(rec.transaction?.createdAt).format('MM/DD/YYYY')
              }
            />
            <GiftShowField label="Gift Message" source="gift.message" />
          </div>
          <div>
            <h2>Gift Card</h2>
            <GiftShowField label="Gift Card Code" source="code" />
            <GiftShowField label="Status" source="status" />
            <GiftShowField
              label="Gift Card Value"
              render={rec => rec.transaction?.price / 100}
            />
            <GiftShowField
              label="Amount Charged"
              render={rec => rec.transaction?.finalPrice / 100}
            />
            <GiftShowField
              label="Payment Method"
              render={rec =>
                `${upperFirst(rec.transaction?.cardDetails?.brand)} - ${
                  rec.transaction?.cardDetails?.last4
                }`
              }
            />
            <GiftShowField
              label="Amount Refunded"
              render={rec => parseInt(rec.transaction?.totalRefunded) / 100}
            />
            {props.record.status === 'redeemed' ? (
              <GiftShowField
                label="Redeemed At"
                render={rec =>
                  rec.redeemedAtDateTimeTZ
                    ? dayjs(rec.redeemedAtDateTimeTZ).format('MM/DD/YYYY')
                    : null
                }
              />
            ) : null}
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
