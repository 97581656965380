import { isEmpty } from 'lodash';
import React from 'react';
import {
  CheckboxGroupInput,
  DateTimeInput,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SelectArrayInput,
  TextInput,
  useQuery,
} from 'react-admin';
import { LocationField } from '../components';
import { products, restrictions } from '../utils/constants';

const validateCode = [required()];
const validateIsDiscountPercentage = [required()];
const validateDiscountValue = [required()];
const validateLocations = [required()];

const validateProducts = [required()];

// const validateStartDateTime = values => {
//   console.log(dayjs().isAfter(dayjs(values)), dayjs().isSame(values, 'day'));
//   let errors = '';
//   if (dayjs().isAfter(dayjs(values)) && !dayjs().isSame(values, 'day')) {
//     errors = 'Start date time should be greater than/equal current date';
//   }
//   return errors;
// };
// const validateEndDateTime = values => {
//   console.log(dayjs().isAfter(dayjs(values)), dayjs().isSame(values, 'day'));
//   let errors = '';
//   if (dayjs().isAfter(dayjs(values)) && !dayjs().isSame(values, 'day')) {
//     errors = 'End date time should be greater than/equal current date';
//   }
//   return errors;
// };

export const PromoForm = props => {
  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'locations',
    payload: { userId: props.id },
  });

  const isDisabled = currentUserRole => currentUserRole === 'guest-experience';

  return (
    <>
      <TextInput
        source="code"
        label="Promo Code"
        style={{ width: '100%' }}
        validate={validateCode}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <RadioButtonGroupInput
        source="isDiscountPercentage"
        choices={[
          { id: '$ Off', name: '$ Off', value: false },
          { id: '% Off', name: '% Off', value: true },
        ]}
        optionValue="value"
        validate={validateIsDiscountPercentage}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <NumberInput
        source="discountValue"
        label="Discount Value($/%)"
        style={{ width: '100%' }}
        validate={validateDiscountValue}
        min={0}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <DateTimeInput
        source="validityStartDateTime"
        label="Start Date Time"
        style={{ width: '100%' }}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <DateTimeInput
        source="validityEndDateTime"
        label="End Date Time"
        style={{ width: '100%' }}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      {!isEmpty(locations) && (
        <SelectArrayInput
          style={{ width: '100%' }}
          label="Shops"
          source="locationIds"
          optionText={<LocationField />}
          optionValue="id"
          choices={locations}
          validate={validateLocations}
          disabled={isDisabled(props?.loggedinUser?.role)}
        />
      )}
      <NumberInput
        source="maxRedemptionCount"
        label="Max Redemption Count"
        style={{ width: '100%' }}
        min={0}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      {props?.editPromo && <TextInput source="usedCount" disabled={true} />}
      <CheckboxGroupInput
        source="restrictions"
        label="Restrictions"
        choices={restrictions}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <CheckboxGroupInput
        source="products"
        label="Products"
        choices={products}
        validate={validateProducts}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
      <TextInput
        source="note"
        label="Notes"
        style={{ width: '100%' }}
        disabled={isDisabled(props?.loggedinUser?.role)}
      />
    </>
  );
};
