import React, { useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  CreateContextProvider,
  NumberInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { ActionButtons } from '../../components';
import { customDataProvider } from '../../data-provider';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import Payment from '../../payments';

export const Charge = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const controllerProps = useCreateController(props);
  const [isCloseInvoice, setIsCloseInvoice] = useState(false);
  const [creditCardValue, setCreditCardValue] = useState(0);
  const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const { data: paymentMethod, refetch } = useQuery({
    type: 'getOne',
    resource: 'paymentMethod',
    payload: { userId: props?.id },
  });

  useEffect(() => {
    refetch();
  }, [isOpenEdit, refetch]);

  const handleCharge = values => {
    if (
      !values?.creditCardAmount &&
      !values?.shopCredits &&
      !values?.reimbursedAmount &&
      !isCloseInvoice
    ) {
      notify(
        'enter amount on credit card charge/shop credits used/reimbursed amount',
      );
      return;
    }
    const payload = {
      ...values,
      sessionId: props?.sessionId?.id,
      creditCardAmount: values?.creditCardAmount
        ? Math.round(values?.creditCardAmount * 100)
        : undefined,
      shopCredits: values?.shopCredits
        ? Math.round(values?.shopCredits * 100)
        : undefined,
      reimbursedAmount: values?.reimbursedAmount
        ? Math.round(values?.reimbursedAmount * 100)
        : undefined,
      closeInvoice: isCloseInvoice,
    };
    customDataProvider.update('charge', payload).then(
      () => {
        notify('successfully charged');
        refresh();
        props.handleClose();
      },
      err => {
        notify(err.message);
      },
    );
  };

  return (
    <div style={{ width: 300, padding: 20 }}>
      <h2>Charge {props.type}</h2>
      <div>
        Credit Card: {paymentMethod?.card?.last4}
        <Button
          style={{
            bottom: 3,
          }}
          variant="text"
          color="primary"
          onClick={() => {
            setIsOpenEdit(true);
          }}
        >
          Edit
        </Button>
      </div>
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          style={{ padding: 0 }}
          save={handleCharge}
          toolbar={
            <ActionButtons
              handleSave={handleCharge}
              handleCancel={props.handleClose}
              saveLabel="Charge Now"
              className="custom-flex"
            />
          }
        >
          <NumberInput
            min={0}
            source="creditCardAmount"
            label="Credit Card Charge Amount"
            onChange={values => setCreditCardValue(Number(values.target.value))}
          />
          <NumberInput
            min={0}
            source="shopCredits"
            label="Shop Credits Used"
            onChange={values =>
              setTotalCreditsUsed(Number(values.target.value))
            }
          />
          <TextInput
            source="outOfPocketTotal"
            disabled={true}
            format={() => (creditCardValue + totalCreditsUsed).toFixed(2)}
            label="Out of Pocket Total"
          />
          <NumberInput
            min={0}
            source="reimbursedAmount"
            label="Reimbursed Amount"
          />
          <TextInput source="notes" multiline label="Notes" />
          <CheckboxGroupInput
            source="closeInvoice"
            label=""
            choices={[
              { id: 1, name: 'This is the Final Charge / Close Invoice' },
            ]}
            onChange={() => {
              setIsCloseInvoice(!isCloseInvoice);
            }}
          />
        </SimpleForm>
      </CreateContextProvider>

      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenEdit}
            anchor="right"
            onClose={() => {
              setIsOpenEdit(false);
            }}
          >
            <div style={{ width: 300, padding: 20 }}>
              <h2>Charge {props.type}</h2>
              <Payment setIsOpenEdit={setIsOpenEdit} props={props} />
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
