/* eslint-disable arrow-body-style */
import {
  CreateContextProvider,
  useCreateController,
  SimpleForm,
  TextInput,
  required,
  useQuery,
  useNotify,
  SelectInput,
  useRedirect,
  AutocompleteInput,
  FormDataConsumer,
  SaveButton,
  Button,
  Toolbar,
  NumberInput,
  maxValue,
} from 'react-admin';

import React, { useEffect, useState } from 'react';
import { isEmpty, upperFirst } from 'lodash';
import { Drawer } from '@material-ui/core';
import { customDataProvider } from '../data-provider';
import { useDebouncedCallback } from 'use-debounce';

import useStripeTerminal from './stripe';

const USE_SIMULATOR = process.env.REACT_APP_SIMULATE_STRIPE_TERMINAL === 'true';

export const SellGift = props => {
  const controllerProps = useCreateController(props);
  const notify = useNotify();
  const [formValues, setFormValues] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPurchasingGift, setIsPurchasingGift] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [searchFilter, setSearchFilter] = useState('');
  const handleSearch = useDebouncedCallback(term => {
    setSearchFilter(term);
  }, 300);

  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'locations',
    payload: { userId: props.id },
  });

  const { data: giftTypes } = useQuery({
    type: 'getList',
    resource: 'giftTypes',
  });

  const { data: giftCodes, refetch } = useQuery({
    type: 'getList',
    resource: 'giftCodes',
    payload: {
      searchText: searchFilter,
      pagination: {
        perPage: 50,
        page: 1,
      },
    },
  });

  const sessionType = giftTypes?.find(gT => gT.type === 'session');
  const membershipType = giftTypes?.find(gT => gT.type === 'membership');

  const {
    discoveringReaders,
    connectingToReader,
    collectingPaymentMethod,
    processingPayment,
    cancelablePayment,

    discoverReaders,
    disconnectReader,
    connectToReader,
    connectToSimulator,
    collectCardPayment,
    cancelPendingPayment,
  } = useStripeTerminal();
  const redirect = useRedirect();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    async function disconnect() {
      await disconnectReader();
    }
    disconnect();
    return () => disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processGift = payload =>
    customDataProvider.create('gift', payload).then(
      () => {
        setIsPurchasingGift(false);
        setIsDrawerOpen(false);
        disconnectReader();
        notify('Gift purchase processed successfully');
        setIsProcessing(false);
        redirect('/gift');
      },
      err => {
        setIsPurchasingGift(false);
        setIsProcessing(false);
        disconnectReader();
        setIsDrawerOpen(false);
        notify(err.response ? err.response?.data?.message : err.message);
      },
    );

  const handleErrors = e => {
    setIsProcessing(false);
    setIsPurchasingGift(false);
    setIsDrawerOpen(false);
    alert(e.message);
  };

  const purchaseGift = async values => {
    setIsProcessing(true);
    if (!Number(values.chargeAmount)) {
      try {
        const payload = {
          code: formValues.code,
          userEmail: formValues.userEmail,
          userFirstName: formValues.userFirstName,
          userLastName: formValues.userLastName,
          type: formValues.type,
          duration:
            formValues.type === 'membership' ? formValues.duration : undefined,
        };
        setIsPurchasingGift(true);
        return processGift(payload);
      } catch (e) {
        handleErrors(e);
      }
    } else {
      try {
        if (USE_SIMULATOR) {
          await connectToSimulator();
        } else {
          const readers = await discoverReaders();
          if (!readers || isEmpty(readers)) {
            throw new Error('No readers found');
          }
          await connectToReader(readers[0]);
        }

        const paymentResult = await collectCardPayment(
          values.chargeAmount * 100,
        );
        const payload = {
          paymentIntentId: paymentResult.paymentIntent.id,
          code: formValues.code,
          userEmail: formValues.userEmail,
          userFirstName: formValues.userFirstName,
          userLastName: formValues.userLastName,
          type: formValues.type,
          duration:
            formValues.type === 'membership' ? formValues.duration : undefined,
        };
        setIsPurchasingGift(true);
        return processGift(payload);
      } catch (e) {
        handleErrors(e);
      }
    }
  };

  const cancelGiftPurchase = async () => {
    try {
      if (cancelablePayment) {
        await cancelPendingPayment();
        await disconnectReader();
        setIsDrawerOpen(false);
        notify('Gift purchase cancelled successfully');
      } else {
        await disconnectReader();
        setIsDrawerOpen(false);
      }
    } catch (e) {
      notify(
        'Payment has already been processed, and cannot be cancelled at this point',
      );
    }
  };

  const handleSave = async values => {
    setFormValues(values);
    setIsDrawerOpen(true);
  };

  const requiredField = [required()];

  const price = React.useMemo(
    () =>
      formValues?.type === 'session'
        ? sessionType?.price / 100
        : (membershipType?.price / 100) * formValues?.duration,
    [
      formValues?.duration,
      formValues?.type,
      membershipType?.price,
      sessionType?.price,
    ],
  );

  return (
    <div className="mb63 gift-create">
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          save={handleSave}
          toolbar={
            <Toolbar>
              <SaveButton label="Continue" />
            </Toolbar>
          }
        >
          <AutocompleteInput
            source="code"
            label="Gift Code"
            validate={requiredField}
            choices={
              searchFilter
                ? giftCodes?.map(gC => ({
                    id: gC.code,
                    name: gC.code,
                  }))
                : []
            }
            onInputValueChange={value => {
              handleSearch(value);
            }}
            resettable
          />
          <TextInput
            source="userEmail"
            label="Email"
            validate={requiredField}
          />
          <TextInput
            source="userFirstName"
            label="First Name"
            validate={requiredField}
          />
          <TextInput
            source="userLastName"
            label="Last Name"
            validate={requiredField}
          />
          {!isEmpty(locations) && (
            <SelectInput
              label="Location"
              source="location"
              optionText="city"
              optionValue="city"
              choices={locations}
              validate={requiredField}
            />
          )}
          <SelectInput
            label="Gift Type"
            source="type"
            optionText="label"
            optionValue="value"
            choices={[
              { id: 1, value: 'session', label: 'Session' },
              { id: 2, value: 'membership', label: 'Membership' },
            ]}
            validate={requiredField}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <>
                {formData.type === 'membership' ? (
                  <SelectInput
                    label="Membership Duration"
                    source="duration"
                    optionText="label"
                    optionValue="value"
                    choices={membershipType?.duration.map((d, index) => ({
                      id: index + 1,
                      value: d,
                      label: `${d} ${d === 1 ? 'month' : 'months'}`,
                    }))}
                    validate={requiredField}
                  />
                ) : null}
              </>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </CreateContextProvider>

      <Drawer open={isDrawerOpen} anchor="right">
        <CreateContextProvider value={controllerProps}>
          <SimpleForm
            save={purchaseGift}
            toolbar={
              <Toolbar {...props}>
                <SaveButton label="Make Payment" onSuccess={purchaseGift} />
                <Button
                  label="Cancel"
                  onClick={cancelGiftPurchase}
                  disabled={isProcessing && !cancelablePayment}
                />
              </Toolbar>
            }
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <>
                  <h2 style={{ marginLeft: 0 }}>Please Confirm</h2>
                  <table border="collapsed" className="custom-table">
                    <tbody>
                      <tr>
                        <td>Gift Code</td>
                        <td>{formValues?.code}</td>
                      </tr>
                      <tr>
                        <td>Gift Type</td>
                        <td>{upperFirst(formValues?.type)}</td>
                      </tr>
                      {formValues?.type === 'membership' ? (
                        <tr>
                          <td>Membership Duration</td>
                          <td>
                            {formValues?.duration}{' '}
                            {formValues?.duration === 1 ? 'month' : 'months'}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Subtotal</td>
                        <td>${price}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>${price - formData.chargeAmount}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>${formData.chargeAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </FormDataConsumer>

            <NumberInput
              source="chargeAmount"
              max={price}
              min={0}
              initialValue={price}
              validate={[
                ...requiredField,
                maxValue(
                  price,
                  'Charge amount cannot be greater than the sub total',
                ),
              ]}
            />
            {discoveringReaders ? (
              <div>Please wait, looking for readers</div>
            ) : null}
            {connectingToReader ? (
              <div>Reader found, attempting connection</div>
            ) : null}
            {collectingPaymentMethod ? (
              <div>Reader Connected, waiting for card</div>
            ) : null}
            {processingPayment ? <div>Processing Payment</div> : null}
            {isPurchasingGift ? <div>Processing Gift Purchase</div> : null}
          </SimpleForm>
        </CreateContextProvider>
      </Drawer>
    </div>
  );
};
