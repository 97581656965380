import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './auth-provider';
import { UserList } from './user';
import { customDataProvider } from './data-provider';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { UserEdit } from './user/user-edit';
import customRoutes from './custom-routes';
import { TeamMemberList } from './teams/teams-list';
import { TeamMemberEdit } from './teams/team-member-edit';
import { TeamMemberCreate } from './teams/team-member-create';
import { PromoList } from './promo/promo-list';
import { PromoEdit } from './promo/promo-edit';
import { PromoCreate } from './promo/promo-create';
import { AllGiftsList } from './gift/gift-list';
import { SellGift } from './gift/gift-create';
import { LoginPage } from './user/login';
import { rawTheme } from './theme';
import { GiftDetail } from './gift/gift-show';
import { ReportList } from './reports/report-list';

const App = () => {
  //TODO usePermissions()
  const [permission, setPermission] = React.useState();
  React.useEffect(() => {
    authProvider.getPermissions().then(res => {
      if (!permission && permission !== res) setPermission(res);
    });
  }, [permission]);

  return (
    <Admin
      loginPage={LoginPage}
      title="agdhsabjsd"
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={customDataProvider}
      theme={rawTheme}
    >
      <Resource
        name="guest"
        list={UserList}
        edit={UserEdit}
        icon={PersonOutlineIcon}
      />
      {permission === 'admin' || permission === 'director' ? (
        <Resource
          name="team"
          list={TeamMemberList}
          edit={TeamMemberEdit}
          create={TeamMemberCreate}
          icon={PeopleOutlineIcon}
        />
      ) : (
        <></>
      )}
      {permission === 'admin' ||
      permission === 'director' ||
      permission === 'guest-experience' ? (
        <Resource
          name="promo"
          list={PromoList}
          edit={PromoEdit}
          create={PromoCreate}
          icon={AttachMoneyIcon}
        />
      ) : (
        <></>
      )}
      {permission === 'admin' ||
      permission === 'director' ||
      permission === 'guest-experience' ? (
        <Resource
          name="gift"
          list={AllGiftsList}
          create={SellGift}
          show={GiftDetail}
          icon={CardGiftcardIcon}
        />
      ) : (
        <></>
      )}
      {permission === 'admin' ||
      permission === 'director' ||
      permission === 'guest-experience' ? (
        <Resource name="report" list={ReportList} icon={AssessmentIcon} />
      ) : (
        <></>
      )}
    </Admin>
  );
};

export default App;
