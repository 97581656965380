import React, { useEffect, useState } from 'react';
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { PromoForm } from './promo-forms';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';

export const PromoEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const handlePromoDelete = () => {
    const { id } = props;
    customDataProvider.delete('promo', { id }).then(
      () => {
        setIsOpenDelete(false);
        notify('deleted successfully');
        redirect('/promo');
      },
      err => {
        notify(err.message);
      },
    );
  };
  const [loggedinUser, setLoggedInUser] = useState();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setLoggedInUser(data);
  }, []);

  const PostEditToolbar = props => (
    <Toolbar
      {...props}
      style={{
        display: 'flex',
      }}
    >
      <Button
        onClick={() => setIsOpenDelete(true)}
        class="custom-btn"
        style={{ marginRight: 10 }}
      >
        Delete
      </Button>
      <SaveButton style={{ width: 150 }} />
    </Toolbar>
  );

  const onError = err => {
    notify(err.response.data.message, 'error');
  };

  return (
    <div className="intake-width mb63  promo-wrap">
      <Edit
        title="Edit Promo"
        {...props}
        onError={onError}
        onFailure={onError}
        mutationMode="pessimistic"
      >
        <SimpleForm
          toolbar={
            loggedinUser?.role === 'guest-experience' ? (
              <></>
            ) : (
              <PostEditToolbar />
            )
          }
        >
          <PromoForm
            data={props.record}
            editPromo={true}
            loggedinUser={loggedinUser}
          />
        </SimpleForm>
      </Edit>
      <Route
        path={`/promo/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenDelete}
            anchor="right"
            onClose={() => setIsOpenDelete(false)}
          >
            <div style={{ width: 400, padding: 20 }}>
              <h3>Delete Promo</h3>
              <div style={{ marginBottom: 20 }}>
                Are you sure you want to delete this promo code?
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <Button
                  onClick={() => setIsOpenDelete(false)}
                  variant="outlinedPrimary"
                >
                  Cancel
                </Button>
                <Button onClick={handlePromoDelete} variant="containedPrimary">
                  Delete
                </Button>
              </div>
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
