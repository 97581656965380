import { isEmpty } from 'lodash';
import React from 'react';
import { SelectInput, TextInput } from 'react-admin';
import { FullNameField } from '../components';

// eslint-disable-next-line arrow-body-style
export const TreatmentPlanForm = ({ userPartners, diagnosisList }) => {
  const [partnerId, setPartnerId] = React.useState(0);

  const handleChange = record => {
    setPartnerId(record.target.value);
  };

  const DiagnosisField = ({ record }) => <span>{record.option}</span>;

  return (
    <>
      {!isEmpty(userPartners) ? (
        <>
          <div>Partner (Optional)</div>
          <SelectInput
            source="partnerId"
            choices={userPartners || []}
            label="Partner Name"
            style={{ width: '100%' }}
            optionText={<FullNameField />}
            onChange={handleChange}
          />
        </>
      ) : null}
      <SelectInput
        source="diagnosisId"
        choices={diagnosisList || []}
        label="Tentative Diagnostic Impressions"
        style={{ width: '100%' }}
        className="text-indent"
        optionText={<DiagnosisField />}
      />
      {partnerId > 0 ? (
        <SelectInput
          source="partnerDiagnosisId"
          choices={diagnosisList || []}
          label="Tentative Diagnostic Impressions | Partner"
          style={{ width: '100%' }}
          optionText={<DiagnosisField />}
        />
      ) : null}

      <TextInput
        source="presentingProblems"
        multiline
        label="Current Presenting Problems"
        style={{ width: '100%' }}
      />
      <TextInput
        source="formulation"
        multiline
        label="History/Impressions/Dynamic Formulations"
        style={{ width: '100%' }}
      />
      <TextInput
        source="treatmentGoals"
        multiline
        label="Treatment Goals"
        style={{ width: '100%' }}
      />
      <TextInput
        source="interventions"
        multiline
        label="Interventions"
        style={{ width: '100%' }}
      />
      <TextInput
        source="referrals"
        multiline
        label="Referrals/Consultations"
        style={{ width: '100%' }}
      />
    </>
  );
};
