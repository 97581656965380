import React, { useState } from 'react';
import {
  Edit,
  FunctionField,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import { TeamMemberForm } from './team-member-forms';
import Button from '@material-ui/core/Button';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';

export const TeamMemberEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [isOpenDeactivate, setIsOpenDeactivate] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const handleDeactivation = () => {
    const { id } = props;
    customDataProvider.delete('team', { userId: id }).then(
      () => {
        setIsOpenDeactivate(false);
        notify('deactivated successfully');
        redirect('/team');
      },
      err => {
        notify(err.message);
      },
    );
  };
  const handleActivation = () => {
    const { id } = props;
    customDataProvider.update('activateUser', { userId: id }).then(
      () => {
        setIsOpenActivate(false);
        notify('activated successfully');
        redirect('/team');
      },
      err => {
        notify(err.message);
      },
    );
  };
  const PostEditToolbar = props => (
    <Toolbar {...props} style={{ display: 'flex' }}>
      {props?.record?.isActive && (
        <Button
          onClick={() => setIsOpenDeactivate(true)}
          class="custom-btn"
          style={{ marginRight: 10 }}
        >
          Deactivate
        </Button>
      )}
      {!props?.record?.isActive && (
        <Button onClick={() => setIsOpenActivate(true)}>Activate</Button>
      )}
      <SaveButton
        style={{ width: 150 }}
        disabled={disableButton}
        variant={disableButton ? 'contained' : 'containedPrimary'}
      />
    </Toolbar>
  );

  return (
    <div className="intake-width mb63 team-edit">
      <Edit title="Edit Team Member" {...props} mutationMode="pessimistic">
        <SimpleForm toolbar={<PostEditToolbar />}>
          <TeamMemberForm
            data={props.record}
            editTeamMemeber={true}
            setDisableButton={setDisableButton}
          />
          <FunctionField
            label="Status"
            render={record => (record.isActive ? 'Active' : 'Inactive')}
          />
        </SimpleForm>
      </Edit>
      <Route
        path={`/team/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenDeactivate}
            anchor="right"
            onClose={() => setIsOpenDeactivate(false)}
          >
            <div style={{ width: 400, padding: 20 }}>
              <h3>Deactivate Team Member</h3>
              <div style={{ marginBottom: 20 }}>
                Are you sure you want to deactivate this team member’s account?
                They will not be able to log in after being deactivated
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <Button
                  onClick={() => setIsOpenDeactivate(false)}
                  variant="outlinedPrimary"
                  className="custom-btn"
                >
                  Cancel
                </Button>
                <Button onClick={handleDeactivation} variant="containedPrimary">
                  Deactivate
                </Button>
              </div>
            </div>
          </Drawer>
        )}
      />
      <Route
        path={`/team/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenActivate}
            anchor="right"
            onClose={() => setIsOpenActivate(false)}
          >
            <div style={{ width: 400, padding: 20 }}>
              <h3>Activate Team Member</h3>
              <div style={{ marginBottom: 20 }}>
                Are you sure you want to activate this team member’s account?
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <Button
                  onClick={() => setIsOpenActivate(false)}
                  variant="outlinedPrimary"
                >
                  Cancel
                </Button>
                <Button onClick={handleActivation} variant="containedPrimary">
                  Activate
                </Button>
              </div>
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
