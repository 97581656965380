import React, { useState } from 'react';
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput,
  CreateButton,
  FunctionField,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { FullNameField } from '../components';
import { teamsFilters } from '../utils/constants';

const TeamMemberFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="email" alwaysOn />
  </Filter>
);

export const TeamMemberList = ({ ...props }) => {
  const getIsActive = localStorage.getItem('isActive');
  const [isActive, setIsActive] = useState(getIsActive || true);
  return (
    <>
      <List
        // filters={<TeamMemberFilter />}
        filter={{
          isActive: isActive,
        }}
        {...props}
        bulkActionButtons={false}
        actions={
          <div>
            <CreateButton basePath={props?.basePath} />
          </div>
        }
        perPage={25}
        className="main-table"
      >
        <>
          <div className="team-fliter-wrap">
            <TeamMemberFilter />
            <SimpleForm
              initialValues={{
                isActive: isActive,
              }}
              toolbar={<></>}
              style={{ padding: 0, width: '100%' }}
            >
              <SelectInput
                source="isActive"
                choices={teamsFilters}
                label="Status"
                style={{ width: '100%', maxWidth: '320px' }}
                optionValue="isActive"
                onChange={values => {
                  setIsActive(values?.target?.value);
                  localStorage.setItem('isActive', values?.target?.value);
                }}
              />
            </SimpleForm>
          </div>
          <Datagrid rowClick="edit">
            <FullNameField label="Name" />
            <TextField source="email" />
            <TextField source="location.city" label="Location" />
            <FunctionField
              source="role"
              label="Role"
              render={rec => rec?.role.replace('-', ' ')}
            />
            <FunctionField
              source="isActive"
              label="Status"
              render={rec => (rec.isActive ? 'Active' : 'InActive')}
            />
          </Datagrid>
        </>
      </List>
    </>
  );
};
