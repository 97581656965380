import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ListContextProvider,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import { GetPriceField } from '../../components';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { Refund } from '../refund';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../../data-provider';
// import { GiftDetail } from '../../gift/gift-show';

export const Gifts = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [isOpenRefund, setIsOpenRefund] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [giftCard, setGiftCard] = useState();
  const [user, setUser] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);
  const { data } = useQuery({
    type: 'getList',
    resource: 'gifts',
    payload: { id: props.id },
  });

  const handleCloseRefund = () => {
    setIsOpenRefund(false);
  };
  const handleCloseCancel = () => {
    setIsOpenCancel(false);
  };
  const handleGiftCancel = () => {
    customDataProvider
      .delete('gifts', { giftId: giftCard.id, status: 'canceled' })
      .then(
        () => {
          handleCloseCancel();
          notify('gift card cancelled successfylly');
          refresh();
        },
        err => {
          notify(err.message);
        },
      );
  };
  const RecipientNameField = ({ record }) => (
    <span>
      {record?.gift?.recipientFirstName} {record?.gift?.recipientLastName}
    </span>
  );
  return (
    <>
      <ListContextProvider
        value={{
          resource: 'guest',
          basePath: '/guest',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          perPage: 25,
        }}
        className="main-table"
      >
        <Datagrid
          rowClick={e => {
            const rowData = data.filter(res => res.id === e);
            setGiftCard(rowData[0]);
          }}
          // expand={<GiftDetail props={{ ...props, record: giftCard }} />}
        >
          <DateField
            record={data && data[0]}
            source="updatedAt"
            label="Created/Updated Date/Time"
            showTime
          />
          <RecipientNameField record={data && data[0]} label="Recipient Name" />
          <GetPriceField
            label="Gift Card Amount"
            source="transaction.price"
            parentKey="transaction"
            childKey="price"
          />
          <GetPriceField
            label="CC Total"
            source="transaction.finalPrice"
            parentKey="transaction"
            childKey="finalPrice"
          />
          <GetPriceField
            label="Refunded amount"
            source="transaction.totalRefunded"
            parentKey="transaction"
            childKey="totalRefunded"
          />
          <FunctionField label="Status" render={record => record.status} />
          <FunctionField
            label=""
            render={record => (
              <>
                {user?.role !== 'therapist' && user?.role !== 'supervisor' ? (
                  <Button variant="containedPrimary">
                    <Link
                      target="_blank"
                      to={`/gift?filter=%7B"code"%3A"${record?.code}"%7D&order=ASC&page=1&perPage=25`}
                      style={{ color: '#000' }}
                    >
                      VIEW DETAILS
                    </Link>
                  </Button>
                ) : null}
              </>
            )}
          />
        </Datagrid>
        <div style={{ height: 50 }}></div>
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenRefund}
              anchor="right"
              onClose={handleCloseRefund}
            >
              <Refund
                {...props}
                handleClose={handleCloseRefund}
                giftCard={giftCard}
                type="Gift"
              />
            </Drawer>
          )}
        />
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenCancel}
              anchor="right"
              onClose={handleCloseCancel}
            >
              <div style={{ width: 300, padding: 20 }}>
                <h2>Cancel Gift Card</h2>
                <div>
                  Are you sure you want to cancel this gift card? If you do, it
                  will not be able to be redeemed.
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: 20,
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Button
                    variant="outlinedPrimary"
                    onClick={handleCloseCancel}
                    className="custom-btn"
                  >
                    Keep It
                  </Button>
                  <Button
                    variant="containedPrimary"
                    onClick={handleGiftCancel}
                    style={{ marginTop: '10px' }}
                  >
                    Cancel Card
                  </Button>
                </div>
              </div>
            </Drawer>
          )}
        />
      </ListContextProvider>
    </>
  );
};
