import React, { useState } from 'react';
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  FunctionField,
  useNotify,
  useRefresh,
} from 'react-admin';
import { NavLink } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { Route } from 'react-router';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';
import { GiftDetail } from './gift-show';
import dayjs from 'dayjs';
import { Refund } from '../transactions/refund';
import authProvider from '../auth-provider';

export const AllGiftsList = ({ ...props }) => {
  const [cancelGiftId, setCancelGiftId] = useState(null);
  const [redeemGiftId, setRedeemGiftId] = useState(null);
  const [isOpenRefund, setIsOpenRefund] = useState(false);
  const [giftCard, setGiftCard] = useState();

  const notify = useNotify();
  const refresh = useRefresh();

  const FullNameField = ({ record }) => (
    <span>
      {record?.gift?.userFirstName} {record?.gift?.userLastName}
    </span>
  );
  // Gift Card Code
  // Purchaser Name
  // Gift Card Type
  // Gift Card Amount
  // CC Total
  // Refunded Amount
  // Status
  // Is Redeemed

  // CTAs to refund full/partial amount (to the source), mark the gift card as redeemed/canceled.

  const UserFilter = props => (
    <Filter {...props} className="searchWrapper">
      <SearchInput source="code" alwaysOn />
    </Filter>
  );

  const handleCloseRefund = () => {
    setIsOpenRefund(false);
  };

  const handleGiftCancellation = () => {
    customDataProvider
      .update('gift', { id: cancelGiftId, status: 'canceled' })
      .then(
        () => {
          setCancelGiftId(null);
          notify('The gift has been cancelled successfully');
          refresh();
        },
        err => {
          notify(err.message);
        },
      );
  };

  const handleGiftRedemption = () => {
    customDataProvider
      .update('gift', { id: redeemGiftId, status: 'redeemed' })
      .then(
        () => {
          setRedeemGiftId(null);
          notify('The gift has been redeemed successfully');
          refresh();
        },
        err => {
          notify(err.message);
        },
      );
  };

  const [permission, setPermission] = React.useState();
  React.useEffect(() => {
    authProvider.getPermissions().then(res => {
      if (!permission && permission !== res) setPermission(res);
    });
  }, [permission]);

  return (
    <div style={{ position: 'relative' }}>
      <div className="gift-actions">
        <NavLink
          to={{
            pathname: '/gift/create',
          }}
          style={{ width: 'fit-content' }}
        >
          Sell a Gift Card
        </NavLink>

        {permission !== 'guest-experience' && (
          <NavLink
            to={{
              pathname: '/gift-codes/create',
            }}
            style={{ width: 'fit-content' }}
          >
            Generate Gift Codes
          </NavLink>
        )}
      </div>

      <List
        filters={<UserFilter />}
        {...props}
        bulkActionButtons={false}
        actions={false}
        perPage={25}
        className="main-table"
      >
        <Datagrid expand={<GiftDetail />}>
          <TextField source="code" label="Gift Code" />
          <FullNameField
            record={props?.data && props?.data[0]}
            label="Purchaser Name"
          />
          <FunctionField
            label="Gift Type"
            render={rec =>
              rec.giftType?.type === 'session'
                ? rec.giftType?.type
                : `${rec.giftType?.type} - ${rec?.duration} mo.`
            }
          />
          <FunctionField
            source="transaction.price"
            label="Gift Amount"
            render={rec => rec.transaction?.price / 100}
          />
          <FunctionField
            label="Amount Charged"
            source="transaction.finalPrice"
            render={rec => rec.transaction?.finalPrice / 100}
          />
          <TextField label="Status" source="status" />
          <FunctionField
            label="Purchase Date"
            render={rec =>
              dayjs(rec.transaction?.createdAt).format('MM/DD/YYYY')
            }
          />
          <FunctionField
            label=""
            render={record => (
              <>
                {record.status !== 'redeemed' &&
                record.status !== 'canceled' ? (
                  <Button
                    variant="containedPrimary"
                    onClick={e => {
                      e.stopPropagation();
                      setCancelGiftId(record.id);
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
              </>
            )}
          />
          <FunctionField
            label=""
            render={record => (
              <>
                {record.status !== 'redeemed' &&
                record.status !== 'canceled' ? (
                  <Button
                    variant="containedPrimary"
                    onClick={e => {
                      e.stopPropagation();
                      setRedeemGiftId(record.id);
                    }}
                  >
                    Redeem
                  </Button>
                ) : null}
              </>
            )}
          />

          <FunctionField
            label=""
            render={record => (
              <>
                {record.transaction.fullyRefunded ? (
                  <Button
                    variant="containedPrimary"
                    disabled={true}
                    className="refunded-disable"
                  >
                    Refunded
                  </Button>
                ) : (
                  <Button
                    variant="containedPrimary"
                    onClick={() => {
                      setGiftCard(record);
                      setIsOpenRefund(true);
                    }}
                    style={{ maxWidth: '159px', width: '100%' }}
                  >
                    Refund
                  </Button>
                )}
              </>
            )}
          />
        </Datagrid>
      </List>

      {/* Redeem Drawer */}
      <Drawer open={Boolean(redeemGiftId)} anchor="right">
        <div style={{ width: 300, padding: 20 }}>
          <h2>Redeem Card</h2>
          <div style={{ marginBottom: 20 }}>
            Are you sure you want to redeem it? Once you redeem it, it will not
            be able to be used{' '}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <Button
              variant="outlinedPrimary"
              onClick={() => setRedeemGiftId(null)}
              style={{
                color: 'rgb(21, 54, 76)',
                borderColor: 'rgb(21, 54, 76)',
                marginTop: 10,
              }}
            >
              Cancel
            </Button>
            <Button variant="containedPrimary" onClick={handleGiftRedemption}>
              Redeem Card
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Cancel Drawer */}
      <Drawer open={Boolean(cancelGiftId)} anchor="right">
        <div style={{ width: 300, padding: 20 }}>
          <h2>Cancel Card</h2>
          <div style={{ marginBottom: 20 }}>
            Are you sure you want to cancel it? Once you cancel it, it will not
            be able to be used.
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <Button
              variant="outlinedPrimary"
              onClick={() => setCancelGiftId(null)}
              style={{
                color: 'rgb(21, 54, 76)',
                borderColor: 'rgb(21, 54, 76)',
                marginTop: 10,
              }}
            >
              Exit
            </Button>
            <Button variant="containedPrimary" onClick={handleGiftCancellation}>
              Cancel Card
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Refund Drawer*/}
      <Route
        path={`/gift`}
        render={() => (
          <Drawer
            open={isOpenRefund}
            anchor="right"
            onClose={handleCloseRefund}
          >
            <Refund
              {...props}
              handleClose={handleCloseRefund}
              giftCard={giftCard}
              type="Gift"
            />
          </Drawer>
        )}
      />
    </div>
  );
};
