import React from 'react';
import { required, TextInput } from 'react-admin';

export const GuestNotesForm = () => (
  <>
    <TextInput
      source="note"
      multiline
      label="Notes From Our Session"
      style={{ width: '100%' }}
      validate={[required()]}
    />
    <TextInput
      source="resources"
      multiline
      label="Other Resources"
      style={{ width: '100%' }}
      validate={[required()]}
    />
  </>
);
