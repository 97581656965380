export const getFilteredList = (data, key, value) =>
  (data && data.filter(res => res[key] === value)) || [];

export const getRestrictions = data => {
  const restrictions = [];
  if (data.isForFirstTimeGuestsOnly) {
    restrictions.push('isForFirstTimeGuestsOnly');
  }
  if (data.isOneTimePerGuest) {
    restrictions.push('isOneTimePerGuest');
  }
  return restrictions;
};

export const getProducts = data => {
  const products = [];
  if (data.isApplicableOnSession) {
    products.push('isApplicableOnSession');
  }
  if (data.isApplicableOnMembership) {
    products.push('isApplicableOnMembership');
  }
  if (data.isApplicableOnGift) {
    products.push('isApplicableOnGift');
  }
  return products;
};

export const formatObjectKey = res => {
  const result = res.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getPaymentStatus = record => {
  const status =
    record?.status === 'successful'
      ? 'PAYMENT COMPLETE'
      : record?.status === 'refund'
      ? 'Refunded'
      : record?.status === '‘fullRefund’'
      ? 'Refunded'
      : 'PAYMENT PENDING';
  return status;
};
