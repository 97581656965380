import { client } from '../services/api-client';

const createSetupIntent = async (_resource, params) => {
  try {
    const resp = await client(`users/setup-intent`, {
      authNeeded: true,
      method: 'POST',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const createPaymentMethod = async (_resource, params) => {
  try {
    const resp = await client(`users/payment-method`, {
      authNeeded: true,
      method: 'POST',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const queries = { createSetupIntent, createPaymentMethod };
export default queries;
