import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Show,
  SimpleForm,
  TextInput,
  TopToolbar,
  useQuery,
  SelectInput,
  Loading,
} from 'react-admin';
import { NoTitle, PartnerFullNameField } from '../components';
import './user-styles.css';

export const GuestIntake = ({ ...props }) => {
  const { data: userPartners } = useQuery({
    type: 'getList',
    resource: 'userPartners',
    payload: { id: props.id },
  });
  const { data: userPartnersIntake } = useQuery({
    type: 'getList',
    resource: 'userPartnersIntake',
    payload: { id: props.id },
  });
  // const { data: userSpecialities } = useQuery({
  //   type: 'getList',
  //   resource: 'specialities',
  //   payload: { id: props.id },
  // });
  const [partnerDetails, setPartnerDetails] = useState(
    userPartners && userPartners[0],
  );
  useEffect(() => {
    if (!isEmpty(userPartners) && !isEmpty(userPartnersIntake)) {
      const partnerData = userPartnersIntake.filter(
        res => res.partnerId === userPartners[0].partnerId,
      );
      setPartnerDetails(partnerData[0]);
    }
  }, [userPartners, userPartnersIntake]);
  const hangleChange = record => {
    const partnerData = userPartnersIntake.filter(
      res => res.partnerId === record.target.value,
    );
    setPartnerDetails(partnerData[0]);
  };

  return (
    <>
      <Show
        title={<NoTitle />}
        {...props}
        style={{ width: '100%' }}
        actions={<TopToolbar style={{ display: 'none' }} />}
      >
        <>
          {!props.record && <Loading className="plain-loading" />}
          <SimpleForm
            className="styled-form2"
            initialValues={props.record}
            toolbar={<TopToolbar style={{ display: 'none' }} />}
          >
            <div className="grid-3">
              <TextInput
                disabled={true}
                label="Employment Status"
                source="newGuestIntake.employmentStatus"
                record={props.record}
              />
              <TextInput
                disabled={true}
                label="Relationship Status"
                source="newGuestIntake.relationshipStatus"
              />
              <TextInput
                disabled={true}
                label="What's bringing you in?"
                source="lastSession.reasons"
                format={val => val?.map(v => v.name).join(',')}
              />
              <TextInput
                className="full-col"
                multiline
                disabled={true}
                label="In your own words, please describe what is bringing you into therapy?"
                source="newGuestIntake.therapyReason"
              />
              <TextInput
                className="full-col"
                multiline
                disabled={true}
                label="What do you hope to gain from therapy?"
                source="newGuestIntake.therapyGain"
              />
              <TextInput
                className="full-col"
                multiline
                disabled={true}
                label="Anything else you’d like us to know?"
                source="newGuestIntake.therapyAnythingElse"
              />
            </div>
          </SimpleForm>
          {props?.record?.guardian && (
            <>
              <h3 className="sub-headers margin-left">MINOR INTAKE</h3>
              <SimpleForm
                className="styled-form2"
                initialValues={{
                  ...props?.record?.guardian,
                  ...props?.record?.newGuestIntake,
                }}
                toolbar={<TopToolbar style={{ display: 'none' }} />}
              >
                <>
                  <div className="grid-3">
                    <>
                      <TextInput
                        disabled={true}
                        label="Guardian First Name"
                        source="firstName"
                      />
                      <TextInput
                        disabled={true}
                        label="Guardian Last Name"
                        source="lastName"
                      />
                      <TextInput
                        disabled={true}
                        label="Guardian Email"
                        source="email"
                      />
                      <TextInput
                        disabled={true}
                        label="Guardian Phone"
                        source="phoneNumber"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        source="familyConcerns"
                        label="Please share any concerns that your family is currently experiencing"
                      />
                      <TextInput
                        disabled={true}
                        label="Family have concerns with online activity?"
                        source="hasOnlineUsageConcern"
                        format={formValue => (formValue ? 'Yes' : '')}
                      />
                      <TextInput
                        disabled={true}
                        label="Family have concerns with mature content?"
                        source="hasMatureContentExposureConcern"
                        format={formValue => (formValue ? 'Yes' : '')}
                      />
                    </>
                  </div>
                </>
              </SimpleForm>
            </>
          )}
          {!isEmpty(userPartners) && !isEmpty(userPartnersIntake) && (
            <>
              <h3 className="sub-headers margin-left">Couples</h3>
              <SimpleForm
                className="styled-form2"
                initialValues={partnerDetails}
                toolbar={<TopToolbar style={{ display: 'none' }} />}
              >
                <>
                  <div className="grid-3">
                    <SelectInput
                      source="partnerId"
                      choices={userPartners || []}
                      label="Select Partner"
                      style={{ width: '100%' }}
                      optionText={<PartnerFullNameField />}
                      onChange={hangleChange}
                      optionValue="partnerId"
                    />
                  </div>
                  <div className="grid-3">
                    <>
                      <TextInput
                        disabled={true}
                        label="How would you describe your relationship?"
                        source="relationships"
                      />
                      <TextInput
                        disabled={true}
                        label="Are you happy with the frequency of sex?"
                        source="isHappyWithSexFrequency"
                        format={formValue => (formValue ? 'Yes' : '')}
                      />
                      <TextInput
                        disabled={true}
                        label="How enjoyable is your sexual relationship?"
                        source="sexualRelationshipEnjoyability"
                      />
                      <TextInput
                        disabled={true}
                        label="Partnership Length"
                        source="partnershipLength"
                      />
                      <TextInput
                        disabled={true}
                        label="Primary Reason for Coming Frequency"
                        source="primaryReasonFrequency"
                      />
                      <TextInput
                        disabled={true}
                        label="Primary Reason for Coming Concern Level"
                        source="primaryReasonConcern"
                      />
                      <TextInput
                        disabled={true}
                        label="Partnership Happiness Level"
                        source="partnershipHappinessLevel"
                      />
                      <TextInput
                        disabled={true}
                        label="Concerns about your partner’s drug or alcohol use?"
                        source="hasPartnerSubstanceAbuseConcern"
                        format={formValue => (formValue ? 'Yes' : '')}
                      />
                      <TextInput
                        disabled={true}
                        label="Partner ever physically restrained or injured you?"
                        source="hasPhysicallyAbusedPartner"
                        format={formValue => (formValue ? 'Yes' : '')}
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="What have you already done to deal with the dificulties?"
                        source="difficultyEfforts"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="What are your biggest strengths as a partnership?"
                        source="partnershipStrengths"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="Relationship Improvement Suggestions"
                        source="relationshipImprovementSuggestions"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="Relationship Concern One"
                        source="relationshipConcernOne"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="Relationship Concern Two"
                        source="relationshipConcernTwo"
                      />
                      <TextInput
                        className="full-col"
                        multiline
                        disabled={true}
                        label="Relationship Concern Three"
                        source="relationshipConcernThree"
                      />
                    </>
                  </div>
                </>
              </SimpleForm>
            </>
          )}
        </>
      </Show>
    </>
  );
};
