import React, { useState } from 'react';
import {
  EditContextProvider,
  Loading,
  SaveButton,
  SimpleForm,
  Toolbar,
  useEditController,
  useNotify,
  useQuery,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';
import { GuestNotesForm } from './guest-notes-form';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';

export const GuestNotesEdit = ({ ...props }) => {
  const notify = useNotify();
  const controllerProps = useEditController(props);
  const [isOpenSend, setIsOpenSend] = useState(false);
  const { data, loading } = useQuery({
    type: 'getOne',
    resource: 'guestNotes',
    payload: { id: JSON.stringify(props?.rowId) },
  });

  const handleSave = values => {
    const payload = {
      ...values,
    };
    delete payload.partnerId;
    updateGuestNote(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const updateGuestNote = payload =>
    customDataProvider.update('guestNotes', payload).then(
      () => {
        props.handleClose();
        notify('updated successfully');
      },
      err => {
        notify(err.message);
      },
    );

  const deleteGuestNote = () =>
    customDataProvider.delete('guestNotes', { noteId: props?.rowId }).then(
      () => {
        props.handleClose();
        notify('deleted successfully');
      },
      err => {
        notify(err.message);
      },
    );

  const PostEditToolbar = props => (
    <Toolbar {...props}>
      <Button onClick={handleCancel} className="custom-btn">
        Close
      </Button>
      <SaveButton
        label="Save Message"
        onSuccess={handleSave}
        style={{ marginLeft: 10 }}
      />
      <Button
        onClick={() => setIsOpenSend(true)}
        variant="contained"
        color="primary"
        style={{ marginLeft: 10 }}
      >
        Delete Message
      </Button>
    </Toolbar>
  );
  return (
    <div className="intake-width">
      <EditContextProvider value={controllerProps} style={{ width: '100%' }}>
        {loading && <Loading class="plain-loading" />}
        <SimpleForm
          save={handleSave}
          initialValues={data}
          toolbar={<PostEditToolbar />}
        >
          <GuestNotesForm />
        </SimpleForm>
      </EditContextProvider>
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenSend}
            anchor="right"
            onClose={() => setIsOpenSend(false)}
          >
            <div className="intake-width note">
              <h2>Delete Message</h2>
              <h3>Are you sure you want to delete this message?</h3>
              <Button
                onClick={deleteGuestNote}
                variant="contained"
                color="primary"
              >
                Delete Message
              </Button>
              <Button onClick={() => setIsOpenSend(false)}>Cancel</Button>
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
