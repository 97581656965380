import { useQuery } from 'ra-core';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  SelectInput,
  SimpleForm,
  TextField,
  useNotify,
  ListContextProvider,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { isEmpty, keyBy } from 'lodash';
import {
  allPaymentTypes,
  gssPaymentTypes,
  ReportLabels,
  SelectedPaymentTypes,
} from '../utils/constants';

export const ReportList = ({ ...props }) => {
  const paymentTypes =
    props?.permissions === 'guest-experience'
      ? gssPaymentTypes
      : allPaymentTypes;
  const [paymentType, setPaymentType] = useState();
  const [showTable, setShowTable] = useState(false);
  const [isDownload, setIsDownload] = useState();
  const notify = useNotify();
  const [filterDates, setFilterDates] = useState({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    if (props?.permissions && !paymentType) {
      setPaymentType(paymentTypes[0].value);
    }
  }, [props, paymentType, setPaymentType, paymentTypes]);

  const [filterPaymentType, setFilterPaymentType] = useState({
    startDate: '',
    endDate: '',
    type: '',
  });

  const { data, refetch } = useQuery({
    type: 'getList',
    resource: 'report',
    payload: {
      filter: filterDates,
    },
  });

  const {
    data: downloadableReport,
    refetch: refetchDownloadableReport,
    loading: isLoading,
  } = useQuery({
    type: 'getList',
    resource: 'downlaodableReport',
    payload: {
      filter: filterPaymentType,
    },
  });
  const onDateChange = (value, key, d1, d2) => {
    setFilterDates({
      ...filterDates,
      [key]: value,
      search: false,
    });
    if (d1 && d1?.getTime() > d2 && d2?.getTime()) {
      notify('Please select proper dates', 'error');
    }
  };

  const handleRunReport = tableView => {
    setShowTable(tableView);
    if (paymentType === SelectedPaymentTypes.revenue) {
      setFilterDates({
        ...filterDates,
        search: true,
      });
      refetch();
    } else {
      setFilterPaymentType({
        ...filterDates,
        type: paymentType,
      });
      refetchDownloadableReport();
    }
  };

  useEffect(() => {
    if (isDownload && !isLoading && !isEmpty(downloadableReport)) {
      downloadCsv(downloadableReport);
    }
  }, [isDownload, downloadableReport, isLoading]);

  const downloadReport = () => {
    setIsDownload(true);
    handleRunReport(showTable);
  };

  const downloadCsv = report => {
    let csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        [
          'Guest Full Name',
          'Session Date',
          'CPT Code',
          'Place Of Service',
          'Diagnosis Code',
          'Supervisor NPI',
          'Supervisor Full Name',
          'Guest Date of Birth',
          'Guest Sex',
          'Insurance Carrier',
          'Member ID',
          'Group ID',
          'Policy Relationship to You',
          'Policyholder Full Name',
          'Policyholder Date of Birth',
          'Policyholder Full Address',
          'Amount Charged',
        ],
        ...report.map(item => [
          item['Guest Full Name'],
          item['Session Date Time'],
          item['CPT Code'],
          item['Place Of Service'],
          item['Diagnosis Code'],
          item['Supervisor NPI'],
          item['Supervisor Full Name'],
          item['Guest Date of Birth'],
          item['Guest Sex'],
          item['Insurance Carrier'],
          item['Member ID']?.replaceAll('#', '-'),
          item['Group ID']?.replaceAll('#', '-'),
          item['Policy Relationship to You'],
          item['Policyholder Full Name'],
          item['Policyholder Date of Birth'],
          item['Policyholder Full Address']
            .replaceAll(',', ' ')
            .replaceAll('#', 'No.'),
          item['Charge Amount'],
        ]),
      ]
        .map(e => e.join(','))
        .join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <>
      <div>
        <SimpleForm
          initialValues={{ ...filterDates, type: paymentType }}
          toolbar={<></>}
          style={{
            padding: 0,
            width: '100%',
          }}
        >
          <div className="report-fliter-wrap">
            <DateInput
              source="startDate"
              onChange={values => {
                const d1 = new Date(values.target.value);
                onDateChange(
                  values?.target.value,
                  'startDate',
                  d1,
                  new Date(filterDates?.endDate),
                );
              }}
            />
            <DateInput
              source="endDate"
              onChange={values => {
                const d2 = new Date(values?.target.value);
                onDateChange(
                  values?.target.value,
                  'endDate',
                  new Date(filterDates?.startDate),
                  d2,
                );
              }}
              style={{ paddingLeft: 15, paddingRight: 15 }}
            />
            <SelectInput
              source="type"
              choices={paymentTypes}
              label="Payment Type"
              style={{ marginRight: 20 }}
              optionValue="value"
              onChange={values => {
                setPaymentType(values?.target?.value);
              }}
            />
            <Button
              variant="containedPrimary"
              onClick={() => {
                setIsDownload(false);
                handleRunReport(true);
              }}
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                marginTop: 8,
                marginRight: 20,
              }}
            >
              Run Report
            </Button>
            {paymentType !== SelectedPaymentTypes?.revenue && (
              <Button
                variant="containedPrimary"
                onClick={downloadReport}
                style={{ paddingTop: 12, paddingBottom: 12, marginTop: 8 }}
              >
                Download Report
              </Button>
            )}
          </div>
        </SimpleForm>
      </div>
      <div
        className="main-table"
        style={{ width: '65%', fontFamily: '"sofia-pro", cursive' }}
      >
        {!isEmpty(data) && paymentType === SelectedPaymentTypes?.revenue ? (
          Object.keys(data[0]).map(
            res =>
              ReportLabels[res] && (
                <div
                  style={{
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                  }}
                >
                  <>
                    <div
                      style={{
                        color: '#000 !important',
                        fontWeight: 600,
                      }}
                    >
                      {ReportLabels[res]}
                    </div>
                    <div
                      style={{
                        color: '#000 !important',
                        fontWeight: 600,
                        textAlign: 'left',
                        minWidth: '25%',
                      }}
                    >
                      {data[0][res]}
                    </div>
                  </>
                </div>
              ),
          )
        ) : (
          <>
            {paymentType !== SelectedPaymentTypes?.revenue &&
              !isEmpty(downloadableReport) &&
              showTable && (
                <ListContextProvider
                  value={{
                    resource: 'guest',
                    basePath: '',
                    data: keyBy(downloadableReport, 'id'),
                    ids:
                      downloadableReport &&
                      downloadableReport.map(({ id }) => id),
                    currentSort: { field: 'id', order: 'ASC' },
                    selectedIds: [],
                  }}
                  className="main-table"
                >
                  <Datagrid>
                    <TextField source="Guest Full Name" />
                    <DateField
                      source="Session Date Time"
                      label="Session Date "
                    />
                    <TextField source="CPT Code" label="CPT Code" />
                    <TextField source="Place Of Service" />
                    <TextField source="Diagnosis Code" />
                    <TextField source="Supervisor NPI" label="Supervisor NPI" />
                    <TextField source="Supervisor Full Name" />
                    <TextField source="Guest Date of Birth" />
                    <TextField source="Guest Sex" />
                    <TextField source="Insurance Carrier" />
                    <TextField source="Member ID" />
                    <TextField source="Group ID" />
                    <TextField source="Policy Relationship to You" />
                    <TextField source="Policyholder Full Name" />
                    <TextField source="Policyholder Date of Birth" />
                    <TextField source="Policyholder Full Address" />
                    {paymentType === paymentTypes[1].value && (
                      <TextField source="Charge Amount" />
                    )}
                  </Datagrid>
                </ListContextProvider>
              )}
          </>
        )}
      </div>
      {isEmpty(downloadableReport) && isEmpty(data) && (
        <div>No results found</div>
      )}
    </>
  );
};
