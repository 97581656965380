import React from 'react';
import { Create, SimpleForm, useRedirect } from 'react-admin';
import { TeamMemberForm } from './team-member-forms';

export const TeamMemberCreate = props => {
  const redirect = useRedirect();

  const onSuccess = res => {
    if (res?.data?.role !== 'therapist') {
      redirect('/team');
    }
  };
  return (
    <div className="intake-width mb63">
      <Create title="Create Team" onSuccess={onSuccess} {...props}>
        <SimpleForm>
          <TeamMemberForm data={props.record} />
        </SimpleForm>
      </Create>
    </div>
  );
};
