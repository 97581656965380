import React, { useEffect, useState } from 'react';
import {
  CreateContextProvider,
  required,
  SelectInput,
  SimpleForm,
  useCreateController,
  useNotify,
  useQuery,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';
import { GuestNotesForm } from './guest-notes-form';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { ActionButtons, FullNameField } from '../components';

export const GuestNotesCreate = props => {
  const notify = useNotify();
  const controllerProps = useCreateController(props);
  const [isOpenSend, setIsOpenSend] = useState(false);
  const [payload, setPayload] = useState();
  const { data } = useQuery({
    type: 'getList',
    resource: 'userTherapists',
    payload: { userId: props.id },
  });

  const [user, setUser] = useState('');

  const handleSave = values => {
    const { id } = props;
    const payload = {
      ...values,
      userId: id,
      therapistId:
        user?.role === 'therapist' || user?.role === 'supervisor'
          ? undefined
          : values?.therapistId,
    };
    setPayload(payload);
    setIsOpenSend(true);
  };

  const handleCancel = () => {
    props.handleClose();
  };
  const createGuestNotes = payload =>
    customDataProvider.create('guestNotes', payload).then(
      () => {
        handleCancel();
        setIsOpenSend(false);
        notify('created successfully');
      },
      err => {
        notify(err.message);
      },
    );

  const validateTherapistId = [required()];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  const preSelectedTherapist = data?.length === 1 ? data[0] : null;

  return (
    <div className="intake-width">
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          save={handleSave}
          initialValues={{
            therapistId: preSelectedTherapist?.id || undefined,
          }}
          toolbar={
            <ActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          }
        >
          {user?.role !== 'therapist' && (
            <SelectInput
              source="therapistId"
              choices={data}
              label="Therapists"
              optionText={<FullNameField />}
              validate={validateTherapistId}
              className="text-indent"
            />
          )}
          <GuestNotesForm data={props.record} />
        </SimpleForm>
      </CreateContextProvider>
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenSend}
            anchor="right"
            onClose={() => setIsOpenSend(false)}
          >
            <div className="intake-width note">
              <h2>Send Message</h2>
              <h3>Are you sure you want to send this message?</h3>
              <div>
                This will send a notification to the guest & make the message
                available in their inbox.
              </div>
              <div>
                <Button
                  onClick={() => createGuestNotes(payload)}
                  variant="contained"
                  color="primary"
                >
                  Send Message
                </Button>
                <Button onClick={() => setIsOpenSend(false)}>Cancel</Button>
              </div>
            </div>
          </Drawer>
        )}
      />
    </div>
  );
};
