import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = {
  fontFamilySecondary: "'sofia-pro', sans-serif",
  fontFamily: '"sofia-pro", cursive',
  fontSize: 16, // Should be a number in pixels
  fontStyle: 'normal',
  fontWeightThin: 300,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  //   color: palette.text.primary,
};

const typographyBase = {
  fontFamily: theme.fontFamily,
  fontSize: theme.fontSize,
  fontStyle: theme.fontStyle,
  color: theme.color,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: theme.fontWeightBold,
  fontFamily: theme.fontFamilySecondary, // Use a dedicated font for the header
};

const typographyBody = {
  ...typographyBase,
  fontWeight: theme.fontWeightRegular,
  fontFamily: theme.fontFamily,
};

export const rawTheme = createMuiTheme({
  //   palette,
  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      textTransform: 'uppercase',
      fontSize: '4rem',
    },
    // ... Put other title styles below
    body1: {
      ...typographyBody,
      fontSize: '1rem',
    },
    body2: {
      ...typographyBody,
      fontSize: '1rem',
      fontWeight: theme.fontWeightThin,
    },
  },
});
