import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentForm from './payment-form';
import { useNotify } from 'react-admin';
import { customDataProvider } from '../data-provider';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY,
);

const Payment = ({ handleSubmit, promoCodeContent, setIsOpenEdit, props }) => {
  const notify = useNotify();

  useEffect(() => {
    handleCreateSetupIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [setupIntent, setSetupIntent] = useState();
  const handleCreateSetupIntent = () => {
    customDataProvider
      .create('createSetupIntent', { userId: Number(props?.id) })
      .then(
        res => {
          setSetupIntent(res);
        },
        err => {
          notify(err.message);
        },
      );
  };

  return (
    <Elements stripe={stripePromise}>
      <>
        {setupIntent ? (
          <PaymentForm
            setupIntent={setupIntent}
            handleSubmit={handleSubmit}
            promoCodeContent={promoCodeContent}
            setIsOpenEdit={setIsOpenEdit}
            props={props}
          />
        ) : null}
      </>
    </Elements>
  );
};

export default Payment;
