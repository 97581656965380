import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getTherapistIntakeList = async (_resource, params) => {
  try {
    const fields = ['therapist.*'];

    const filters = { userId: params?.id };

    const url = constructUrl('users/therapist-intake', { fields, filters });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getTherapistIntake = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id };
    const fields = ['therapist.*'];

    const url = constructUrl('users/therapist-intake', {
      filters,
      fields,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const updateTherapistIntake = async (_resource, params) => {
  const {
    id,
    userId,
    therapistId,
    updatedAtDateTimeTZ,
    createdAt,
    updatedAt,
    therapist,
    partnerId,
    firstName,
    diagnosisId,
    ...rest
    // presentationInfo,
    // generalHistory,
    // relationshipHistory,
    // behaviourStruggleInfo,
    // alcoholDrugUsageInfo,
    // violentBehaviourInfo,
    // violentBehaviourHistory,
    // safetyConcerns,
    // medicalHistory,
    // appearance,
    // hygiene,
    // mood,
    // eyeContact,
    // speech,
    // affect,
    // engagement,
    // thoughtDisorder,
    // cognitiveFunctions,
    // therapyHistory,
    // generalImpression,
    // specialRequests,
  } = params;
  try {
    const resp = await client(`users/therapist-intake/${id}`, {
      authNeeded: true,
      method: 'PUT',
      data: {
        ...rest,
        diagnosisId:
          diagnosisId === null || diagnosisId === '' ? undefined : diagnosisId,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const createTherapistIntake = async (_resource, params) => {
  const { firstName, ...rest } = params;
  try {
    const resp = await client(`users/therapist-intake`, {
      authNeeded: true,
      method: 'POST',
      data: rest,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getDiagnosis = async (_resource, params) => {
  try {
    const url = constructUrl('diagnosis', {});

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getUserTherapistList = async (_resource, params) => {
  try {
    const url = constructUrl(`users/${params.userId}/therapists`, {});

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTherapistIntakeList,
  getTherapistIntake,
  updateTherapistIntake,
  createTherapistIntake,
  getDiagnosis,
  getUserTherapistList,
};
