import { isEmpty } from 'lodash';
import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getTeamList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;
    filter['isActive.in'] = filter?.isActive;
    filter['role.in'] = [
      'admin',
      'director',
      'guest-experience',
      'therapist',
      'supervisor',
    ];
    if (filter.email) {
      filter.condition = 'or';
      filter['email.lk'] = encodeURIComponent(filter.email);
      filter['firstName.lk'] = filter.email;
      filter['lastName.lk'] = filter.email;

      delete filter.email;
    }
    const { isActive, ...rest } = filter;
    const fields = ['location.*'];

    const url = constructUrl('users', {
      filters: rest,
      fields,
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.page - 1),
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTeamMember = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id, ...params.filter };

    const url = constructUrl('users', { filters });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLocations = async (_resource, params) => {
  try {
    const url = constructUrl('locations', {});
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateTeam = async (_resource, params) => {
  const {
    id: userId,
    locationId,
    role,
    firstName,
    lastName,
    email,
    bio,
    specialtyIds,
    supervisorNpi,
    supervisorFullName,
    notSeeingNewClients,
    virtualMeetingLink,
  } = params.data;
  const location = locationId !== null ? JSON.stringify(locationId) : undefined;
  try {
    const resp = await client('users', {
      authNeeded: true,
      method: 'PUT',
      data: {
        userId,
        locationId: location,
        role,
        firstName,
        lastName,
        email,
        supervisorNpi,
        supervisorFullName,
        bio: bio !== null ? bio : undefined,
        specialtyIds:
          specialtyIds !== null && !isEmpty(specialtyIds)
            ? specialtyIds
            : undefined,
        notSeeingNewClients: !isEmpty(notSeeingNewClients),
        virtualMeetingLink,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateImage = async (_resource, params) => {
  try {
    const resp = await client('users/image', {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateThumbnail = async (_resource, params) => {
  try {
    const resp = await client('users/thumbnail', {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateVideo = async (_resource, params) => {
  try {
    const resp = await client('users/video', {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadImage = async (_resource, params) => {
  try {
    const { userId, contentType, fileExtension } = params;

    const url = `users/image?userId=${userId}&contentType=${contentType}&fileExtension=${fileExtension}`;
    const resp = await client(url, { authNeeded: true });

    return resp;
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadThumbnail = async (_resource, params) => {
  try {
    const { userId, contentType, fileExtension } = params;

    const url = `users/thumbnail?userId=${userId}&contentType=${contentType}&fileExtension=${fileExtension}`;
    const resp = await client(url, { authNeeded: true });

    return resp;
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadVideo = async (_resource, params) => {
  try {
    const { userId, contentType, fileExtension } = params;
    const url = `users/video?userId=${userId}&contentType=${contentType}&fileExtension=${fileExtension}`;
    const resp = await client(url, { authNeeded: true });

    return resp;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createTeam = async (_resource, params) => {
  const { locationId } = params.data;
  const location = JSON.stringify(locationId);
  try {
    const resp = await client('users', {
      authNeeded: true,
      method: 'POST',
      data: {
        locationId: location,
        ...params.data,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deactivateTeamMember = async (_resource, params) => {
  const { userId } = params;
  try {
    const resp = await client(`users?userId=${userId}`, {
      authNeeded: true,
      method: 'DELETE',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const activateTeamMember = async (_resource, params) => {
  const { userId } = params;
  try {
    const resp = await client(`users/${userId}/activate`, {
      authNeeded: true,
      method: 'PUT',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTeamList,
  getTeamMember,
  updateTeam,
  createTeam,
  getLocations,
  deactivateTeamMember,
  activateTeamMember,
  uploadImage,
  uploadThumbnail,
  uploadVideo,
  updateImage,
  updateThumbnail,
  updateVideo,
};
