import React from 'react';
import {
  EditContextProvider,
  Loading,
  SaveButton,
  SimpleForm,
  Toolbar,
  useEditController,
  useNotify,
  useQuery,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';
import { TreatmentPlanForm } from './treatment-plan-form';

export const TreatmentPlanEdit = ({ ...props }) => {
  const notify = useNotify();
  const controllerProps = useEditController(props);
  const { data, loading } = useQuery({
    type: 'getOne',
    resource: 'treatmentPlan',
    payload: { id: JSON.stringify(props?.rowId) },
  });
  const { data: diagnosisList } = useQuery({
    type: 'getList',
    resource: 'diagnosis',
    payload: { id: props.id },
  });

  const updateTreatmentPlan = payload =>
    customDataProvider.update('treatmentPlan', payload).then(
      () => {
        props.handleClose();
        notify('updated successfully');
      },
      err => {
        notify(err.message);
      },
    );

  const handleSave = values => {
    const payload = {
      ...values,
      partnerDiagnosisId: values?.partnerDiagnosisId || undefined,
      diagnosisId: values?.diagnosisId || undefined,
    };
    updateTreatmentPlan(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const PostEditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Save" onSuccess={handleSave} />
      <Button
        onClick={handleCancel}
        style={{ marginLeft: 10 }}
        className="custom-btn"
      >
        Cancel
      </Button>
    </Toolbar>
  );

  return (
    <div className="intake-width">
      <EditContextProvider value={controllerProps} style={{ width: '100%' }}>
        {loading ? (
          <Loading />
        ) : (
          <SimpleForm
            save={handleSave}
            initialValues={data}
            toolbar={<PostEditToolbar />}
          >
            <TreatmentPlanForm diagnosisList={diagnosisList} />
          </SimpleForm>
        )}
      </EditContextProvider>
    </div>
  );
};
