import React from 'react';
import {
  PasswordInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../assets/oh-white.svg';
import { customDataProvider } from '../data-provider';
import history from '../history';

export const ResetPassword = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const Base64 = {
    atob: (input = '') => {
      const str = input.replace(/=+$/, '');
      let output = '';

      if (str.length % 4 === 1) {
        throw new Error(
          "'atob' failed: The string to be decoded is not correctly encoded.",
        );
      }
      for (
        let bc = 0, bs = 0, buffer, i = 0;
        (buffer = str.charAt(i++));
        ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
          ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
          : 0
      ) {
        buffer = chars.indexOf(buffer);
      }

      return output;
    },
  };

  const parseJwt = token => {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(Base64.atob(base64));
  };
  const submit = form => {
    const encodedValues = parseJwt(history.location.search);
    customDataProvider
      .create('resetPassword', {
        email: encodedValues?.email,
        password: form?.password,
        resetPasswordToken: encodedValues?.tokenId,
      })
      .then(() => {
        notify('reset password link sent');
        redirect('/login');
      })
      .catch(err => {
        notify(
          err?.message ||
            err.response.data.message ||
            'reset password link failed',
        );
      });
  };
  const resetpassword = () => (
    <>
      <div className="page-wrap">
        <div className="login-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="login-wrap">
            <SimpleForm save={submit} toolbar={<LoginToolbar />}>
              <PasswordInput source="password" label="Password" />
              <PasswordInput
                source="confirmPassword"
                label="Confirm Password"
              />
            </SimpleForm>
          </div>
        </div>
      </div>
    </>
  );
  const LoginToolbar = props => (
    <Toolbar {...props}>
      <SaveButton icon={<></>} label="Confirm Password" />
    </Toolbar>
  );
  return (
    <MuiThemeProvider theme={props.theme}>{resetpassword()}</MuiThemeProvider>
  );
};
