import React, { useState } from 'react';
import {
  SelectInput,
  Show,
  SimpleForm,
  Toolbar,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { NoTitle } from '../components';
import { sessions, TransactionTypes } from '../utils/constants';
import { Gifts } from './gifts';
import { Membership } from './membership';
import { Sessions } from './sessions';

export const Transactions = props => {
  const type = JSON.parse(localStorage.getItem('transactionType')) || 1;
  const [transactionType, setTransactionType] = useState(type);
  const sessionId = JSON.parse(localStorage.getItem('sessionId')) || 1;
  const [sessionStatusId, setSessionStatusId] = useState(sessionId);

  const { permissions } = usePermissions();

  const handleSessionChange = record => {
    setSessionStatusId(record.target.value);
    localStorage.setItem('sessionId', record.target.value);
  };
  const handleChange = record => {
    setTransactionType(record.target.value);
    localStorage.setItem('transactionType', record.target.value);
  };
  return (
    <>
      <SimpleForm
        className="styled-form2"
        toolbar={<Toolbar style={{ display: 'none' }} />}
        initialValues={{
          transactionsId: transactionType,
          sessionStatusType: sessionStatusId,
        }}
      >
        <div className="grid-3">
          {permissions !== 'therapist' && permissions !== 'supervisor' && (
            <SelectInput
              source="transactionsId"
              choices={TransactionTypes}
              label=""
              onChange={handleChange}
            />
          )}
          {transactionType === 1 ? (
            <SelectInput
              source="sessionStatusType"
              choices={sessions}
              label=""
              onChange={handleSessionChange}
            />
          ) : null}
        </div>
      </SimpleForm>
      <Show
        title={<NoTitle />}
        {...props}
        actions={<TopToolbar style={{ display: 'none' }} />}
      >
        {transactionType === 1 ? (
          <div style={{ marginBottom: 100 }}>
            <Sessions {...props} sessionStatusId={sessionStatusId} />
          </div>
        ) : transactionType === 2 ? (
          <>
            <Membership {...props} />
          </>
        ) : (
          <>
            <Gifts {...props} />
          </>
        )}
      </Show>
    </>
  );
};
