import { useEffect, useState } from 'react';
import { client as apiClient } from '../services/api-client';
import giftQueries from './gift-queries';

const useStripeTerminal = giftDetails => {
  const [connectionStatus, setConnectionStatus] = useState('not_connected');
  const [terminal, setTerminal] = useState(null);
  const [discoveredReaders, setDiscoveredReaders] = useState([]);
  const [discoveringReaders, setDiscoveringReaders] = useState(false);
  const [discoveryWasCancelled, setDiscoveryWasCancelled] = useState(false);

  const [reader, setReader] = useState(null);
  const [connectingToReader, setConnectingToReader] = useState(false);

  const [usingSimulator, setUsingSimulator] = useState(false);

  const [cancelablePayment, setCancelablePayment] = useState(false);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [collectingPaymentMethod, setCollectingPaymentMethod] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

  useEffect(() => {
    async function initStripeTerminal() {
      const stripeTerminal = window.StripeTerminal.create({
        // 1c. Create a callback that retrieves a new ConnectionToken from the example backend
        onFetchConnectionToken: async () => {
          let connectionTokenResult = await apiClient(
            'users/connection-token',
            { method: 'POST', authNeeded: true },
          );
          return connectionTokenResult.secret;
        },
        // 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
        // You can use this callback to alert your user that the reader is no longer connected and will need to be reconnected.
        onUnexpectedReaderDisconnect: () => {
          alert('Unexpected disconnect from the reader');
          setReader(null);
        },
        // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
        // You can use this callback to update your UI with the reader's connection status.
        onConnectionStatusChange: ev => {
          setConnectionStatus(ev.status);
        },
      });
      setTerminal(stripeTerminal);
    }

    initStripeTerminal();
  }, []);

  const discoverReaders = async (simulate = false) => {
    setDiscoveryWasCancelled(false);
    setDiscoveringReaders(true);
    const discoverResult = await terminal.discoverReaders({
      simulated: simulate,
    });
    setDiscoveringReaders(false);
    if (discoverResult.error) {
      throw new Error(discoverResult.error);
    } else {
      if (discoveryWasCancelled) return;

      setDiscoveredReaders(discoverResult.discoveredReaders);

      return discoverResult.discoveredReaders;
    }
  };

  const cancelDiscoverReaders = () => {
    setDiscoveryWasCancelled(true);
    setDiscoveringReaders(false);
  };

  const connectToReader = async selectedReader => {
    setConnectingToReader(true);
    const connectResult = await terminal.connectReader(selectedReader);
    setConnectingToReader(false);
    setDiscoveredReaders([]);
    if (connectResult.error) {
      throw new Error(connectResult.error);
    } else {
      setUsingSimulator(selectedReader.id === 'SIMULATOR');
      setDiscoveredReaders([]);
      setReader(connectResult.reader);
      return connectResult;
    }
  };

  const connectToSimulator = async () => {
    try {
      const simulatedReaders = await discoverReaders(true);
      return await connectToReader(simulatedReaders[0]);
    } catch (e) {
      throw e;
    }
  };

  const disconnectReader = async () => {
    await terminal?.disconnectReader();
    setReader(null);
  };

  const fetchPaymentIntentSecret = async amount => {
    const intentSecret = paymentIntentSecret
      ? paymentIntentSecret
      : (await giftQueries.fetchGiftPaymentIntent(amount)).client_secret;

    setPaymentIntentSecret(intentSecret);

    return intentSecret;
  };

  const cancelPendingPayment = async () => {
    await terminal.cancelCollectPaymentMethod();
    setCancelablePayment(false);
  };

  const collectCardPayment = async amount => {
    setCollectingPaymentMethod(true);
    // We want to reuse the same PaymentIntent object in the case of declined charges, so we
    // store the pending PaymentIntent's secret until the payment is complete.
    const intentSecret = await fetchPaymentIntentSecret(amount);
    // Read a card from the customer
    terminal.setSimulatorConfiguration({
      testCardNumber: '4242424242424242',
    });

    setCancelablePayment(true);
    const result = await terminal.collectPaymentMethod(intentSecret);

    setCollectingPaymentMethod(false);

    if (result.error) {
      console.log('Collect payment method failed:', result.error.message);
      throw new Error(result.error.message);
    } else {
      setProcessingPayment(true);
      const confirmResult = await terminal.processPayment(result.paymentIntent);
      setCancelablePayment(false);
      setProcessingPayment(false);
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      if (confirmResult.error) {
        setProcessingPayment(false);
        console.log(`Confirm failed: ${confirmResult.error.message}`);
        throw new Error(confirmResult.error.message);
      }
      // else if (confirmResult.paymentIntent) {
      //   if (confirmResult.paymentIntent.status !== 'succeeded') {
      //     try {
      //       // Capture the PaymentIntent from your backend client and mark the payment as complete
      //       let captureResult = await this.client.capturePaymentIntent({
      //         paymentIntentId: confirmResult.paymentIntent.id,
      //       });
      //       setPaymentIntentSecret(null);
      //       console.log('Payment Successful!');
      //       return captureResult;
      //     } catch (e) {
      //       // Suppress backend errors since they will be shown in logs
      //       return;
      //     }
      //   }
      else {
        setPaymentIntentSecret(null);
        console.log('Single-message payment successful!');
        return confirmResult;
      }
    }
  };

  return {
    connectionStatus,
    discoveredReaders,
    discoveringReaders,
    discoveryWasCancelled,
    reader,
    connectingToReader,
    usingSimulator,
    terminal,
    collectingPaymentMethod,
    processingPayment,
    cancelablePayment,

    discoverReaders,
    cancelDiscoverReaders,
    connectToReader,
    connectToSimulator,
    disconnectReader,
    collectCardPayment,
    cancelPendingPayment,
  };
};

export default useStripeTerminal;
