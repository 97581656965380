import { constructUrl } from '../../utils/api';

const { client } = require('../../services/api-client');

const getGifts = async (_resource, params) => {
  try {
    const filter = {};
    filter['isActive.in'] = ['true', 'false'];
    filter['userId'] = params?.id;
    const fields = ['gift.*', 'giftType.*', 'user.*'];
    const url = constructUrl('users/gifts', { fields, filters: filter });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const cancelGiftCard = async (_resource, params) => {
  const { giftId, status } = params;
  try {
    const resp = await client(`gifts/${giftId}`, {
      authNeeded: true,
      method: 'PUT',
      data: { status },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getGifts, cancelGiftCard };
