import { keyBy } from 'lodash';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
} from 'react-admin';
import { useState } from 'react';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { Charge } from './charge';
import { Refund } from '../refund';
import {
  SessionPartnerFullNameField,
  RelationshipGuestNameField,
  TherapistFullNameField,
} from '../../components';
import Button from '@material-ui/core/Button';
import { SessionTransactions } from './session-transactions';

export const SessionsList = props => {
  const [isOpenRefund, setIsOpenRefund] = useState(false);
  const [isOpenCharge, setIsOpenCharge] = useState(false);
  const [isOpenSessionTransactions, setIsOpenSessionTransactions] = useState(
    false,
  );

  const { page, perPage, total, setPage, setPerPage, loading } = props;
  const [sessionId, setSessionId] = useState();

  const handleCloseEdit = () => {
    setIsOpenRefund(false);
    setIsOpenCharge(false);
    setIsOpenSessionTransactions(false);
  };

  return (
    <>
      <ListContextProvider
        value={{
          resource: 'guest',
          basePath: '',
          data: keyBy(props?.data, 'id'),
          ids: props?.data && props?.data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total: props?.total,
          page: props?.page,
          perPage: props?.perPage,
          setPage: props?.setPage,
          setPerPage: props?.setPerPage,
          loading,
        }}
        className="main-table"
      >
        <Datagrid
          rowClick={e => {
            const rowData = props?.data.filter(res => res.id === e);
            setSessionId(rowData[0]);
          }}
        >
          <DateField
            style={{
              textDecoration: props?.transactions ? 'underline' : '',
              color: props?.transactions ? 'blue' : '',
            }}
            record={props?.data && props?.data[0]}
            source="startDateTime"
            showTime
            onClick={() => {
              if (props?.transactions) {
                setIsOpenSessionTransactions(true);
              }
            }}
          />
          <SessionPartnerFullNameField
            record={props?.data && props?.data[0]}
            user={props}
            label="Partner"
          />
          <RelationshipGuestNameField
            record={props?.data && props?.data[0]}
            label="Relationship to Guest"
          />
          <TherapistFullNameField
            record={props?.data && props?.data[0]}
            label="Therapist"
          />
          <FunctionField
            label="Location"
            render={record => {
              console.log('record', record);
              return record?.isVirtual
                ? record?.location?.city + ' - V'
                : record?.location?.city;
            }}
          />
          <FunctionField
            label="Purchased via Promo/Gift"
            render={record =>
              record?.transaction?.isPaidViaMembershipGift ||
              record?.transaction?.appliedPromoCodeId ||
              record?.transaction?.appliedGiftCodeId
                ? 'Yes'
                : 'No'
            }
          />
          {props.charge && (
            <Button
              variant="containedPrimary"
              onClick={() => {
                setIsOpenCharge(true);
              }}
            >
              CHARGE
            </Button>
          )}
        </Datagrid>
        {loading && <Loading className="plain-loading" />}
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenRefund}
              anchor="right"
              onClose={handleCloseEdit}
            >
              <Refund
                {...props}
                handleClose={handleCloseEdit}
                sessionId={sessionId}
                type="Sessions"
                isPaidViaSessionCredits={sessionId?.isPaidViaSessionCredits}
              />
            </Drawer>
          )}
        />
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenCharge}
              anchor="right"
              onClose={handleCloseEdit}
            >
              <Charge
                {...props}
                handleClose={handleCloseEdit}
                sessionId={sessionId}
                type="Session"
                isPaidViaSessionCredits={sessionId?.isPaidViaSessionCredits}
              />
            </Drawer>
          )}
        />

        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenSessionTransactions}
              anchor="right"
              onClose={handleCloseEdit}
            >
              <SessionTransactions
                {...props}
                sessionId={sessionId}
                type="Session"
                isPaidViaSessionCredits={sessionId?.isPaidViaSessionCredits}
              />
            </Drawer>
          )}
        />

        <Pagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
          setPerPage={setPerPage}
        />
      </ListContextProvider>
    </>
  );
};
