import { Show, TopToolbar, useQuery } from 'react-admin';
import { SessionsList } from './session-list';
import { NoTitle } from '../../components';
import { useState } from 'react';
import { isEmpty } from 'lodash';

export const Sessions = props => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const {
    data: upcomingList,
    total: upcomingListTotal,
    loading: upcomingListLoading,
  } = useQuery({
    type: 'getList',
    resource: 'sessions',
    payload: {
      pagination: { page, perPage },
      id: props?.id,
      partnerId: props?.id,
      filter: {
        'status.in': ['upcoming', 'checked-in'],
        sortField: 'startDateTime',
        sortOrder: 'asc',
      },
    },
  });
  const {
    data: completedList,
    total: completedListTotal,
    loading: completedListLoading,
  } = useQuery({
    type: 'getList',
    resource: 'sessions',
    payload: {
      pagination: { page, perPage },
      id: props?.id,
      partnerId: props?.id,
      filter: {
        status: 'completed',
        sortField: 'startDateTime',
        sortOrder: 'desc',
      },
    },
  });
  const {
    data: cancelNoShowList,
    total: cancelNoShowListTotal,
    loading: cancelNoShowListLoading,
  } = useQuery({
    type: 'getList',
    resource: 'sessions',
    payload: {
      pagination: { page, perPage },
      id: props?.id,
      partnerId: props?.id,
      filter: {
        'status.in': ['canceled', 'no-show'],
        sortField: 'startDateTime',
        sortOrder: 'asc',
      },
    },
  });

  return (
    <>
      <Show
        title={<NoTitle />}
        {...props}
        actions={<TopToolbar style={{ display: 'none' }} />}
      >
        {props?.sessionStatusId === 1 ? (
          <SessionsList
            {...props}
            data={!isEmpty(upcomingList) ? upcomingList : []}
            loading={upcomingListLoading}
            total={upcomingListTotal}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            upcomingList
            charge={true}
            transactions={true}
          />
        ) : props?.sessionStatusId === 2 ? (
          <SessionsList
            {...props}
            data={!isEmpty(completedList) ? completedList : []}
            loading={completedListLoading}
            refund={true}
            total={completedListTotal}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            charge={true}
            transactions={true}
          />
        ) : (
          <SessionsList
            {...props}
            data={!isEmpty(cancelNoShowList) ? cancelNoShowList : []}
            loading={cancelNoShowListLoading}
            refund={true}
            total={cancelNoShowListTotal}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            cancelledList
            transactions={true}
          />
        )}
      </Show>
    </>
  );
};
