import React from 'react';
import { Create, SimpleForm, useNotify, useRedirect } from 'react-admin';
import { PromoForm } from './promo-forms';

export const PromoCreate = props => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = res => {
    if (res?.data?.role !== 'therapist') {
      redirect('/promo');
    }
  };
  const onError = err => {
    notify(err.response.data.message, 'error');
  };
  return (
    <div className="intake-width mb63 promo-wrap">
      <Create
        title="Create Promo"
        onSuccess={onSuccess}
        onError={onError}
        onFailure={onError}
        {...props}
      >
        <SimpleForm>
          <PromoForm data={props.record} />
        </SimpleForm>
      </Create>
    </div>
  );
};
