import { keyBy } from 'lodash';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  useQuery,
} from 'react-admin';
import { CustomButton, GetPriceField } from '../../components';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { Refund } from '../refund';

export const Membership = ({ ...props }) => {
  const [isOpenRefund, setIsOpenRefund] = useState(false);

  const [membershipId, setMembershipId] = useState();
  const { data } = useQuery({
    type: 'getList',
    resource: 'membership',
    payload: { id: props.id },
  });

  const handleCloseEdit = () => {
    setIsOpenRefund(false);
  };
  return (
    <>
      <ListContextProvider
        value={{
          resource: 'guest',
          basePath: '',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          perPage: 25,
        }}
        className="main-table"
      >
        <Datagrid
          rowClick={e => {
            const rowData = data.filter(res => res.id === e);
            setMembershipId(rowData[0]);
          }}
        >
          <DateField
            record={data && data[0]}
            source="updatedAt"
            label="Created/Updated Date/Time"
            showTime
          />
          <FunctionField
            label="Purchased via Promo/Gift"
            render={record =>
              record?.transaction?.isPaidViaMembershipGift ||
              record?.transaction?.appliedPromoCodeId ||
              record?.transaction?.appliedGiftCodeId
                ? 'Yes'
                : 'No'
            }
          />
          <GetPriceField
            label="CC Total"
            source="transaction.finalPrice"
            parentKey="transaction"
            childKey="finalPrice"
          />
          <GetPriceField
            label="Refunded amount"
            source="transaction.totalRefunded"
            parentKey="transaction"
            childKey="totalRefunded"
          />
          <CustomButton
            setIsOpenModal={setIsOpenRefund}
            record={data && data[0]}
          />
        </Datagrid>
        <div style={{ height: 50 }}></div>
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenRefund}
              anchor="right"
              onClose={handleCloseEdit}
            >
              <Refund
                {...props}
                handleClose={handleCloseEdit}
                membershipId={membershipId}
                type="Membership"
              />
            </Drawer>
          )}
        />
      </ListContextProvider>
    </>
  );
};
