import { constructUrl } from '../utils/api';
import dayjs from 'dayjs';
import { getProducts, getRestrictions } from '../utils/common';

const { client } = require('../services/api-client');

const getPromoList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;
    if (filter.email) {
      filter.condition = 'or';
      filter['code.lk'] = filter.email;

      delete filter.email;
    }

    const url = constructUrl('promo-codes', {
      filters: filter,
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.page - 1),
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPromo = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id, ...params.filter };
    const url = constructUrl('promo-codes', { filters });
    const resp = await client(url, { authNeeded: true });
    const data = resp.items[0];
    const restrictions = getRestrictions(data);
    const products = getProducts(data);

    return {
      data: {
        ...data,
        products,
        restrictions,
        discountValue: data?.isDiscountPercentage
          ? data?.discountValue
          : data?.discountValue / 100,
      },
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLocations = async (_resource, params) => {
  try {
    const url = constructUrl('locations', {});
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updatePromo = async (_resource, params) => {
  const {
    id,
    products,
    restrictions,
    validityStartDateTime,
    validityEndDateTime,
    validityStartDateTimeTZ,
    validityEndDateTimeTZ,
    createdAt,
    updatedAt,
    isForFirstTimeGuestsOnly,
    isOneTimePerGuest,
    isApplicableOnMembership,
    isApplicableOnSession,
    isApplicableOnGift,
    discountValue,
    note,
    maxRedemptionCount,
    updatedByUserId,
    usedCount,
    ...rest
  } = params.data;

  try {
    const resp = await client(`promo-codes/${id}`, {
      authNeeded: true,
      method: 'PUT',
      data: {
        ...rest,
        isForFirstTimeGuestsOnly: restrictions.includes(
          'isForFirstTimeGuestsOnly',
        ),
        isOneTimePerGuest: restrictions.includes('isOneTimePerGuest'),
        isApplicableOnSession: products.includes('isApplicableOnSession'),
        isApplicableOnMembership: products.includes('isApplicableOnMembership'),
        isApplicableOnGift: products.includes('isApplicableOnGift'),
        validityStartDateTime:
          validityStartDateTime &&
          new Date(validityStartDateTime).getTime() >=
            new Date(dayjs(new Date()).subtract(1, 'day')).getTime()
            ? dayjs(validityStartDateTime).format('YYYY-MM-DDTHH:mm:ss')
            : undefined,
        validityEndDateTime:
          validityEndDateTime &&
          new Date(validityEndDateTime).getTime() >=
            new Date(dayjs(new Date()).subtract(1, 'day')).getTime()
            ? dayjs(validityEndDateTime).format('YYYY-MM-DDTHH:mm:ss')
            : undefined,
        discountValue: rest.isDiscountPercentage
          ? discountValue
          : discountValue * 100,
        note: note !== null ? note : undefined,
        maxRedemptionCount:
          maxRedemptionCount !== null ? maxRedemptionCount : undefined,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const createPromo = async (_resource, params) => {
  const {
    products,
    restrictions,
    validityStartDateTime,
    validityEndDateTime,
    discountValue,
    ...rest
  } = params.data;

  try {
    const resp = await client('promo-codes', {
      authNeeded: true,
      method: 'POST',
      data: {
        isForFirstTimeGuestsOnly: restrictions?.includes(
          'isForFirstTimeGuestsOnly',
        )
          ? true
          : false,
        isOneTimePerGuest: restrictions?.includes('isOneTimePerGuest')
          ? true
          : false,
        isApplicableOnSession: products?.includes('isApplicableOnSession')
          ? true
          : false,
        isApplicableOnMembership: products?.includes('isApplicableOnMembership')
          ? true
          : false,
        isApplicableOnGift: products?.includes('isApplicableOnGift')
          ? true
          : false,
        validityStartDateTime: validityStartDateTime
          ? dayjs(validityStartDateTime).format('YYYY-MM-DDTHH:mm:ss')
          : undefined,
        validityEndDateTime: validityEndDateTime
          ? dayjs(validityEndDateTime).format('YYYY-MM-DDTHH:mm:ss')
          : undefined,
        discountValue: rest.isDiscountPercentage
          ? discountValue
          : discountValue * 100,
        ...rest,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deletePromo = async (_resource, params) => {
  const { id } = params;
  try {
    const resp = await client(`promo-codes/${id}`, {
      authNeeded: true,
      method: 'DELETE',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPromoList,
  getPromo,
  updatePromo,
  createPromo,
  getLocations,
  deletePromo,
};
