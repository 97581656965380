import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getGuestNotesList = async (_resource, params) => {
  try {
    const fields = ['therapist.*'];
    const filters = { userId: params?.id };

    const url = constructUrl('users/okay-kit/inbox', { fields, filters });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGuestNotes = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id };
    const fields = [];

    const url = constructUrl('users/okay-kit/inbox', {
      filters,
      fields,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const updateGuestNotes = async (_resource, params) => {
  const { id, note, resources } = params;
  try {
    const resp = await client(`users/okay-kit/inbox/${id}`, {
      authNeeded: true,
      method: 'PUT',
      data: {
        note,
        resources,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const createGuestNotes = async (_resource, params) => {
  try {
    const resp = await client(`users/okay-kit/inbox`, {
      authNeeded: true,
      method: 'POST',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const deleteGuestNote = async (_resource, params) => {
  const { noteId } = params;
  try {
    const resp = await client(`users/okay-kit/inbox/${noteId}`, {
      authNeeded: true,
      method: 'DELETE',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGuestNotesList,
  getGuestNotes,
  updateGuestNotes,
  createGuestNotes,
  deleteGuestNote,
};
