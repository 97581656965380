import React from 'react';
import { DateInput, NumberInput, TextInput } from 'react-admin';

// const dateFormatter = v => {
//   // v is a `Date` object
//   if (!(v instanceof Date) || isNaN(v)) return;
//   const pad = '00';
//   const yy = v.getFullYear().toString();
//   const mm = (v.getMonth() + 1).toString();
//   const dd = v.getDate().toString();
//   // return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
//   return `${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}-${yy}`;
// };

// const dateParser = v => {
//   // v is a string of "YYYY-MM-DD" format
//   const match = /(\d{2})-(\d{2})-(\d{4})/.exec(v);
//   if (match === null) return;
//   const d = new Date(parseInt(match[2], 10) - 1, match[3], match[1]);
//   if (isNaN(d)) return;
//   return d;
// };

export const ChartNotesForm = () => (
  <>
    <TextInput
      source="theme"
      multiline
      label="Theme:"
      style={{ width: '100%' }}
    />
    <TextInput
      source="observation"
      multiline
      label="Observation/Affect"
      style={{ width: '100%' }}
    />
    <TextInput
      source="intervention"
      multiline
      label="Intervention"
      style={{ width: '100%' }}
    />
    <DateInput
      source="sessionDate"
      label="Session Date"
      style={{ width: '100%' }}
      // format={dateFormatter}
      // parse={() => dateParser('MM-DD-YYYY')}
    />
    <NumberInput
      source="sessionNumber"
      label="Session Number"
      style={{ width: '100%' }}
    />
  </>
);
