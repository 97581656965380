import React, { useEffect, useState } from 'react';
import {
  CreateContextProvider,
  required,
  SelectInput,
  SimpleForm,
  useCreateController,
  useNotify,
  useQuery,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import { ChartNotesForm } from './chart-notes-form';
import { ActionButtons, FullNameField } from '../components';

export const ChartNotesCreate = props => {
  const notify = useNotify();
  const controllerProps = useCreateController(props);
  const { data } = useQuery({
    type: 'getList',
    resource: 'userTherapists',
    payload: { userId: props.id },
  });

  const [user, setUser] = useState('');

  const handleSave = values => {
    const { id } = props;
    const payload = {
      ...values,
      userId: id,
      therapistId:
        user?.role === 'therapist' || user?.role === 'supervisor'
          ? undefined
          : values?.therapistId,
    };
    createChartNotes(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };
  const createChartNotes = payload =>
    customDataProvider.create('chartNotes', payload).then(
      () => {
        props.handleClose();
        notify('created successfully');
      },
      err => {
        notify(err.message);
      },
    );

  const validateTherapistId = [required()];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  const preSelectedTherapist = data?.length === 1 ? data[0] : null;

  return (
    <div className="intake-width">
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          save={handleSave}
          initialValues={{
            therapistId: preSelectedTherapist?.id || undefined,
          }}
          toolbar={
            <ActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          }
        >
          {user?.role !== 'therapist' && (
            <SelectInput
              source="therapistId"
              choices={data}
              label="Therapists"
              optionText={<FullNameField />}
              validate={validateTherapistId}
              className="text-indent"
            />
          )}
          <ChartNotesForm data={props.record} />
        </SimpleForm>
      </CreateContextProvider>
    </div>
  );
};
