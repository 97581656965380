import { isEmpty } from 'lodash';
import React from 'react';
import { required, SelectInput, TextInput, useQuery } from 'react-admin';
import { PartnerFullNameField, OptionsField } from '../components';

const validateUserID = [required()];

export const TherapistIntakeForm = ({ userPartners, userId, isEdit }) => {
  const { data, loading: isDiagnosisLoading } = useQuery({
    type: 'getList',
    resource: 'diagnosis',
    payload: {},
  });
  const { data: userObj, loading: isUserLoading } = useQuery({
    type: 'getOne',
    resource: 'guest',
    payload: { id: userId },
  });

  if (isUserLoading || isDiagnosisLoading) {
    return null;
  }

  return (
    <>
      <TextInput
        source="presentationInfo"
        multiline
        label="Presenting problem & symptoms (can refer to OQ - appetite, concentration, sleep, energy,
mood, anxiety, hopelessness, etc):"
        style={{ width: '100%' }}
      />
      <TextInput
        source="generalHistory"
        multiline
        label="General relevant history? (relationships, friends, family, children, support, living situation,
occupation, financial concerns, legal issues, school, developmental history)"
        style={{ width: '100%' }}
      />
      <TextInput
        source="relationshipHistory"
        multiline
        label="Relevant history of couple’s relationship"
        style={{ width: '100%' }}
      />
      <TextInput
        source="behaviourStruggleInfo"
        multiline
        label="Current or past struggle with difficult feelings, thoughts, and/or behaviors about food/weight and/or treatment? If present, please elaborate (circumstance, seriousness, interventions and any insights or awareness guest has)"
        style={{ width: '100%' }}
      />
      <TextInput
        source="alcoholDrugUsageInfo"
        multiline
        style={{ width: '100%' }}
        label="Current or past alcohol/drug use and/or treatment? If present, please elaborate
            (circumstance, seriousness, interventions and any insights or awareness guest has)"
      />
      <TextInput
        source="violentBehaviourInfo"
        multiline
        style={{ width: '100%' }}
        label="Current or past self-harm, suicidal, homicidal, or violent tendencies? If present, please
            elaborate (circumstance, others involved, seriousness, interventions and any insights or
            awareness guest has)."
      />
      <TextInput
        source="violentBehaviourHistory"
        multiline
        style={{ width: '100%' }}
        label="Any prior suicide attempts and/or hospitalizations for mental health? If present, please
            elaborate (circumstance, plan, mean, seriousness/number of attempt(s), interventions and
            any insights or awareness guest has)"
      />
      <TextInput
        source="safetyConcerns"
        multiline
        style={{ width: '100%' }}
        label="Any ethical and/or safety concerns (contract for safety needed?)"
      />
      <TextInput
        source="medicalHistory"
        multiline
        style={{ width: '100%' }}
        label="Medical (any concerns, current medications & dosage, experience of meds, prescribing
              Dr.)"
      />
      <div>Mental Status Exam:</div>
      {userObj && (
        <TextInput
          disabled={true}
          source="firstName"
          style={{ width: '100%' }}
          validate={validateUserID}
          label="Guest Name"
          initialValue={`${userObj?.firstName} ${userObj?.lastName}`}
        />
      )}
      <TextInput
        source="appearance"
        style={{ width: '100%' }}
        label="Appearance"
        multiline
      />
      <TextInput
        source="hygiene"
        style={{ width: '100%' }}
        label="Hygiene"
        multiline
      />
      <TextInput
        source="mood"
        style={{ width: '100%' }}
        label="Mood"
        multiline
      />
      <TextInput
        source="eyeContact"
        style={{ width: '100%' }}
        label="Eye Contact"
        multiline
      />
      <TextInput
        source="speech"
        style={{ width: '100%' }}
        label="Speech"
        multiline
      />
      <TextInput
        source="affect"
        style={{ width: '100%' }}
        label="Affect"
        multiline
      />
      <TextInput
        source="engagement"
        style={{ width: '100%' }}
        label="Ability to Engage Interviewer"
        multiline
      />
      <TextInput
        source="thoughtDisorder"
        style={{ width: '100%' }}
        label="Evidence of Thought Disorder"
        multiline
      />
      <TextInput
        source="cognitiveFunctions"
        style={{ width: '100%' }}
        label="Cognitive functions: (orientation, intelligence, fund of information, memory, global
              judgment, capacity for insight)"
        multiline
      />
      {!isEmpty(userPartners) ? (
        <>
          <div>Partner (Optional)</div>
          <SelectInput
            source="partnerId"
            choices={userPartners || []}
            label="Partner Name"
            style={{ width: '100%' }}
            optionText={<PartnerFullNameField />}
            optionValue="partnerId"
            // disabled={isEdit} TODO: Need to enable it only when partner isn't set
          />
          <TextInput
            source="partnerAppearance"
            style={{ width: '100%' }}
            label="Appearance"
            multiline
          />
          <TextInput
            source="partnerHygiene"
            style={{ width: '100%' }}
            label="Hygiene"
            multiline
          />
          <TextInput
            source="partnerMood"
            style={{ width: '100%' }}
            label="Mood"
            multiline
          />
          <TextInput
            source="partnerEyeContact"
            style={{ width: '100%' }}
            label="Eye Contact"
            multiline
          />
          <TextInput
            source="partnerSpeech"
            style={{ width: '100%' }}
            label="Speech"
            multiline
          />
          <TextInput
            source="partnerAffect"
            style={{ width: '100%' }}
            label="Affect"
            multiline
          />
          <TextInput
            source="partnerEngagement"
            style={{ width: '100%' }}
            label="Ability to Engage Interviewer"
            multiline
          />
          <TextInput
            source="partnerThoughtDisorder"
            style={{ width: '100%' }}
            label="Evidence of Thought Disorder"
            multiline
          />
          <TextInput
            source="partnerCognitiveFunctions"
            style={{ width: '100%' }}
            label="Cognitive functions: (orientation, intelligence, fund of information, memory, global
              judgment, capacity for insight)"
            multiline
          />
        </>
      ) : null}
      <TextInput
        source="therapyHistory"
        style={{ width: '100%' }}
        label="Any prior therapy, how was the experience,with whom?"
        multiline
      />
      <TextInput
        source="generalImpression"
        style={{ width: '100%' }}
        label="General impressions/conceptualization (motivation for therapy)"
        multiline
      />
      <TextInput
        source="specialRequests"
        style={{ width: '100%' }}
        label="Special considerations/requests"
        multiline
      />
      <SelectInput
        source="diagnosisId"
        choices={data}
        label="Tentative Diagnostic Impressions"
        style={{ width: '100%' }}
        optionText={<OptionsField />}
      />
    </>
  );
};
