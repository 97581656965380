import React from 'react';
import {
  Create,
  NumberInput,
  required,
  SimpleForm,
  useNotify,
  useRedirect,
} from 'react-admin';
import { customDataProvider } from '../data-provider';

export const GiftCodesCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = values => {
    const payload = {
      quantity: values.quantity,
    };

    customDataProvider.create('giftCodes', payload).then(
      () => {
        notify('created successfully');
        redirect('/gift');
      },
      err => {
        notify(err.message);
      },
    );
  };

  const validate = [required()];

  return (
    <div className="intake-width">
      <Create {...props} basePath="gift-codes" resource="giftCodes">
        <SimpleForm save={handleSave}>
          <NumberInput
            source="quantity"
            label="Quantity"
            validate={validate}
            min={1}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};
