import Button from '@material-ui/core/Button';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { isEmpty } from 'lodash';
import React from 'react';
import { useNotify } from 'react-admin';
import { customDataProvider } from '../data-provider';

const useOptions = () => {
  const fontSizes = '16px';
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize: fontSizes,
          iconColor: 'blue',
          color: 'blue',
          letterSpacing: '0.025em',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: 'black',
          },
        },
        invalid: {
          color: 'red',
        },
      },
    }),
    [fontSizes],
  );

  return options;
};

const getInitialElementStates = () => {
  const cardElements = ['cardNumber', 'cardExpiry', 'cardCvc', 'postalCode'];

  const elementMap = {};
  cardElements.forEach(el => {
    elementMap[el] = {
      error: null,
      empty: true,
      complete: false,
      showError: false,
    };
  });

  return elementMap;
};

const PaymentForm = ({ setupIntent, setIsOpenEdit, props }) => {
  const notify = useNotify();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  //   const { user } = useAuth();
  const [postalCode, setPostalCode] = React.useState('');
  const [paymentFormState, setPaymentFormState] = React.useState(
    getInitialElementStates(),
  );
  const createPaymentMethod = async payload => {
    customDataProvider.create('createPaymentMethod', payload).then(
      () => {
        notify('successfully payment method created');
        setIsOpenEdit();
      },
      err => {
        notify(err.message);
      },
    );
  };

  const confirmPaymentMethod = async () => {
    if (!stripe || !elements || isEmpty(setupIntent)) {
      return;
    }

    const cardNumberEle = elements.getElement(CardNumberElement);

    try {
      stripe
        .confirmCardSetup(setupIntent.data.client_secret, {
          payment_method: {
            card: cardNumberEle,
            billing_details: {
              name: `${props?.record?.firstName} ${props?.record?.lastName}`,
              address: {
                postal_code: postalCode,
              },
            },
          },
        })
        .then(res => {
          const payload = {
            paymentMethodId: res.setupIntent.payment_method,
            isApplePay: false,
            userId: Number(props?.id),
          };
          createPaymentMethod(payload);
        })
        .catch(err => {
          notify(err.message);
        });
      // }
    } catch (err) {
      notify(err.message);
    }
  };

  const handlePostalCodeChange = value => {
    if (value?.length <= 5) {
      const newPaymentFormState = { ...paymentFormState };
      newPaymentFormState.postalCode.error =
        value.length !== 5 ? { message: 'Invalid Postal Code' } : null;
      newPaymentFormState.postalCode.empty = false;
      newPaymentFormState.postalCode.complete = value.length === 5;
      setPaymentFormState(newPaymentFormState);
      setPostalCode(value);
    }
  };

  const handleFieldChange = e => {
    const { elementType } = e;
    const newPaymentFormState = { ...paymentFormState };
    newPaymentFormState[elementType].error = {
      ...e.error,
    };
    newPaymentFormState[elementType].empty = false;
    newPaymentFormState[elementType].complete = e.complete;
    setPaymentFormState(newPaymentFormState);
  };

  const handleFieldBlur = e => {
    const { elementType } = e;
    const newPaymentFormState = { ...paymentFormState };
    if (newPaymentFormState[elementType || 'postalCode'].error) {
      newPaymentFormState[elementType || 'postalCode'].showError = true;
      setPaymentFormState(newPaymentFormState);
    }
  };

  return (
    <>
      <div>
        <div className="stripe-wrapper">
          <div className="stripe-label">Credit Card Number</div>
          <div className="stripe-inputs">
            <CardNumberElement
              options={{ ...options, showIcon: true, iconStyle: 'solid' }}
              onChange={handleFieldChange}
            />
          </div>
          {!isEmpty(paymentFormState.cardNumber.error) ? (
            <div>{paymentFormState.cardNumber.error?.message}</div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="stripe-wrapper">
          <div className="stripe-label">Expiration</div>
          <div className="stripe-inputs">
            <CardExpiryElement options={options} onChange={handleFieldChange} />
          </div>
          {!isEmpty(paymentFormState.cardExpiry.error) ? (
            <div>{paymentFormState.cardExpiry.error?.message}</div>
          ) : null}
        </div>
        <div className="stripe-wrapper">
          <div className="stripe-label">CVC</div>
          <div className="stripe-inputs">
            <CardCvcElement options={options} onChange={handleFieldChange} />
          </div>
          {!isEmpty(paymentFormState.cardCvc.error) ? (
            <div>{paymentFormState.cardCvc.error?.message}</div>
          ) : null}
        </div>
      </div>
      <div className="stripe-wrapper">
        <div className="stripe-label">PostalCode</div>
        <input
          className="stripe-inputs-new"
          type="number"
          onChange={e => handlePostalCodeChange(e.target.value)}
          onBlur={handleFieldBlur}
        />
      </div>
      <Button
        style={{ width: '100%' }}
        variant="containedPrimary"
        isabled={!stripe}
        onClick={confirmPaymentMethod}
      >
        Next
      </Button>
    </>
  );
};

export default PaymentForm;
