import { keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Loading,
  TextField,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { customDataProvider } from '../data-provider';

export const GuestDischarge = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [isOpenDischargeGuest, setIsOpenDischargeGuest] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  const { data, loading } = useQuery({
    type: 'getList',
    resource: 'therapies',
    payload: { id: props.id },
  });

  const handleCloseDischarge = () => {
    setIsOpenDischargeGuest(false);
  };

  const [rowId, setRowId] = useState(0);

  const handleGuestDischarge = () => {
    customDataProvider.update('therapies', { therapyId: rowId }).then(
      () => {
        handleCloseDischarge();
        notify('The guest has been discharged successfully');
        refresh();
      },
      err => {
        notify(err.message);
      },
    );
  };

  return (
    <>
      <ListContextProvider
        value={{
          resource: 'guest',
          basePath: '',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          perPage: 25,
        }}
        className="main-table"
      >
        <Datagrid
          rowClick={e => {
            setRowId(e);
          }}
        >
          <DateField
            record={data && data[0]}
            source="updatedAt"
            label="Created/Updated Date/Time"
            showTime
          />
          <TextField
            record={data && data[0]}
            label="isOngoing"
            source="isOngoing"
          />
          {user?.role !== 'guest-experience' && (
            <FunctionField
              label=""
              render={record =>
                record.isOngoing ? (
                  <Button
                    variant="containedPrimary"
                    onClick={e => {
                      setIsOpenDischargeGuest(true);
                    }}
                  >
                    Discharge Guest
                  </Button>
                ) : null
              }
            />
          )}
        </Datagrid>
        {loading && <Loading className="plain-loading" />}
        <div style={{ height: 50 }}></div>
        <Route
          path={`/guest/${props.id}`}
          render={() => (
            <Drawer
              open={isOpenDischargeGuest}
              anchor="right"
              onClose={handleCloseDischarge}
            >
              <div style={{ width: 300, padding: 20 }}>
                <h2>Discharge Guest</h2>
                <div style={{ marginBottom: 20 }}>
                  Are you sure you want to discharge the guest? You should only
                  do so if you/they have chosen to end their therapy. By
                  discharging, they will be sent their final OQ surveys and
                  their status will be updated.
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}
                >
                  <Button
                    variant="outlinedPrimary"
                    onClick={handleCloseDischarge}
                    style={{
                      color: 'rgb(21, 54, 76)',
                      borderColor: 'rgb(21, 54, 76)',
                      marginTop: 10,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="containedPrimary"
                    onClick={handleGuestDischarge}
                  >
                    Discharge Guest
                  </Button>
                </div>
              </div>
            </Drawer>
          )}
        />
      </ListContextProvider>
    </>
  );
};
