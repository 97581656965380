import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const refund = async (_resource, params) => {
  try {
    const resp = await client(`users/refund`, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getRefundReasons = async (_resource, params) => {
  try {
    const url = constructUrl('refund-reason', {});

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};
const queries = { refund, getRefundReasons };
export default queries;
