import React, { useEffect, useState } from 'react';
import {
  CreateContextProvider,
  required,
  SelectInput,
  SimpleForm,
  useCreateController,
  useNotify,
  useQuery,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import { TherapistIntakeForm } from './therapist-intake-form';
import { ActionButtons, FullNameField } from '../components';

export const TherapistIntakeCreate = props => {
  const notify = useNotify();
  const controllerProps = useCreateController(props);
  const {
    data: therapists,
    loading: therapistLoading,
    loaded: therapistsLoaded,
  } = useQuery({
    type: 'getList',
    resource: 'userTherapists',
    payload: { userId: props.id },
  });

  const [user, setUser] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  const { data: userPartners, loading: userPartnersLoading } = useQuery({
    type: 'getList',
    resource: 'userPartners',
    payload: { id: props.id },
  });

  const handleSave = values => {
    const { id } = props;
    const payload = {
      ...values,
      userId: id,
      therapistId:
        user?.role === 'therapist' || user?.role === 'supervisor'
          ? undefined
          : values?.therapistId,
    };
    createTherapistIntake(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const createTherapistIntake = payload =>
    customDataProvider.create('therapists-intake', payload).then(
      () => {
        handleCancel();
        notify('created successfully');
      },
      err => {
        notify(err.message, 'error');
      },
    );

  const validateTherapistId = [required()];

  const preSelectedTherapist = therapists?.length === 1 ? therapists[0] : null;

  if (therapistLoading || userPartnersLoading) {
    return null;
  }

  return (
    <>
      {therapistsLoaded ? (
        <div className="intake-width">
          <CreateContextProvider value={controllerProps}>
            <SimpleForm
              save={handleSave}
              initialValues={{
                userId: props.id,
                therapistId: preSelectedTherapist?.id || undefined,
              }}
              toolbar={
                <ActionButtons
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                  style={{ width: 150 }}
                />
              }
            >
              {user?.role !== 'therapist' && (
                <SelectInput
                  source="therapistId"
                  choices={therapists}
                  label="Therapists"
                  optionText={<FullNameField />}
                  validate={validateTherapistId}
                  className="text-indent"
                />
              )}
              <div className="intake-form">
                <TherapistIntakeForm
                  data={props.record}
                  userPartners={userPartners}
                  userId={props.id}
                />
              </div>
            </SimpleForm>
          </CreateContextProvider>
        </div>
      ) : null}
    </>
  );
};
