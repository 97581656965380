const { client } = require('../services/api-client');

const getReportList = async (_resource, params) => {
  try {
    let { filter } = params;

    // const url = constructUrl(`report?startDate=2022-02-01&endDate=2022-02-28`);
    const searchReports =
      new Date(filter?.startDate).getTime() <
      new Date(filter?.endDate).getTime();
    if (filter?.search && searchReports) {
      const resp = await client(
        `report?startDate=${filter?.startDate}&endDate=${filter?.endDate}`,
        { authNeeded: true },
      );
      return {
        data: [{ ...resp, id: 1 }],
        total: 1,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const getDownloadableReportList = async (_resource, params) => {
  try {
    let { filter } = params;
    const searchReports =
      new Date(filter?.startDate).getTime() <
      new Date(filter?.endDate).getTime();
    if (filter?.type && searchReports) {
      const resp = await client(
        `session-report?startDate=${filter?.startDate}&endDate=${filter?.endDate}&type=${filter?.type}`,
        { authNeeded: true },
      );
      return {
        data: resp,
        total: resp?.length || 0,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getReportList,
  getDownloadableReportList,
};
