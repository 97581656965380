import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getAllUserList = async (_resource, params) => {
  try {
    const resp = await client('users?filters=role=user', { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTreatmentPlanList = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = {
      userId: id,
    };
    const fields = ['therapist.*'];
    const url = constructUrl('users/treatment-plan', { filters, fields });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getTreatmentPlan = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id };

    const url = constructUrl('users/treatment-plan', {
      filters,
    });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const createTreatmentPlan = async (_resource, params) => {
  const { firstName, ...rest } = params;
  try {
    const resp = await client(`users/treatment-plan`, {
      authNeeded: true,
      method: 'POST',
      data: rest,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const updateTreatmentPlan = async (_resource, params) => {
  const {
    id,
    userId,
    therapistId,
    partnerId,
    createdAt,
    updatedAt,
    updatedAtDateTimeTZ,
    firstName,
    ...rest
  } = params;

  try {
    const resp = await client(`users/treatment-plan/${id}`, {
      authNeeded: true,
      method: 'PUT',
      data: rest,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getDiagnosisList = async (_resource, params) => {
  try {
    const resp = await client('diagnosis', { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const queries = {
  getTreatmentPlanList,
  getTreatmentPlan,
  createTreatmentPlan,
  updateTreatmentPlan,
  getDiagnosisList,
  getAllUserList,
};
export default queries;
