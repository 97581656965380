import React from 'react';
import {
  EditContextProvider,
  Loading,
  SimpleForm,
  useEditController,
  useNotify,
  useQuery,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import { ChartNotesForm } from './chart-notes-form';
import { ActionButtons } from '../components';

export const ChartNotesEdit = ({ ...props }) => {
  const notify = useNotify();
  const controllerProps = useEditController(props);
  const { data, loading } = useQuery({
    type: 'getOne',
    resource: 'chartNotes',
    payload: { id: JSON.stringify(props?.rowId) },
  });

  const handleSave = values => {
    const payload = {
      ...values,
    };
    delete payload.partnerId;
    updateChartnote(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const updateChartnote = payload =>
    customDataProvider.update('chartNotes', payload).then(
      () => {
        props.handleClose();
        notify('updated successfully');
      },
      err => {
        notify(err.message);
      },
    );

  return (
    <div className="intake-width">
      <EditContextProvider value={controllerProps} style={{ width: '100%' }}>
        {loading ? (
          <Loading />
        ) : (
          <SimpleForm
            save={handleSave}
            initialValues={data}
            toolbar={
              <ActionButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            }
          >
            <ChartNotesForm />
          </SimpleForm>
        )}
      </EditContextProvider>
    </div>
  );
};
