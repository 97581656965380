import { constructUrl } from '../../utils/api';

const { client } = require('../../services/api-client');

const getSessions = async (_resource, params) => {
  try {
    const fields = [
      'partner.*',
      'therapist.*',
      'location.*',
      'user.relationships.*',
    ];
    let { pagination, filter } = params;

    let url = constructUrl('sessions', {
      fields,
      filters: {
        condition1: params?.id && params?.partnerId ? 'or' : undefined,
        userId: params?.id,
        partnerId: params?.partnerId ? params?.partnerId : undefined,
        condition2: 'and',
        ...filter,
      },
      limit: pagination?.perPage,
      offset: pagination?.perPage * (pagination?.page - 1),
    });

    url = url.replace('condition1', 'condition');
    url = url.replace('condition2', 'condition');

    const resp = await client(url, {
      authNeeded: true,
    });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const syncZenotiSessions = async (_resource, params) => {
  const { id: userId } = params;
  try {
    const resp = await client(`users/${userId}/zenoti-session-sync`, {
      authNeeded: true,
      method: 'POST',
      data: {},
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const charge = async (_resource, params) => {
  try {
    const resp = await client(`users/charge`, {
      authNeeded: true,
      method: 'POST',
      data: params,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const paymentMethod = async (_resource, params) => {
  try {
    const resp = await client(`users/payment-method?userId=${params.userId}`, {
      authNeeded: true,
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getSessionTransactions = async (_resource, params) => {
  try {
    const fields = [];
    let { filter } = params;

    let url = constructUrl('transactions', {
      fields,
      filters: filter,
      limit: 25,
      offset: 0,
    });

    const resp = await client(url, {
      authNeeded: true,
    });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const queries = {
  getSessions,
  syncZenotiSessions,
  charge,
  paymentMethod,
  getSessionTransactions,
};
export default queries;
