export const BACKEND_URI = process.env.REACT_APP_GRAPHQL_URL;
export const FRONTEND_URI = process.env.REACT_APP_FRONTEND_URL;
export const S3_URI = process.env.REACT_APP_S3_URL;
export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

export const TransactionTypes = [
  { id: 1, name: 'Sessions' },
  { id: 2, name: 'Membership' },
  { id: 3, name: 'Gifts' },
];

export const sessions = [
  { id: 1, name: 'Upcoming' },
  { id: 2, name: 'Completed' },
  { id: 3, name: 'Cancelled' },
];

export const roles = [
  { id: 'admin', name: 'Admin' },
  { id: 'director', name: 'Director' },
  { id: 'guest-experience', name: 'Guest Experience' },
  { id: 'therapist', name: 'Therapist' },
  { id: 'supervisor', name: 'Supervisor' },
];

export const teamsFilters = [
  { id: 1, name: 'Active', isActive: true },
  { id: 2, name: 'InActive', isActive: false },
];

export const allPaymentTypes = [
  { id: 1, name: 'Revenue', value: 'revenue' },
  { id: 2, name: 'Self Pay', value: 'self-pay' },
  { id: 3, name: 'In Network', value: 'in-network' },
  { id: 4, name: 'In Network Open', value: 'in-network-open' },
];

export const gssPaymentTypes = [
  { id: 1, name: 'Self Pay', value: 'self-pay' },
  { id: 2, name: 'In Network', value: 'in-network' },
  { id: 3, name: 'In Network Open', value: 'in-network-open' },
];

export const restrictions = [
  {
    id: 'isForFirstTimeGuestsOnly',
    name: 'First Time Guests Only',
  },
  { id: 'isOneTimePerGuest', name: 'One Time Per Guest' },
];

export const products = [
  {
    id: 'isApplicableOnSession',
    name: 'Applicable On Session',
  },
  {
    id: 'isApplicableOnMembership',
    name: 'Applicable On Membership',
  },
  { id: 'isApplicableOnGift', name: 'Applicable On Gift' },
];

export const IdentifiedGender = [
  { id: 1, name: 'Man', value: 'Man' },
  { id: 2, name: 'Woman', value: 'Woman' },
  { id: 3, name: 'Trans Man', value: 'Trans Man' },
  { id: 4, name: 'Trans Woman', value: 'Trans Woman' },
  {
    id: 5,
    name: 'Genderqueer / Gender Non-Conforming / Non-Binary / Agender',
    value: 'Genderqueer / Gender Non-Conforming / Non-Binary / Agender',
  },
  { id: 6, name: 'Questioning', value: 'Questioning' },
  { id: 7, name: 'Prefer Not to Say', value: 'Prefer Not to Say' },
  { id: 8, name: 'Prefer to Self Describe', value: 'Prefer to Self Describe' },
];

export const GenderPronouns = [
  { id: 1, name: 'She / Her / Hers', value: 'She / Her / Hers' },
  { id: 2, name: 'He / Him / His', value: 'He / Him / His' },
  { id: 3, name: 'Just my name', value: 'Just my name' },
  { id: 4, name: 'They / Them / Theirs', value: 'They / Them / Theirs' },
  { id: 5, name: 'Ze / Hir', value: 'Ze / Hir' },
  { id: 6, name: 'Other', value: 'Other' },
];

export const GuestIntakeRelationships = [
  'Spouse',
  'Partner',
  'Parent',
  'Guardian',
  'Sibling',
  'Friend',
].map(o => ({ id: o, name: o }));

export const phoneValidationRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const ReportLabels = {
  sessionCreditSumAllUsers: 'Total Session Credits',
  shopCreditSumAllUsers: 'Total Shop Credits',
  unredeemedGiftCodeValueAllUsers: 'Unredeemed Gift Code Value',
  unredeemedGiftCodeCCChargedPriceAllUsers:
    'Unredeemed Gift Code Amount Charged',
  redeemedGiftCodeValueAllUsers: 'Redeemed Gift Code Value',
  redeemedGiftCodeCCChargedPriceAllUsers: 'Redeemed Gift Code Amount Charged',
  promoDiscountOnAllMemberships: 'Promo Revenue - Memberships',
  promoDiscountOnAllCompletedSessions: 'Promo Revenue - Sessions',
};

export const PaymentMethods = {
  1: 'Aetna',
  2: 'Cigna',
  3: 'Self Pay',
};

export const SelectedPaymentTypes = {
  revenue: 'revenue',
  selfPay: 'self-pay',
  inNetwork: 'in-network',
};
