import React, { useEffect, useState } from 'react';
import {
  Edit,
  Tab,
  Show,
  SimpleForm,
  TabbedShowLayout,
  TextInput,
  TopToolbar,
  useNotify,
  useRefresh,
  Loading,
  SaveButton,
  SelectInput,
  Toolbar,
  required,
  regex,
  useQuery,
} from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';
import FaceIcon from '@material-ui/icons/Face';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import _, { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import { TherapistIntakeList } from '../therapist-intake/therapist-intake-list';
import { NoTitle } from '../components';
import { TreatmentPlanList } from '../treatment-plan/treatment-plan-list';
import { GuestIntake } from './guest-intake';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { customDataProvider } from '../data-provider';
import { Transactions } from '../transactions';
import { ChartNotesList } from '../chart-notes/chart-notes-list';
import { GuestNotesList } from '../guest-notes/guest-notes-list';
import { GuestDischarge } from '../discharge';
import './user-styles.css';
import { CreditsSlide, UpdateTherapist } from './user-slides';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  GenderPronouns,
  GuestIntakeRelationships,
  IdentifiedGender,
  phoneValidationRegex,
} from '../utils/constants';
import dayjs from 'dayjs';

export const UserEdit = ({ permissions, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [enableCancel, setEnableCancel] = useState(false);
  const [isOpenSessionSync, setIsOpenSessionSync] = useState(false);
  const [isOpenSessionCredit, setIsOpenSessionCredit] = useState(false);
  const [isOpenShopCredit, setIsOpenShopCredit] = useState(false);
  const [isGuestNonEditable, setIsGuestNonEditable] = useState(true);

  const [isOpenUpdateTherapist, setIsOpenUpdateTherapist] = useState(false);

  const [loading, setLoading] = useState(false);
  const requiredField = [required()];
  const [showEmergencyContact, setShowEmergencyContact] = React.useState(false);
  const { total: completedListTotal, loading: isSessionsLoading } = useQuery({
    type: 'getList',
    resource: 'sessions',
    payload: {
      pagination: { page: 1, perPage: 25 },
      id: props?.id,
      partnerId: props?.id,
      sort: { field: 'startDateTime', order: 'DESC' },
      filter: { status: 'completed' },
    },
  });

  const { total: upcomingListTotal } = useQuery({
    type: 'getList',
    resource: 'sessions',
    payload: {
      pagination: { page: 1, perPage: 25 },
      id: props?.id,
      partnerId: props?.id,
      sort: { field: 'startDateTime', order: 'ASC' },
      filter: { status: 'upcoming' },
    },
  });

  const { data: paymentMethodOptions } = useQuery({
    type: 'getList',
    resource: 'payment',
  });

  const handleMembershipCancel = () => {
    customDataProvider.update('membership', { id: props?.id }).then(
      () => {
        setEnableCancel(false);
        notify('membership canceled');
        refresh();
      },
      err => {
        notify(err.message);
      },
    );
  };

  const handleZenotiSyncSession = () => {
    setLoading(true);
    customDataProvider.create('zenotiSync', { id: props?.id }).then(
      () => {
        notify('sessions synced successfully');
        refresh();
        setIsOpenSessionSync(false);
        setLoading(false);
      },
      err => {
        notify(err.message);
        setLoading(false);
      },
    );
  };
  const [user, setUser] = useState();
  const [insuranceDetails, setInsuranceDetails] = useState();
  const [loggedinUser, setLoggedInUser] = useState();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setLoggedInUser(data);
  }, []);
  const [waiverLink, setWaiverLink] = useState();
  useEffect(() => {
    customDataProvider.getOne('guest', { id: props?.id }).then(res => {
      if (
        res?.data?.id !== user?.id ||
        user?.lastMembership?.status !== res?.data?.lastMembership?.status
      ) {
        const waiverBaseUrl = process.env.REACT_APP_PUBLIC_WAIVER_BASE_URL;
        setUser(res?.data);
        setWaiverLink(
          `${waiverBaseUrl}/waiver?waiverToken=${res?.data?.waiverToken}`,
        );
      }
    });
  }, [props?.id, user?.id, user?.lastMembership?.status]);

  useEffect(() => {
    customDataProvider
      .getOne('insuranceDetails', { userId: props?.id || user?.id })
      .then(res => {
        if (!isEmpty(res)) {
          console.log('insuranceDetails', res);
          setInsuranceDetails(res?.data);
        }
      });
  }, [props?.id, user?.id]);

  const [genderPronounsOther, setGenderPronounsOther] = useState(
    user?.newGuestIntake?.genderPronouns === 'Other',
  );

  const [identifiedGenderOther, setIdentifiedGenderOther] = useState(
    user?.newGuestIntake?.identifiedGender === 'Prefer to Self Describe',
  );

  useEffect(() => {
    setGenderPronounsOther(user?.newGuestIntake?.genderPronouns === 'Other');
    setIdentifiedGenderOther(
      user?.newGuestIntake?.identifiedGender === 'Prefer to Self Describe',
    );
  }, [user]);

  const { data: therapiesList } = useQuery({
    type: 'getList',
    resource: 'therapies',
    payload: { id: user?.id },
  });

  const handleCredits = values => {
    const payload = {
      id: props.id,
      sessionCredits:
        values?.sessionCredits <= 50 ? values?.sessionCredits : undefined,
      shopCredits:
        values?.shopCredits <= 50 ? values?.sessionCredits : undefined,
      firstName: values?.firstName || user?.firstName,
      lastName: values?.lastName || user?.lastName,
      email: values?.email || user?.lastName,
      phoneNumber: values?.phoneNumber || user?.phoneNumber,
      paymentMethod: values?.paymentMethod || user?.paymentMethod,
    };
    const guestIntake = _.pickBy(
      _.pick(values.newGuestIntake, [
        'identifiedGender',
        'identifiedGenderOther',
        'genderPronouns',
        'genderPronounsOther',
        'emergencyFirstName',
        'emergencyLastName',
        'emergencyPhoneNumber',
        'emergencyRelationship',
      ]),
    );
    const newGuestIntake = {
      ...guestIntake,
      userId: props.id,
      identifiedGenderOther:
        identifiedGenderOther && values?.newGuestIntake?.identifiedGenderOther
          ? values?.newGuestIntake?.identifiedGenderOther
          : undefined,
      genderPronounsOther:
        genderPronounsOther && values?.newGuestIntake?.genderPronounsOther
          ? values?.newGuestIntake?.genderPronounsOther
          : undefined,
    };
    if (!isEmpty(guestIntake)) {
      customDataProvider.update('newGuestIntake', newGuestIntake).then(
        () => {
          customDataProvider.update('guest', payload).then(
            () => {
              notify(
                `updated ${values?.firstName || user?.firstName} ${
                  values?.lastName || user?.lastName
                }`,
              );
              refresh();
              setIsGuestNonEditable(true);
              window.location.reload(false);
            },
            err => {
              notify(err.response.data.message, 'error');
            },
          );
        },
        err => {
          notify(err.response.data.message, 'error');
        },
      );
    } else {
      customDataProvider.update('guest', payload).then(
        () => {
          notify(
            `updated ${values?.firstName || user?.firstName} ${
              values?.lastName || user?.lastName
            }`,
          );
          refresh();
          setIsGuestNonEditable(true);
          window.location.reload(false);
        },
        err => {
          notify(err.response.data.message, 'error');
        },
      );
    }
  };

  const renderIcons = () => (
    <>
      {user?.lastPartner || user?.lastSession?.partnerId ? (
        <span className="icon-wrapper">
          <GroupIcon />
        </span>
      ) : null}
      {dayjs().diff(user?.dob, 'year') < 18 ? (
        <span className="icon-wrapper">
          <FaceIcon />
        </span>
      ) : null}
      {!isSessionsLoading && completedListTotal < 1 ? (
        <span className="icon-wrapper">
          <FiberNewIcon />
        </span>
      ) : null}
    </>
  );
  return (
    <>
      <Edit title="Edit Guest" {...props} mutationMode="pessimistic">
        <SimpleForm
          className="styled-form2"
          toolbar={
            !isGuestNonEditable ? (
              <Toolbar
                {...props}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <SaveButton
                  disabled={isGuestNonEditable}
                  onSave={handleCredits}
                />
                <Button
                  variant="outlined"
                  disabled={isGuestNonEditable}
                  onClick={() => {
                    setIsGuestNonEditable(true);
                    refresh();
                  }}
                >
                  Cancel
                </Button>
              </Toolbar>
            ) : null
          }
        >
          <div className="grid-2">
            <div className="sub-headers-with-icon">
              <h3 className="sub-headers">Basic Details</h3>
              <span>{renderIcons()}</span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns:
                  user?.guardian && !user?.waiverSigned
                    ? '1fr 1fr 1fr'
                    : '1fr 1fr',
                gridGap: 10,
                marginBottom: 10,
                marginTop: 20,
              }}
            >
              {isGuestNonEditable ? (
                <Button
                  variant="containedPrimary"
                  onClick={() => {
                    setIsOpenSessionSync(true);
                  }}
                >
                  Sync Sessions ({'>'}7 Days Out)
                </Button>
              ) : null}
              {isGuestNonEditable ? (
                <Button
                  variant="containedPrimary"
                  onClick={() => {
                    setIsGuestNonEditable(false);
                  }}
                >
                  Edit Guest
                </Button>
              ) : null}
              {isGuestNonEditable && user?.guardian && !user?.waiverSigned && (
                <CopyToClipboard text={waiverLink}>
                  <Button variant="containedPrimary">Copy Waiver Link</Button>
                </CopyToClipboard>
              )}
              {isGuestNonEditable &&
              loggedinUser?.role !== 'therapist' &&
              loggedinUser?.role !== 'supervisor' &&
              upcomingListTotal === 1 &&
              !isEmpty(therapiesList) &&
              !isSessionsLoading &&
              completedListTotal < 1 ? (
                <Button
                  variant="containedPrimary"
                  onClick={() => {
                    setIsOpenUpdateTherapist(true);
                  }}
                >
                  UPDATE THERAPIST
                </Button>
              ) : null}
            </div>
          </div>
          <div className={isGuestNonEditable ? 'custom-table-view' : 'grid-3'}>
            <TextInput
              source="firstName"
              disabled={isGuestNonEditable}
              validate={requiredField}
            />
            <TextInput
              source="lastName"
              disabled={isGuestNonEditable}
              validate={requiredField}
            />
            <TextInput
              source="firstName"
              label="Goes By"
              disabled={isGuestNonEditable}
            />
            <SelectInput
              source="newGuestIntake.identifiedGender"
              choices={IdentifiedGender}
              label="Gender"
              style={{ width: '100%' }}
              optionValue="value"
              disabled={isGuestNonEditable}
              onChange={value =>
                setIdentifiedGenderOther(
                  value.target.value === 'Prefer to Self Describe',
                )
              }
            />
            {identifiedGenderOther && (
              <TextInput
                label="Gender"
                source="newGuestIntake.identifiedGenderOther"
                disabled={isGuestNonEditable}
              />
            )}
            <SelectInput
              source="newGuestIntake.genderPronouns"
              choices={GenderPronouns}
              disabled={isGuestNonEditable}
              label="Pronouns"
              style={{ width: '100%' }}
              optionValue="value"
              onChange={e => {
                setGenderPronounsOther(e.target.value === 'Other');
              }}
            />
            {genderPronounsOther && (
              <TextInput
                label="Pronouns"
                source="newGuestIntake.genderPronounsOther"
                disabled={isGuestNonEditable}
              />
            )}
            {isGuestNonEditable && (
              <>
                <TextInput
                  source="dob"
                  label="Date of Birth"
                  disabled={true}
                  format={formValue =>
                    `${new Date(formValue).getUTCMonth() + 1}/${new Date(
                      formValue,
                    ).getUTCDate()}/${new Date(formValue).getUTCFullYear()}`
                  }
                />
                <TextInput
                  source="email"
                  disabled={true}
                  multiline={isGuestNonEditable}
                />
              </>
            )}
            <TextInput
              source="phoneNumber"
              disabled={isGuestNonEditable}
              format={formValue => formValue && formValue.split('+1')[1]}
            />
            {paymentMethodOptions && (
              <SelectInput
                source="paymentMethod"
                choices={paymentMethodOptions}
                label="Payment Method"
                style={{ width: '100%' }}
                optionValue="id"
                disabled={isGuestNonEditable}
              />
            )}
            {isGuestNonEditable && (
              <>
                <TextInput
                  source="newGuestIntake"
                  label="Address"
                  multiline
                  disabled={true}
                  format={formValue =>
                    formValue?.address
                      ? `${formValue?.address}, ${formValue?.city}, ${formValue?.state}, ${formValue?.zip}`
                      : ''
                  }
                />
              </>
            )}
          </div>
          <div className="more-info">
            <div className="accordian-head">
              <h2
                role="button"
                className="sub-headers"
                onClick={() => {
                  setShowEmergencyContact(state => !state);
                }}
              >
                View Emergency Contact Details
              </h2>
              <div
                className={showEmergencyContact ? 'arrow down' : 'arrow up'}
              />
            </div>
            {showEmergencyContact ? (
              <div
                className={
                  isGuestNonEditable
                    ? 'custom-table-view emergency-info'
                    : 'grid-3 emergency-info'
                }
              >
                <TextInput
                  label="First Name"
                  source="newGuestIntake.emergencyFirstName"
                  disabled={isGuestNonEditable}
                  // format={formValue =>
                  //   formValue && JSON.stringify(formValue?.emergencyFirstName)
                  // }
                />
                <TextInput
                  label="Last Name"
                  source="newGuestIntake.emergencyLastName"
                  disabled={isGuestNonEditable}
                  // format={formValue => formValue && formValue?.emergencyLastName}
                />
                <SelectInput
                  source="newGuestIntake.emergencyRelationship"
                  label="Relationship"
                  disabled={isGuestNonEditable}
                  choices={GuestIntakeRelationships}
                  // format={formValue =>
                  //   formValue && formValue?.emergencyRelationship
                  // }
                />
                <TextInput
                  source="newGuestIntake.emergencyPhoneNumber"
                  label="Phone"
                  format={formValue => formValue && formValue.split('+1')[1]}
                  validate={[
                    ...requiredField,
                    regex(phoneValidationRegex, 'Invalid Phone'),
                  ]}
                  disabled={isGuestNonEditable}
                />
              </div>
            ) : null}
          </div>
          <div className="grid-2 gap-40">
            {loggedinUser?.role !== 'therapist' &&
              loggedinUser?.role !== 'supervisor' &&
              isGuestNonEditable && (
                <div>
                  <h3 className="sub-headers">Credit Details:</h3>
                  <div className="grid-2">
                    {/* <div style={{ position: 'relative' }}>
                      <TextInput
                        style={{ width: '100%' }}
                        source="sessionCredits"
                        label="Session Credits"
                        disabled={true}
                        className="editable-input"
                      />
                      <Button
                        style={{
                          position: 'absolute',
                          bottom: 33,
                          right: 10,
                        }}
                        variant="containedPrimary"
                        color="primary"
                        onClick={() => {
                          setIsOpenSessionCredit(true);
                        }}
                      >
                        +/-
                      </Button>
                    </div> */}
                    <div style={{ position: 'relative' }}>
                      <TextInput
                        style={{ width: '100%' }}
                        source="shopCredits"
                        label="Shop Credits"
                        disabled={true}
                        className="editable-input"
                      />
                      <Button
                        style={{
                          position: 'absolute',
                          bottom: 33,
                          right: 10,
                        }}
                        variant="containedPrimary"
                        color="primary"
                        onClick={() => {
                          setIsOpenShopCredit(true);
                        }}
                      >
                        +/-
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            {isGuestNonEditable && insuranceDetails?.provider && (
              <div>
                <h3 className="sub-headers">Insurance Details:</h3>
                <div className="grid-2">
                  <div className="">
                    <div style={{ position: 'relative' }}></div>
                    <TextInput
                      label="Carrier"
                      source="insurance.provider"
                      disabled={true}
                    />
                  </div>
                  <div className="">
                    <div style={{ position: 'relative' }}></div>
                    <TextInput
                      label="Member ID"
                      source="insurance.memberId"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {isGuestNonEditable && user?.lastMembership ? (
              <div>
                <h3 className="sub-headers">Membership Details:</h3>
                <div className="grid-2">
                  <div style={{ position: 'relative' }}>
                    <TextInput
                      source="lastMembership.status"
                      label="Status"
                      format={formValue => {
                        if (!formValue || !user?.lastMembership) {
                          return null;
                        }
                        if (formValue === 'canceled') {
                          if (user?.lastMembership?.isExpired) {
                            return `Canceled on ${dayjs(
                              user?.lastMembership?.cancelDateTime,
                            ).format('MM/DD/YY')}`;
                          } else {
                            return `Canceling on ${dayjs(
                              user?.lastMembership?.endDateTime,
                            ).format('MM/DD/YY')}`;
                          }
                        }
                        return formValue === 'active' ||
                          formValue === 'reactivated'
                          ? 'Active'
                          : 'Inactive';
                      }}
                      disabled={true}
                    />
                    {loggedinUser?.role !== 'therapist' &&
                    loggedinUser?.role !== 'supervisor' &&
                    user?.lastMembership &&
                    (user?.lastMembership?.status === 'active' ||
                      user?.lastMembership?.status === 'reactivated') ? (
                      <Button
                        style={{
                          position: 'absolute',
                          bottom: 33,
                          right: '40%',
                        }}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          setEnableCancel(true);
                        }}
                      >
                        cancel
                      </Button>
                    ) : null}
                  </div>
                  {user?.lastMembership?.status === 'active' ||
                  user?.lastMembership?.status === 'reactivated' ? (
                    <DateInput
                      source="lastMembership.endDateTime"
                      label="Membership Renewal Date:"
                      disabled={true}
                    />
                  ) : null}
                </div>
              </div>
            ) : null} */}
          </div>
        </SimpleForm>
      </Edit>
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={enableCancel}
            anchor="right"
            onClose={() => {
              setEnableCancel(false);
            }}
          >
            <div style={{ width: 400, padding: 20 }}>
              <h2>Cancel Membership</h2>
              <div style={{ marginBottom: 20 }}>
                Are you sure you want to cancel this guest’s membership? Their
                membership will end at their renewal date & they will not be
                charged again
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <Button
                  variant="outlinedPrimary"
                  onClick={() => {
                    setEnableCancel(false);
                  }}
                >
                  Leave It
                </Button>
                <Button
                  variant="containedPrimary"
                  onClick={handleMembershipCancel}
                >
                  Cancel Membership
                </Button>
              </div>
            </div>
          </Drawer>
        )}
      />
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer
            open={isOpenSessionSync}
            anchor="right"
            onClose={() => {
              setIsOpenSessionSync(false);
            }}
          >
            <div style={{ width: 400, padding: 20 }}>
              {loading && <Loading />}
              <h2>Sync Zenoti Sessions</h2>
              <div style={{ marginBottom: 20 }}>
                The newly created sessions and/or modified existing sessions
                from zenoti will be synced with selected user
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 10,
                }}
              >
                <Button
                  variant="outlinedPrimary"
                  onClick={() => {
                    setIsOpenSessionSync(false);
                  }}
                >
                  Leave It
                </Button>
                <Button
                  variant="containedPrimary"
                  onClick={handleZenotiSyncSession}
                >
                  Sync Zenoti Sessions
                </Button>
              </div>
            </div>
          </Drawer>
        )}
      />
      {isOpenSessionCredit && (
        <CreditsSlide
          {...props}
          isOpenCredits={isOpenSessionCredit}
          setIsOpenCredits={setIsOpenSessionCredit}
          title="Session Credits"
          source="sessionCredits"
          user={user}
          objectKey="sessionCredits"
        />
      )}
      {isOpenShopCredit && (
        <CreditsSlide
          {...props}
          isOpenCredits={isOpenShopCredit}
          setIsOpenCredits={setIsOpenShopCredit}
          title="Shop Credits"
          source="shopCredits"
          user={user}
          objectKey="shopCredits"
        />
      )}
      {isOpenUpdateTherapist && (
        <UpdateTherapist
          {...props}
          isOpenUpdateTherapist={isOpenUpdateTherapist}
          setIsOpenUpdateTherapist={setIsOpenUpdateTherapist}
        />
      )}
      {isGuestNonEditable ? (
        <Show title={<NoTitle />} {...props} actions={<TopToolbar />}>
          {permissions === 'admin' || permissions === 'director' ? (
            <TabbedShowLayout>
              {/* {(permissions === 'therapist' ||
                permissions === 'supervisor') && (
                <Tab label="Sessions">
                  <Transactions {...props} />
                </Tab>
              )} */}
              <Tab label="Guest Intake">
                <GuestIntake {...props} />
              </Tab>
              <Tab label="Therapist Intake" path="therapist-intake">
                <TherapistIntakeList {...props} />
              </Tab>
              {permissions !== 'therapist' && permissions !== 'supervisor' && (
                <Tab label="Guest Discharge" path="guest-discharge">
                  <GuestDischarge {...props} />
                </Tab>
              )}
              {permissions !== 'therapist' && permissions !== 'supervisor' && (
                <Tab label="Transactions" path="transactions">
                  <Transactions {...props} />
                </Tab>
              )}
              <Tab label="Treatment Plan" path="treatment-plans">
                <TreatmentPlanList {...props} />
              </Tab>
              <Tab label="Chart Notes" path="chart-notes">
                <ChartNotesList {...props} />
              </Tab>
              <Tab label="Message Guest" path="guest-notes">
                <GuestNotesList {...props} />
              </Tab>
            </TabbedShowLayout>
          ) : permissions === 'therapist' || permissions === 'supervisor' ? (
            <TabbedShowLayout>
              <Tab label="Sessions">
                <Transactions {...props} />
              </Tab>
              <Tab label="Chart Notes" path="chart-notes">
                <ChartNotesList {...props} />
              </Tab>
              <Tab label="Message Guest" path="guest-notes">
                <GuestNotesList {...props} />
              </Tab>
              <Tab label="Treatment Plan" path="treatment-plans">
                <TreatmentPlanList {...props} />
              </Tab>
              <Tab label="Guest Intake">
                <GuestIntake {...props} />
              </Tab>
              <Tab label="Therapist Intake" path="therapist-intake">
                <TherapistIntakeList {...props} />
              </Tab>
              <Tab label="Guest Discharge" path="guest-discharge">
                <GuestDischarge {...props} />
              </Tab>
            </TabbedShowLayout>
          ) : (
            <Transactions {...props} />
          )}
        </Show>
      ) : null}
    </>
  );
};
