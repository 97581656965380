import React from 'react';
import {
  EditContextProvider,
  SimpleForm,
  useEditController,
  useNotify,
  useQuery,
} from 'react-admin';
import { customDataProvider } from '../data-provider';
import { TherapistIntakeForm } from './therapist-intake-form';
import { ActionButtons } from '../components';

export const TherapistIntakeEdit = ({ ...props }) => {
  const notify = useNotify();
  const controllerProps = useEditController(props);
  const { data, loading: isTherapistIntakeLoading } = useQuery({
    type: 'getOne',
    resource: 'therapists-intake',
    payload: { id: JSON.stringify(props?.rowId) },
  });

  const { data: userPartners, loading: isUserPartnerLoading } = useQuery({
    type: 'getList',
    resource: 'userPartners',
    payload: { id: props.id },
  });

  const handleSave = values => {
    const payload = {
      ...values,
      partnerAffect: values?.partnerAffect || undefined,
      partnerAppearance: values?.partnerAppearance || undefined,
      partnerCognitiveFunctions: values?.partnerCognitiveFunctions || undefined,
      partnerEngagement: values?.partnerEngagement || undefined,
      partnerEyeContact: values?.partnerEyeContact || undefined,
      partnerHygiene: values?.partnerHygiene || undefined,
      partnerId: values?.partnerId || undefined,
      partnerMood: values?.partnerMood || undefined,
      partnerSpeech: values?.partnerSpeech || undefined,
      partnerThoughtDisorder: values?.partnerThoughtDisorder || undefined,
    };
    updateTherapistIntake(payload);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const updateTherapistIntake = payload =>
    customDataProvider.update('therapists-intake', payload).then(
      () => {
        handleCancel();
        notify('updated successfully');
      },
      err => {
        notify(err.message);
      },
    );

  if (isTherapistIntakeLoading || isUserPartnerLoading) {
    return null;
  }

  return (
    <div className="intake-width">
      <EditContextProvider value={controllerProps} style={{ width: '100%' }}>
        <SimpleForm
          save={handleSave}
          initialValues={data}
          toolbar={
            <ActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          }
        >
          <div className="intake-form">
            <TherapistIntakeForm
              userPartners={userPartners}
              userId={props.id}
              isEdit={true}
            />
          </div>
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};
