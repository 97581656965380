// in src/customRoutes.js
import * as React from 'react';
import { Route } from 'react-router-dom';
import { GiftCodesCreate } from './gift/gift-codes-create';
import { Sessions } from './transactions/sessions';
import { ResetPassword } from './reset-password/index';
import { RouteWithoutLayout } from 'react-admin';

const CustomRoutes = [
  <Route exact path="/session" component={Sessions} />,
  <Route exact path="/membership" component={Sessions} />,
  <Route exact path="/gifts" component={Sessions} />,
  <Route exact path="/gift-codes/create" component={GiftCodesCreate} />,
  <RouteWithoutLayout exact path="/reset-password" component={ResetPassword} />,
];

export default CustomRoutes;
