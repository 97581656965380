import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getUserList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;

    if (filter.email) {
      filter.role = 'user';
      filter.hasRegistered = 'true';
      filter.condition = 'or';
      filter['email.lk'] = encodeURIComponent(filter.email);
      filter['firstName.lk'] = filter.email;
      filter['lastName.lk'] = filter.email;
      filter['phoneNumber.lk'] = filter.email;

      delete filter.email;

      const url = constructUrl('users', {
        filters: filter,
        limit: pagination?.perPage,
        offset: pagination?.perPage * (pagination?.page - 1),
      });

      const resp = await client(url, { authNeeded: true });
      return {
        data: resp.items,
        total: resp.count,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUser = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id, ...params.filter };
    const fields = [
      'relationships.*',
      'newGuestIntake.*',
      'partnerIntake.*',
      'lastMembership.*',
      'lastPartner.*',
      'guardian.*',
      'specialties.*',
      'lastSession.reasons.*',
      'insurance.*',
    ];

    const url = constructUrl('users', { fields, filters });
    const resp = await client(url, { authNeeded: true });
    localStorage.setItem(
      'guest',
      JSON.stringify({
        ...resp.items[0],
        shopCredits: resp.items[0].shopCredits / 100,
      }),
    );
    return {
      data: {
        ...resp.items[0],
        shopCredits: resp.items[0].shopCredits / 100,
        // dob: dayjs(resp.items[0].dob).format('MM/DD/YYYY'),
      },
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateUser = async (_resource, params) => {
  const {
    id: userId,
    sessionCredits,
    shopCredits,
    status,
    firstName,
    lastName,
    email,
    phoneNumber,
    paymentMethod,
  } = params;
  try {
    const resp = await client('users', {
      authNeeded: true,
      method: 'PUT',
      data: {
        userId,
        sessionCredits: sessionCredits ? sessionCredits : undefined,
        shopCredits: shopCredits ? Math.round(shopCredits * 100) : undefined,
        status,
        firstName,
        lastName,
        email,
        phoneNumber,
        paymentMethod,
      },
    });
    localStorage.setItem(
      'guest',
      JSON.stringify({
        ...resp,
        shopCredits: resp.shopCredits / 100,
      }),
    );
    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateNewGuestIntake = async (_resource, params) => {
  try {
    const resp = await client('users/new-guest-intake', {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });
    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const forgotPassword = async (_resource, params) => {
  const { email } = params;
  try {
    const resp = await client(
      `users/forgot-password?email=${encodeURIComponent(
        email,
      )}&isAdminApp=true`,
      {
        // authNeeded: true,
        method: 'GET',
        // data: params,
      },
    );
    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const resetPassword = async (_resource, params) => {
  try {
    const resp = await client('users/reset-password', {
      // authNeeded: true,
      method: 'POST',
      data: params,
    });
    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserPartnerList = async (_resource, params) => {
  try {
    const fields = ['partner.*'];
    const filters = { userId: params?.id };

    const url = constructUrl('users/partners', { fields, filters });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getUserPartnerIntake = async (_resource, params) => {
  try {
    const fields = [];
    const filters = { userId: params?.id };

    const url = constructUrl('users/partner-intake', { fields, filters });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getList = async (_resource, params) => {
  try {
    const resp = await client('users?filters=role=user', { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTherapistList = async (_resource, params) => {
  try {
    const resp = await client('therapists', { authNeeded: false });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateUserTherapist = async (_resource, params) => {
  const { therapistId, userId } = params;
  try {
    const resp = await client(
      `users/${userId}/change-therapist/${therapistId}`,
      {
        authNeeded: true,
        method: 'PUT',
        data: {},
      },
    );
    return { data: resp };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getSpecialities = async (_resource, params) => {
  try {
    const fields = [];
    const filters = {};
    const url = constructUrl('specialties', { fields, filters });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getReasons = async (_resource, params) => {
  try {
    const fields = [];
    const filters = {};
    const url = constructUrl('reasons', { fields, filters });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPaymentMethodOptions = async (_resource, params) => {
  try {
    const resp = await client('session-payment-methods', { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getInsuranceDetails = async (_resource, params) => {
  try {
    const filters = { ...params };
    const url = constructUrl('users/insurance', { filters });
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp?.items[0],
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserList,
  getUser,
  updateUser,
  getUserPartnerList,
  getUserPartnerIntake,
  getList,
  getSpecialities,
  updateNewGuestIntake,
  getReasons,
  forgotPassword,
  resetPassword,
  getTherapistList,
  updateUserTherapist,
  getPaymentMethodOptions,
  getInsuranceDetails,
};
