import UserQueries from './user/user-queries';
import TherapistsQueries from './therapist-intake/therapist-intake-queries';
import MembershipQueries from './transactions/membership/membership-queries';
import GiftsQueries from './transactions/gifts/gifts-queries';
import TherapiesQueries from './discharge/discharge-queries';
import ChartNotesQueries from './chart-notes/chart-notes-queries';
import GuestNotesQueries from './guest-notes/guest-notes-queries';
import SessionQueries from './transactions/sessions/session-queries';
import TreatmentPlanQueries from './treatment-plan/treatment-plan-queries';
import RefundQueries from './transactions/refund-queries';
import TeamMemberQueries from './teams/team-member-queries';
import PromoQueries from './promo/promo-queries';
import AllGiftQueries from './gift/gift-queries';
import ReportQueries from './reports/report-queries';
import PaymentQueries from './payments/payment-queries';

export const customDataProvider = {
  getList: (resource, params) => {
    if (resource === 'guest') {
      return UserQueries.getUserList(resource, params);
    }
    if (resource === 'specialities') {
      return UserQueries.getSpecialities(resource, params);
    }
    if (resource === 'reasons') {
      return UserQueries.getReasons(resource, params);
    }
    if (resource === 'users') {
      return UserQueries.getList(resource, params);
    }
    if (resource === 'therapists-intake') {
      return TherapistsQueries.getTherapistIntakeList(resource, params);
    }
    if (resource === 'diagnosis') {
      return TherapistsQueries.getDiagnosis(resource, params);
    }
    if (resource === 'sessions') {
      return SessionQueries.getSessions(resource, params);
    }
    if (resource === 'membership') {
      return MembershipQueries.getMemberships(resource, params);
    }
    if (resource === 'gifts') {
      return GiftsQueries.getGifts(resource, params);
    }
    if (resource === 'therapies') {
      return TherapiesQueries.getTherapies(resource, params);
    }
    if (resource === 'chartNotes') {
      return ChartNotesQueries.getChartNotesList(resource, params);
    }
    if (resource === 'guestNotes') {
      return GuestNotesQueries.getGuestNotesList(resource, params);
    }
    if (resource === 'userTherapists') {
      return TherapistsQueries.getUserTherapistList(resource, params);
    }
    if (resource === 'userPartners') {
      return UserQueries.getUserPartnerList(resource, params);
    }
    if (resource === 'userPartnersIntake') {
      return UserQueries.getUserPartnerIntake(resource, params);
    }
    if (resource === 'treatmentPlan') {
      return TreatmentPlanQueries.getTreatmentPlanList(resource, params);
    }
    if (resource === 'diagnosis') {
      return TreatmentPlanQueries.getDiagnosisList(resource, params);
    }
    if (resource === 'refund') {
      return RefundQueries.getRefundReasons(resource, params);
    }
    if (resource === 'team') {
      return TeamMemberQueries.getTeamList(resource, params);
    }
    if (resource === 'locations') {
      return TeamMemberQueries.getLocations(resource, params);
    }
    if (resource === 'promo') {
      return PromoQueries.getPromoList(resource, params);
    }
    if (resource === 'allGuest') {
      return TreatmentPlanQueries.getAllUserList(resource, params);
    }
    if (resource === 'gift') {
      return AllGiftQueries.getGiftList(resource, params);
    }
    if (resource === 'giftTypes') {
      return AllGiftQueries.fetchGiftTypes(resource, params);
    }
    if (resource === 'giftCodes') {
      return AllGiftQueries.fetchUnusedGiftCodes(resource, params);
    }
    if (resource === 'therapistList') {
      return UserQueries.getTherapistList(resource, params);
    }
    if (resource === 'report') {
      return ReportQueries.getReportList(resource, params);
    }
    if (resource === 'downlaodableReport') {
      return ReportQueries.getDownloadableReportList(resource, params);
    }
    if (resource === 'payment') {
      return UserQueries.getPaymentMethodOptions(resource, params);
    }
    if (resource === 'sessionTransactions') {
      return SessionQueries.getSessionTransactions(resource, params);
    }
  },
  getOne: (resource, params) => {
    if (resource === 'guest') {
      return UserQueries.getUser(resource, params);
    }
    if (resource === 'therapists-intake') {
      return TherapistsQueries.getTherapistIntake(resource, params);
    }
    if (resource === 'chartNotes') {
      return ChartNotesQueries.getChartNote(resource, params);
    }
    if (resource === 'guestNotes') {
      return GuestNotesQueries.getGuestNotes(resource, params);
    }
    if (resource === 'treatmentPlan') {
      return TreatmentPlanQueries.getTreatmentPlan(resource, params);
    }
    if (resource === 'team') {
      return TeamMemberQueries.getTeamMember(resource, params);
    }
    if (resource === 'promo') {
      return PromoQueries.getPromo(resource, params);
    }
    if (resource === 'imageUpload') {
      return TeamMemberQueries.uploadImage(resource, params);
    }
    if (resource === 'thumbnailUpload') {
      return TeamMemberQueries.uploadThumbnail(resource, params);
    }
    if (resource === 'videoUpload') {
      return TeamMemberQueries.uploadVideo(resource, params);
    }
    if (resource === 'forgotPassword') {
      return UserQueries.forgotPassword(resource, params);
    }
    if (resource === 'gift') {
      return AllGiftQueries.getGiftDetail(resource, params);
    }
    if (resource === 'paymentMethod') {
      return SessionQueries.paymentMethod(resource, params);
    }
    if (resource === 'insuranceDetails') {
      return UserQueries.getInsuranceDetails(resource, params);
    }
  },
  update: (resource, params) => {
    if (resource === 'guest') {
      return UserQueries.updateUser(resource, params);
    }
    if (resource === 'newGuestIntake') {
      return UserQueries.updateNewGuestIntake(resource, params);
    }
    if (resource === 'therapists-intake') {
      return TherapistsQueries.updateTherapistIntake(resource, params);
    }
    if (resource === 'membership') {
      return MembershipQueries.updateMembership(resource, params);
    }
    if (resource === 'therapies') {
      return TherapiesQueries.updateDischargeGuest(resource, params);
    }
    if (resource === 'chartNotes') {
      return ChartNotesQueries.updateChartNote(resource, params);
    }
    if (resource === 'guestNotes') {
      return GuestNotesQueries.updateGuestNotes(resource, params);
    }
    if (resource === 'treatmentPlan') {
      return TreatmentPlanQueries.updateTreatmentPlan(resource, params);
    }
    if (resource === 'refund') {
      return RefundQueries.refund(resource, params);
    }
    if (resource === 'charge') {
      return SessionQueries.charge(resource, params);
    }
    if (resource === 'team') {
      return TeamMemberQueries.updateTeam(resource, params);
    }
    if (resource === 'activateUser') {
      return TeamMemberQueries.activateTeamMember(resource, params);
    }
    if (resource === 'promo') {
      return PromoQueries.updatePromo(resource, params);
    }
    if (resource === 'updateImage') {
      return TeamMemberQueries.updateImage(resource, params);
    }
    if (resource === 'updateThumbnail') {
      return TeamMemberQueries.updateThumbnail(resource, params);
    }
    if (resource === 'updateVideo') {
      return TeamMemberQueries.updateVideo(resource, params);
    }
    if (resource === 'gift') {
      return AllGiftQueries.updateGift(resource, params);
    }
    if (resource === 'updateUserTherapist') {
      return UserQueries.updateUserTherapist(resource, params);
    }
  },
  create: (resource, params) => {
    if (resource === 'guest') {
      return UserQueries.createUser(resource, params);
    }
    if (resource === 'therapists-intake') {
      return TherapistsQueries.createTherapistIntake(resource, params);
    }
    if (resource === 'chartNotes') {
      return ChartNotesQueries.createChartNote(resource, params);
    }
    if (resource === 'guestNotes') {
      return GuestNotesQueries.createGuestNotes(resource, params);
    }
    if (resource === 'treatmentPlan') {
      return TreatmentPlanQueries.createTreatmentPlan(resource, params);
    }
    if (resource === 'team') {
      return TeamMemberQueries.createTeam(resource, params);
    }
    if (resource === 'promo') {
      return PromoQueries.createPromo(resource, params);
    }
    if (resource === 'zenotiSync') {
      return SessionQueries.syncZenotiSessions(resource, params);
    }
    if (resource === 'gift') {
      return AllGiftQueries.purchaseGift(resource, params);
    }
    if (resource === 'giftCodes') {
      return AllGiftQueries.createGiftCodes(resource, params);
    }
    if (resource === 'resetPassword') {
      return UserQueries.resetPassword(resource, params);
    }
    if (resource === 'createSetupIntent') {
      return PaymentQueries.createSetupIntent(resource, params);
    }
    if (resource === 'createPaymentMethod') {
      return PaymentQueries.createPaymentMethod(resource, params);
    }
  },
  delete: (resource, params) => {
    if (resource === 'gifts') {
      return GiftsQueries.cancelGiftCard(resource, params);
    }
    if (resource === 'guestNotes') {
      return GuestNotesQueries.deleteGuestNote(resource, params);
    }
    if (resource === 'treatmentPlan') {
      return TreatmentPlanQueries.createTreatmentPlan(resource, params);
    }
    if (resource === 'team') {
      return TeamMemberQueries.deactivateTeamMember(resource, params);
    }
    if (resource === 'promo') {
      return PromoQueries.deletePromo(resource, params);
    }
  },
};
