import { keyBy } from 'lodash';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  ListContextProvider,
  Loading,
  useQuery,
  useRefresh,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { TherapistIntakeEdit } from './therapist-intake-edit';
import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { TherapistIntakeCreate } from './therapist-intake-create';
import { TherapistFullNameField } from '../components';

export const TherapistIntakeList = ({ ...props }) => {
  const refresh = useRefresh();
  const { data, loading } = useQuery({
    type: 'getList',
    resource: 'therapists-intake',
    payload: { id: props.id },
  });
  const [rowId, setRowId] = useState(0);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
    refresh();
  };

  const handleCloseCreate = () => {
    setIsOpenCreate(false);
    refresh();
  };

  return (
    <ListContextProvider
      value={{
        resource: 'guest',
        basePath: `/guest/${props.id}`,
        data: keyBy(data, 'id'),
        ids: data && data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
        perPage: 25,
      }}
      className="main-table"
    >
      <Button
        variant="containedPrimary"
        onClick={() => {
          setIsOpenCreate(true);
        }}
      >
        Create Intake
      </Button>

      <Datagrid
        rowClick={e => {
          setRowId(e);
          setIsOpenEdit(true);
        }}
      >
        <DateField
          record={data && data[0]}
          source="updatedAt"
          label="Created/Updated Date/Time"
          showTime
        />
        <TherapistFullNameField record={data && data[0]} label="Therapist" />
      </Datagrid>
      {loading && <Loading className="plain-loading" />}
      <div style={{ height: 50 }}></div>
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer open={isOpenEdit} anchor="right">
            <TherapistIntakeEdit
              {...props}
              handleClose={handleCloseEdit}
              rowId={rowId}
            />
          </Drawer>
        )}
      />
      <Route
        path={`/guest/${props.id}`}
        render={() => (
          <Drawer open={isOpenCreate} anchor="right">
            <TherapistIntakeCreate {...props} handleClose={handleCloseCreate} />
          </Drawer>
        )}
      />
    </ListContextProvider>
  );
};
