import Button from '@material-ui/core/Button';
import { isEmpty } from 'lodash';
import { SaveButton, Toolbar } from 'react-admin';

export const CustomButton = ({ ...props }) => {
  const disableButton = props?.record?.transaction?.fullyRefunded;
  const label = props?.label;
  return (
    <Button
      variant={disableButton ? 'contained' : 'containedPrimary'}
      onClick={() => {
        props.setIsOpenModal(true);
      }}
      disabled={disableButton}
    >
      {label ? label : disableButton ? 'Refunded' : 'Refund'}
    </Button>
  );
};

export const GetPriceField = ({ record, parentKey, childKey }) => (
  <>
    {record[parentKey] && record[parentKey][childKey] !== undefined ? (
      <span>${record[parentKey][childKey] / 100}</span>
    ) : (
      <span>$0</span>
    )}
  </>
);

export const PriceField = ({ record, sourceKey }) => (
  <>
    {record[sourceKey] && record[sourceKey] !== undefined ? (
      <span>${record[sourceKey] / 100}</span>
    ) : (
      <span>$0</span>
    )}
  </>
);

export const TherapistFullNameField = ({ record }) => (
  <span>
    {record?.therapist?.firstName} {record?.therapist?.lastName}
  </span>
);

export const ActionButtons = props => (
  <Toolbar
    {...props}
    style={{
      display: 'flex',
      gridGap: 10,
    }}
  >
    <SaveButton
      label={props?.saveLabel || 'save'}
      onSuccess={props?.handleSave}
      style={{ width: 150 }}
    />
    <Button
      onClick={props?.handleCancel}
      variant="outlinedPrimary"
      class="custom-btn"
    >
      Cancel
    </Button>
  </Toolbar>
);

export const FullNameField = ({ record }) => (
  <span>
    {record.firstName} {record.lastName}
  </span>
);

export const OptionsField = ({ record }) => <span>{record.option}</span>;

export const SessionPartnerFullNameField = ({ record, user }) => (
  <>
    {record?.partner?.id !== user?.record?.id ? (
      <span>
        {record?.partner?.firstName} {record?.partner?.lastName}
      </span>
    ) : (
      <span>
        {record?.user?.firstName} {record?.user?.lastName}
      </span>
    )}
  </>
);
export const PartnerFullNameField = ({ record, user }) => (
  <span>
    {record?.partner?.firstName} {record?.partner?.lastName}
  </span>
);
export const RelationshipGuestNameField = ({ record }) => (
  <span>
    {!isEmpty(record?.user?.relationships) &&
      record?.user?.relationships[0].relationship}
  </span>
);

export const NoTitle = () => <span style={{ display: 'none' }}>Post</span>;

export const LocationField = ({ record }) => <span>{record.city}</span>;
