import React, { useState } from 'react';
import {
  CreateContextProvider,
  NumberInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useNotify,
} from 'react-admin';
import { ActionButtons } from '../../components';
import { customDataProvider } from '../../data-provider';

export const SessionTransactionsRefund = ({
  props,
  handleCancel,
  transaction,
  refetch,
}) => {
  const notify = useNotify();
  const controllerProps = useCreateController(props);

  const [creditCardValue, setCreditCardValue] = useState(0);
  const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);

  const handleRefund = values => {
    if (!values?.ccAmount && !values?.shopCredits) {
      notify('enter amount on credit card charge/credits used');
      return;
    }
    const payload = {
      ...values,
      ccAmount: values?.ccAmount
        ? Math.round(values?.ccAmount * 100)
        : undefined,
      shopCredits: values?.shopCredits
        ? Math.round(values?.shopCredits * 100)
        : undefined,
      transactionId: transaction?.id,
    };
    customDataProvider.update('refund', payload).then(
      () => {
        notify('refund successfull');
        refetch();
        handleCancel();
      },
      err => {
        notify(err.message);
      },
    );
  };

  return (
    <div style={{ width: 300, padding: 20 }}>
      <div>Are you sure you want to process a refund?</div>
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          style={{ padding: 0 }}
          save={handleRefund}
          toolbar={
            <ActionButtons
              handleSave={handleRefund}
              handleCancel={handleCancel}
              saveLabel="Issue Refund"
              className="custom-flex"
            />
          }
        >
          {transaction?.finalPrice > 0 && (
            <NumberInput
              label="Refund To CC"
              source="ccAmount"
              min={0}
              onChange={values =>
                setCreditCardValue(Number(values.target.value))
              }
            />
          )}
          {transaction?.shopCreditsUsed > 0 && (
            <NumberInput
              label="Refund To Shop Credits"
              source="shopCredits"
              min={0}
              onChange={values =>
                setTotalCreditsUsed(Number(values.target.value))
              }
            />
          )}
          <TextInput
            source="refundTotal"
            disabled={true}
            format={() => `$${(creditCardValue + totalCreditsUsed).toFixed(2)}`}
            label="Refund Total"
          />
          <TextInput source="notes" multiline label="Notes" />
        </SimpleForm>
      </CreateContextProvider>
    </div>
  );
};
