import React, { useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  CreateContextProvider,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import { ActionButtons, OptionsField } from '../components';
import { customDataProvider } from '../data-provider';

export const Refund = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const controllerProps = useCreateController(props);
  const [fullRefund, setFullRefund] = useState(false);
  const [reasons, setReasons] = useState([]);
  const { data: refundReason } = useQuery({
    type: 'getList',
    resource: 'refund',
    payload: { id: props?.id, partnerId: props?.record?.lastPartnerId },
  });

  useEffect(() => {
    if (props?.giftCard?.id && refundReason) {
      const data = refundReason.filter(
        res =>
          res.option !== 'Reverse Cancellation Fee' &&
          res.option !== 'Intended to Cancel Membership',
      );
      setReasons(data);
    } else {
      setReasons(refundReason);
    }
  }, [props?.giftCard?.id, refundReason]);
  const handleRefund = values => {
    const amount =
      !fullRefund && values?.refundAmount && values?.refundAmount.toString();
    if (amount) {
      amount.replace('.', '');
    }
    const payload = {
      isFullRefund: fullRefund || !values?.refundAmount || undefined,
      amount:
        fullRefund || !values?.refundAmount
          ? undefined
          : parseInt((Number(amount) * 100).toFixed(0), 10),
      sessionId: props?.sessionId?.id,
      membershipMonthId: props?.membershipId?.id,
      giftCodeId: props?.giftCard?.id,
      refundReasonId: values.refundReasonId,
      refundReasonOther: (() => {
        const otherReasonId = refundReason.find(r => r.option === 'Other');
        if (
          values.refundReasonId === otherReasonId?.id &&
          values.refundReasonOther
        ) {
          return values.refundReasonOther;
        }
        return undefined;
      })(),
    };
    customDataProvider.update('refund', payload).then(
      () => {
        notify('refund successfull');
        refresh();
        props.handleClose();
      },
      err => {
        notify(err.message);
      },
    );
  };
  const validateRefundAmount = [required()];
  const validateRefundReason = [required()];
  const [refundReasonOther, setRefundReasonOther] = useState(false);

  const handleChange = value => {
    setRefundReasonOther(value.target.value === 5);
  };

  const validateRefundReasonOther = [refundReasonOther && required()];

  return (
    <div style={{ width: 300, padding: 20 }}>
      <h2>Refund {props.type}</h2>
      <div>Are you sure you want to process a refund?</div>
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          style={{ padding: 0 }}
          save={handleRefund}
          toolbar={
            <ActionButtons
              handleSave={handleRefund}
              handleCancel={props.handleClose}
              saveLabel="Issue Refund"
              className="custom-flex"
            />
          }
        >
          {!props?.isPaidViaSessionCredits && (
            <div>
              <CheckboxGroupInput
                source="isFullRefund"
                label=""
                choices={[{ id: 1, name: 'Process Full Refund' }]}
                onChange={() => {
                  setFullRefund(!fullRefund);
                }}
              />
              {!fullRefund && (
                <NumberInput
                  source="refundAmount"
                  min={0}
                  validate={validateRefundAmount}
                  style={{ width: '100%' }}
                />
              )}
            </div>
          )}
          <SelectInput
            source="refundReasonId"
            choices={reasons}
            label="Refund Reason"
            optionText={<OptionsField />}
            validate={validateRefundReason}
            onChange={handleChange}
          />
          {refundReasonOther && (
            <TextInput
              source="refundReasonOther"
              label="Other - Reason"
              validate={validateRefundReasonOther}
            />
          )}
        </SimpleForm>
      </CreateContextProvider>
    </div>
  );
};
