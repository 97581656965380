import _ from 'lodash';
import { authKey, getToken, login } from './services/auth';

const authProvider = {
  login: ({ username, password }) =>
    login({ email: username, password })
      .then(data => {
        const { sessionToken, ...rest } = data;
        localStorage.setItem(authKey, sessionToken, { expires: 90 });
        localStorage.setItem('user', JSON.stringify(rest));
        localStorage.setItem('role', data.role);
        window.location.replace('');
      })
      .catch(err => Promise.reject(err)),

  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkAuth: () => {
    if (getToken()) {
      return Promise.resolve();
    }

    return Promise.reject();
  },

  checkError: error => {
    const status = error.response.status;
    if (status === 401) {
      localStorage.clear();
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (_.isEmpty(user)) {
        return Promise.reject();
      }

      const { id, firstName, lastName } = user;
      return Promise.resolve({ id, fullName: `${firstName} ${lastName}` });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getUser: () => {
    try {
      const user = JSON.parse(localStorage.getItem('guest'));
      if (_.isEmpty(user)) {
        return Promise.reject();
      }

      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
