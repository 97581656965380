import _ from 'lodash';

const parseFilters = obj => {
  const result = Object.keys(obj).reduce((acc, prev) => {
    if (!_.isUndefined(obj[prev])) {
      acc.push(
        `${prev}=${Array.isArray(obj[prev]) ? obj[prev].join(',') : obj[prev]}`,
      );
    }
    return acc;
  }, []);

  return result.join('%26');
};

const parseFields = arr => arr.join(',');

const parseFieldsAndFilters = (fields, filters, giftTableFilters) => ({
  fields: parseFields(fields),
  filters: parseFilters(filters),
  giftTableFilters: parseFilters(giftTableFilters),
});

const constructUrl = (base, config) => {
  const {
    fields = [],
    filters = {},
    giftTableFilters = {},
    offset = 0,
    limit = 25,
  } = config;
  let parsedUrl = base;
  const parsedFields = parseFields(fields);
  const parsedFilters = parseFilters(filters);
  const parsedGiftTableFilters = parseFilters(giftTableFilters);

  if (!_.isEmpty(parsedFields)) {
    parsedUrl = `${parsedUrl}?fields=${parsedFields}`;
  }

  if (!_.isEmpty(parsedFilters)) {
    parsedUrl = `${parsedUrl}${
      !_.isEmpty(fields) ? '&' : '?'
    }filters=${parsedFilters}`;
  }

  if (!_.isEmpty(parsedGiftTableFilters)) {
    parsedUrl = `${parsedUrl}${
      !_.isEmpty(fields) ? '&' : '?'
    }giftTableFilters=${parsedGiftTableFilters}`;
  }

  parsedUrl = `${parsedUrl}${
    !_.isEmpty(fields) || !_.isEmpty(filters) ? '&' : '?'
  }offset=${offset}&limit=${limit}`;

  return parsedUrl;
};

export { parseFields, parseFilters, parseFieldsAndFilters, constructUrl };
