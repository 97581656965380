import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getChartNotesList = async (_resource, params) => {
  try {
    const fields = ['therapist.*'];
    const filters = { userId: params?.id };

    const url = constructUrl('users/chart-note', { fields, filters });

    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const getChartNote = async (_resource, params) => {
  try {
    const { id } = params;
    const filters = { id };
    const fields = [];

    const url = constructUrl('users/chart-note', {
      filters,
      fields,
    });
    const resp = await client(url, { authNeeded: true });

    return {
      data: resp.items[0],
    };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const updateChartNote = async (_resource, params) => {
  const {
    id,
    sessionNumber,
    intervention,
    observation,
    sessionDate,
    theme,
    // ...rest
  } = params;
  try {
    const resp = await client(`users/chart-note/${id}`, {
      authNeeded: true,
      method: 'PUT',
      data: {
        intervention,
        observation,
        sessionDate,
        theme,
        sessionNumber: sessionNumber
          ? JSON.stringify(parseInt(sessionNumber))
          : '',
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

const createChartNote = async (_resource, params) => {
  try {
    const resp = await client(`users/chart-note`, {
      authNeeded: true,
      method: 'POST',
      data: {
        ...params,
        sessionNumber: JSON.stringify(params?.sessionNumber),
        sessionDate:
          params?.sessionDate === '' ? undefined : params?.sessionDate,
      },
    });

    return { data: resp };
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getChartNotesList,
  getChartNote,
  updateChartNote,
  createChartNote,
};
