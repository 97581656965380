import React, { useEffect, useState } from 'react';
import {
  CreateContextProvider,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreateController,
  useNotify,
  useQuery,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { TreatmentPlanForm } from './treatment-plan-form';

import { customDataProvider } from '../data-provider';

export const TreatmentPlanCreate = props => {
  const notify = useNotify();
  const controllerProps = useCreateController(props);
  const validateTherapistId = [required()];
  const validateGuestId = [required()];
  const { data: therapists, loading: isTherapistsLoading } = useQuery({
    type: 'getList',
    resource: 'userTherapists',
    payload: { userId: props.id },
  });

  const { data: guest, loading: isGuestLoading } = useQuery({
    type: 'getOne',
    resource: 'guest',
    payload: { id: props.id },
  });

  const { data: diagnosisList, loading: isDiagnosisLoading } = useQuery({
    type: 'getList',
    resource: 'diagnosis',
    payload: { id: props.id },
  });

  const { userPartners, loading: isPartnersLoading } = useQuery({
    type: 'getList',
    resource: 'userPartners',
    payload: { id: props.id },
  });

  const createTreatmentPlan = payload => {
    customDataProvider.create('treatmentPlan', payload).then(
      () => {
        props.handleClose();
        notify('created successfully');
      },
      err => {
        notify(err.message);
      },
    );
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const PostCreateToolbar = props => (
    <Toolbar {...props} style={{ display: 'flex' }}>
      <SaveButton
        label="Save"
        onSuccess={handleSave}
        style={{ width: 150, marginRight: 10 }}
      />
      <Button onClick={handleCancel} class="custom-btn">
        Cancel
      </Button>
    </Toolbar>
  );

  const [user, setUser] = useState('');

  const handleSave = values => {
    const payload = {
      ...values,
      therapistId:
        user?.role === 'therapist' || user?.role === 'supervisor'
          ? undefined
          : values?.therapistId,
    };
    createTreatmentPlan(payload);
  };

  const FullNameField = ({ record }) => (
    <span>
      {record.firstName} {record.lastName}
    </span>
  );

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUser(data);
  }, []);

  let preSelectedTherapist = therapists?.length === 1 ? therapists[0] : null;

  if (!preSelectedTherapist) {
    preSelectedTherapist =
      therapists?.find(t => guest?.lastSession.therapistId === t.id) || null;
  }

  if (
    isGuestLoading ||
    isTherapistsLoading ||
    isPartnersLoading ||
    isDiagnosisLoading
  ) {
    return null;
  }

  return (
    <div className="intake-width">
      <CreateContextProvider value={controllerProps}>
        <SimpleForm
          initialValues={{
            userId: props.id,
            therapistId: preSelectedTherapist?.id || undefined,
          }}
          save={handleSave}
          toolbar={<PostCreateToolbar />}
        >
          {user?.role !== 'therapist' && (
            <SelectInput
              source="therapistId"
              choices={therapists}
              label="Therapists"
              optionText={<FullNameField />}
              validate={validateTherapistId}
              className="text-indent"
            />
          )}
          {/* <SelectInput
            label="Guest"
            source="userId"
            style={{ width: '100%' }}
            choices={[guest] || []}
            optionText={<FullNameField />}
            validate={validateGuestId}
            disabled={true}
          /> */}
          {guest && (
            <TextInput
              disabled={true}
              source="firstName"
              style={{ width: '100%' }}
              validate={validateGuestId}
              label="Guest Name"
              initialValue={`${guest?.firstName} ${guest?.lastName}`}
            />
          )}
          <TreatmentPlanForm
            data={props.record}
            userPartners={userPartners}
            diagnosisList={diagnosisList}
          />
        </SimpleForm>
      </CreateContextProvider>
    </div>
  );
};
